// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var ReactShare = require("react-share");
var Theme$WordpressSeamonsterStarter = require("../Lib/Theme.bs.js");
var Quiz__CopyToClipboard$WordpressSeamonsterStarter = require("./Quiz__CopyToClipboard.bs.js");

var shareLinks = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* :: */[
          Css.fontSize(Css.px(17)),
          /* :: */[
            Css.fontWeight(/* medium */-20425611),
            /* :: */[
              Css.color(/* `hex */[
                    5194459,
                    Theme$WordpressSeamonsterStarter.brandBlue
                  ]),
              /* :: */[
                Css.selector("& div", /* :: */[
                      Css.display(/* flex */-1010954439),
                      /* :: */[
                        Css.alignItems(/* center */98248149),
                        /* [] */0
                      ]
                    ]),
                /* :: */[
                  Css.selector("& i", /* :: */[
                        Css.marginRight(Css.px(20)),
                        /* [] */0
                      ]),
                  /* :: */[
                    Css.selector("& .fa-facebook-square", /* :: */[
                          Css.fontSize(Css.px(37)),
                          /* [] */0
                        ]),
                    /* :: */[
                      Css.selector("& .fa-envelope", /* :: */[
                            Css.fontSize(Css.px(34)),
                            /* [] */0
                          ]),
                      /* :: */[
                        Css.selector("& .fa-clone", /* :: */[
                              Css.fontSize(Css.px(28)),
                              /* :: */[
                                Css.fontWeight(/* medium */-20425611),
                                /* [] */0
                              ]
                            ]),
                        /* :: */[
                          Css.selector("& > div", /* :: */[
                                Css.cursor(/* pointer */-786317123),
                                /* [] */0
                              ]),
                          /* :: */[
                            Css.selector("& > * + *", /* :: */[
                                  Css.marginTop(Css.px(28)),
                                  /* [] */0
                                ]),
                            /* :: */[
                              Theme$WordpressSeamonsterStarter.MediaQuery.sm(/* :: */[
                                    Css.flexDirection(/* row */5693978),
                                    /* :: */[
                                      Css.selector("& > * + *", /* :: */[
                                            Css.marginTop(/* zero */-789508312),
                                            /* [] */0
                                          ]),
                                      /* :: */[
                                        Css.justifyContent(/* spaceBetween */516682146),
                                        /* [] */0
                                      ]
                                    ]
                                  ]),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var content = Curry._1(Css.style, /* [] */0);

var action = Curry._1(Css.style, /* [] */0);

var mailto = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.alignItems(/* center */98248149),
        /* :: */[
          Css.selector("&:hover", /* :: */[
                Css.textDecoration(/* none */-922086728),
                /* :: */[
                  Css.color(/* `hex */[
                        5194459,
                        Theme$WordpressSeamonsterStarter.brandBlue
                      ]),
                  /* [] */0
                ]
              ]),
          /* [] */0
        ]
      ]
    ]);

var Style = {
  shareLinks: shareLinks,
  content: content,
  action: action,
  mailto: mailto
};

function Quiz__Share(Props) {
  var className = Props.className;
  var url = Props.url;
  var shareText = "Take the Virginia Grand Driver quiz and find out if you need to brush up on your driving skills.";
  var shareLinks$1 = Belt_Option.mapWithDefault(className, shareLinks, (function (cn) {
          return Curry._1(Css.merge, /* :: */[
                      shareLinks,
                      /* :: */[
                        cn,
                        /* [] */0
                      ]
                    ]);
        }));
  return React.createElement("div", {
              className: shareLinks$1
            }, React.createElement(ReactShare.FacebookShareButton, {
                  children: React.createElement("div", undefined, React.createElement("i", {
                            className: "fab fa-facebook-square"
                          }), React.createElement("span", undefined, "Share on Facebook")),
                  url: url,
                  quote: shareText
                }), React.createElement("div", undefined, React.createElement("a", {
                      className: mailto,
                      href: "mailto:?to=&body=" + (String(shareText) + "&subject=The%20Virginia%20Grand%20Driver%20Quiz"),
                      rel: "noopener noreferrer",
                      target: "_blank"
                    }, React.createElement("i", {
                          className: "fas fa-envelope"
                        }), React.createElement("span", undefined, "Email"))), React.createElement(Quiz__CopyToClipboard$WordpressSeamonsterStarter.make, {
                  text: url
                }));
}

var CopyToClipboard = /* alias */0;

var make = Quiz__Share;

exports.CopyToClipboard = CopyToClipboard;
exports.Style = Style;
exports.make = make;
/* shareLinks Not a pure module */
