// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Utils$Sms = require("@seamonster-studios/reason/src/utils/Utils.bs.js");
var Theme$WordpressSeamonsterStarter = require("../../Lib/Theme.bs.js");

var root = Curry._1(Css.style, /* :: */[
      Css.padding(Css.px(20)),
      /* :: */[
        Css.backgroundColor(Css.hex(Theme$WordpressSeamonsterStarter.brandBlueLight)),
        /* :: */[
          Css.selector("h3, button, a, p", /* :: */[
                Css.color(/* `hex */[
                      5194459,
                      "fff"
                    ]),
                /* [] */0
              ]),
          /* :: */[
            Css.selector("h3, p", /* :: */[
                  Css.lineHeight(Css.em(1.286)),
                  /* [] */0
                ]),
            /* :: */[
              Css.margin3(Css.px(20), Css.px(0), Css.px(40)),
              /* :: */[
                Theme$WordpressSeamonsterStarter.MediaQuery.sm(/* :: */[
                      Css.padding2(Css.px(20), Css.px(45)),
                      /* [] */0
                    ]),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var form = Curry._1(Css.style, /* :: */[
      Css.marginTop(Css.px(5)),
      /* :: */[
        Css.selector("input", /* :: */[
              Css.fontSize(Css.px(24)),
              /* :: */[
                Css.padding2(Css.px(10), Css.px(20)),
                /* :: */[
                  Css.maxWidth(Css.pct(100)),
                  /* :: */[
                    Css.fontWeight(/* semiBold */-812709613),
                    /* :: */[
                      Css.fontFamily(/* `custom */[
                            1066567601,
                            "Alvar Essential, sans-serif"
                          ]),
                      /* :: */[
                        Css.display(/* block */888960333),
                        /* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]),
        /* :: */[
          Css.selector("button", /* :: */[
                Css.fontFamily(/* `custom */[
                      1066567601,
                      "Alvar Essential, sans-serif"
                    ]),
                /* :: */[
                  Css.fontSize(Css.px(18)),
                  /* :: */[
                    Css.padding2(Css.px(15), Css.px(30)),
                    /* :: */[
                      Css.textTransform(/* uppercase */-415330030),
                      /* :: */[
                        Css.backgroundColor(/* transparent */582626130),
                        /* :: */[
                          Css.border(Css.px(2), /* solid */12956715, /* `hex */[
                                5194459,
                                "fff"
                              ]),
                          /* :: */[
                            Css.marginTop(Css.px(12)),
                            /* :: */[
                              Css.minWidth(Css.px(280)),
                              /* :: */[
                                Css.transition(300, undefined, /* ease */-1022587922, "all"),
                                /* :: */[
                                  Css.cursor(/* pointer */-786317123),
                                  /* :: */[
                                    Css.selector("&:hover", /* :: */[
                                          Css.backgroundColor(/* `hex */[
                                                5194459,
                                                "fff"
                                              ]),
                                          /* :: */[
                                            Css.color(Css.hex(Theme$WordpressSeamonsterStarter.brandBlueLight)),
                                            /* [] */0
                                          ]
                                        ]),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]),
          /* :: */[
            Theme$WordpressSeamonsterStarter.MediaQuery.sm(/* :: */[
                  Css.marginTop(Css.px(14)),
                  /* :: */[
                    Css.display(/* flex */-1010954439),
                    /* :: */[
                      Css.selector("input", /* :: */[
                            Css.padding2(Css.px(4), Css.px(20)),
                            /* [] */0
                          ]),
                      /* :: */[
                        Css.selector("button", /* :: */[
                              Css.marginTop(Css.px(0)),
                              /* :: */[
                                Css.marginLeft(Css.px(15)),
                                /* [] */0
                              ]
                            ]),
                        /* [] */0
                      ]
                    ]
                  ]
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var footer = Curry._1(Css.style, /* :: */[
      Css.textDecoration(/* underline */131142924),
      /* :: */[
        Css.marginTop(Css.px(8)),
        /* :: */[
          Css.display(/* none */-922086728),
          /* :: */[
            Css.selector("a", /* :: */[
                  Css.fontSize(Css.px(15)),
                  /* [] */0
                ]),
            /* :: */[
              Theme$WordpressSeamonsterStarter.MediaQuery.sm(/* :: */[
                    Css.display(/* block */888960333),
                    /* [] */0
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var Style = {
  root: root,
  form: form,
  footer: footer
};

function MapTabsSearch(Props) {
  var match = React.useState((function () {
          return "";
        }));
  var setValue = match[1];
  var value = match[0];
  var submitSearch = function (param) {
    window.open("http://seniornavigator.org/searchresults?topic=31262&citycountyzip=" + (String(value) + "&x=31&y=18"), "_blank");
    return /* () */0;
  };
  return React.createElement("div", {
              className: root
            }, React.createElement("p", undefined, "Click on a region on the map or enter your location below. Results will open in a new tab."), React.createElement("div", {
                  className: form
                }, React.createElement("input", {
                      placeholder: "Enter your city or county",
                      type: "text",
                      value: value,
                      onKeyPress: (function ($$event) {
                          return Utils$Sms.InputU.handleEnterPressOnKeyDown($$event, (function (param) {
                                        return submitSearch(/* () */0);
                                      }));
                        }),
                      onChange: (function ($$event) {
                          $$event.persist();
                          return Curry._1(setValue, (function (param) {
                                        return $$event.target.value;
                                      }));
                        })
                    }), React.createElement("button", {
                      onClick: (function (param) {
                          return submitSearch(/* () */0);
                        })
                    }, "Search Senior Navigator")), React.createElement("div", {
                  className: footer
                }, React.createElement("a", {
                      href: "http://seniornavigator.org/",
                      rel: "noopener noreferrer",
                      target: "_blank"
                    }, "Click here to see more resources from Senior Navigator")));
}

var make = MapTabsSearch;

exports.Style = Style;
exports.make = make;
/* root Not a pure module */
