// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var $$String = require("bs-platform/lib/js/string.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Css_Colors = require("./Css_Colors.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Css_AtomicTypes = require("./Css_AtomicTypes.js");

function ruleToDict(dict, rule) {
  switch (rule.tag | 0) {
    case /* D */0 :
        var value = rule[1];
        var name = rule[0];
        if (name === "content") {
          dict[name] = value === "" ? "\"\"" : value;
        } else {
          dict[name] = value;
        }
        break;
    case /* S */1 :
        dict[rule[0]] = toJson(rule[1]);
        break;
    case /* PseudoClass */2 :
        dict[":" + rule[0]] = toJson(rule[1]);
        break;
    case /* PseudoClassParam */3 :
        dict[":" + (rule[0] + ("(" + (rule[1] + ")")))] = toJson(rule[2]);
        break;
    
  }
  return dict;
}

function toJson(rules) {
  return Belt_List.reduce(rules, { }, ruleToDict);
}

function addStop(dict, param) {
  dict[param[0].toString() + "%"] = toJson(param[1]);
  return dict;
}

function join(strings, separator) {
  var _strings = strings;
  var _acc = "";
  while(true) {
    var acc = _acc;
    var strings$1 = _strings;
    if (strings$1) {
      var xs = strings$1[1];
      var x = strings$1[0];
      if (xs) {
        _acc = acc + (x + separator);
        _strings = xs;
        continue ;
      } else {
        return acc + x;
      }
    } else {
      return acc;
    }
  };
}

function string_of_alpha(param) {
  if (param[0] >= 5496390) {
    return param[1].toString();
  } else {
    return param[1].toString() + "%";
  }
}

function string_of_stops(stops) {
  return join(Belt_List.map(stops, (function (param) {
                    return Css_AtomicTypes.Color.toString(param[1]) + (" " + Css_AtomicTypes.Length.toString(param[0]));
                  })), ", ");
}

function string_of_background(bg) {
  if (typeof bg === "number") {
    if (bg !== 292050538) {
      if (bg >= 582626130) {
        return "transparent";
      } else {
        return "none";
      }
    } else {
      return "currentColor";
    }
  } else {
    var variant = bg[0];
    if (variant >= 5197569) {
      if (variant !== 5692173) {
        if (variant >= 119548911) {
          if (variant >= 616379637) {
            var match = bg[1];
            return "linear-gradient(" + (Css_AtomicTypes.Angle.toString(match[0]) + (", " + (string_of_stops(match[1]) + ")")));
          } else {
            return "radial-gradient(" + (string_of_stops(bg[1]) + ")");
          }
        } else if (variant >= 5843823) {
          return "url(" + (bg[1] + ")");
        } else {
          var match$1 = bg[1];
          var h = match$1[0];
          var s = match$1[1];
          var l = match$1[2];
          return "hsl(" + (Css_AtomicTypes.Angle.toString(h) + (", " + (Css_AtomicTypes.Percentage.toString(s) + (", " + (Css_AtomicTypes.Percentage.toString(l) + ")")))));
        }
      } else {
        var match$2 = bg[1];
        return "rgb(" + (match$2[0].toString() + (", " + (match$2[1].toString() + (", " + (match$2[2].toString() + ")")))));
      }
    } else if (variant !== -878128972) {
      if (variant >= -160280644) {
        if (variant >= 5194459) {
          return "#" + bg[1];
        } else {
          var match$3 = bg[1];
          return "repeating-linear-gradient(" + (Css_AtomicTypes.Angle.toString(match$3[0]) + (", " + (string_of_stops(match$3[1]) + ")")));
        }
      } else if (variant >= -657111370) {
        return "repeating-radial-gradient(" + (string_of_stops(bg[1]) + ")");
      } else {
        var match$4 = bg[1];
        var h$1 = match$4[0];
        var s$1 = match$4[1];
        var l$1 = match$4[2];
        var a = match$4[3];
        return "hsla(" + (Css_AtomicTypes.Angle.toString(h$1) + (", " + (Css_AtomicTypes.Percentage.toString(s$1) + (", " + (Css_AtomicTypes.Percentage.toString(l$1) + (", " + (string_of_alpha(a) + ")")))))));
      }
    } else {
      var match$5 = bg[1];
      return "rgba(" + (match$5[0].toString() + (", " + (match$5[1].toString() + (", " + (match$5[2].toString() + (", " + (match$5[3].toString() + ")")))))));
    }
  }
}

function string_of_content(x) {
  if (typeof x === "number") {
    if (x >= -601204732) {
      if (x >= 684949604) {
        return Css_AtomicTypes.Content.toString(x);
      } else {
        return Css_AtomicTypes.Cascading.toString(x);
      }
    } else if (x !== -878767996) {
      return Css_AtomicTypes.Content.toString(x);
    } else {
      return Css_AtomicTypes.Cascading.toString(x);
    }
  } else {
    var variant = x[0];
    if (variant >= 119548911) {
      if (variant !== 287272439) {
        if (variant !== 492416764) {
          return Css_AtomicTypes.Gradient.toString(x);
        } else {
          return Css_AtomicTypes.Counter.toString(x);
        }
      } else {
        return Css_AtomicTypes.Counters.toString(x);
      }
    } else if (variant >= -657111370) {
      if (variant >= 5843823) {
        return Css_AtomicTypes.Url.toString(x);
      } else {
        return Css_AtomicTypes.Gradient.toString(x);
      }
    } else {
      return Css_AtomicTypes.Content.toString(x);
    }
  }
}

function string_of_counter_increment(x) {
  if (typeof x === "number" && x >= -878767996) {
    return Css_AtomicTypes.Cascading.toString(x);
  } else {
    return Css_AtomicTypes.CounterIncrement.toString(x);
  }
}

function string_of_counter_reset(x) {
  if (typeof x === "number" && x >= -878767996) {
    return Css_AtomicTypes.Cascading.toString(x);
  } else {
    return Css_AtomicTypes.CounterReset.toString(x);
  }
}

function string_of_counter_set(x) {
  if (typeof x === "number" && x >= -878767996) {
    return Css_AtomicTypes.Cascading.toString(x);
  } else {
    return Css_AtomicTypes.CounterSet.toString(x);
  }
}

function important(v) {
  if (v.tag) {
    return v;
  } else {
    return /* D */Block.__(0, [
              v[0],
              v[1] + " !important"
            ]);
  }
}

function label(label$1) {
  return /* D */Block.__(0, [
            "label",
            label$1
          ]);
}

function alignContent(x) {
  return /* D */Block.__(0, [
            "alignContent",
            x >= 287825029 ? (
                x !== 516682146 ? (
                    x >= 662439529 ? (
                        x !== 812216871 ? Css_AtomicTypes.AlignContent.toString(x) : Css_AtomicTypes.NormalAlignment.toString(x)
                      ) : (
                        x >= 653193961 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.BaselineAlignment.toString(x)
                      )
                  ) : Css_AtomicTypes.DistributedAlignment.toString(x)
              ) : (
                x !== -1016061555 ? (
                    x >= -485895757 ? (
                        x >= 67859554 ? Css_AtomicTypes.AlignContent.toString(x) : Css_AtomicTypes.DistributedAlignment.toString(x)
                      ) : (
                        x >= -1021944795 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.AlignContent.toString(x)
                      )
                  ) : Css_AtomicTypes.DistributedAlignment.toString(x)
              )
          ]);
}

function alignItems(x) {
  return /* D */Block.__(0, [
            "alignItems",
            x >= 287825029 ? (
                x >= 662439529 ? (
                    x !== 812216871 ? Css_AtomicTypes.PositionalAlignment.toString(x) : Css_AtomicTypes.AlignItems.toString(x)
                  ) : (
                    x >= 653193961 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.BaselineAlignment.toString(x)
                  )
              ) : (
                x >= -379319332 ? (
                    x !== -162316795 ? Css_AtomicTypes.PositionalAlignment.toString(x) : Css_AtomicTypes.AlignItems.toString(x)
                  ) : (
                    x >= -878767996 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.PositionalAlignment.toString(x)
                  )
              )
          ]);
}

function alignSelf(x) {
  return /* D */Block.__(0, [
            "alignSelf",
            x >= 287825029 ? (
                x >= 662439529 ? (
                    x !== 812216871 ? Css_AtomicTypes.PositionalAlignment.toString(x) : Css_AtomicTypes.AlignSelf.toString(x)
                  ) : (
                    x >= 653193961 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.BaselineAlignment.toString(x)
                  )
              ) : (
                x >= -878767996 ? (
                    x >= -379319332 ? Css_AtomicTypes.PositionalAlignment.toString(x) : Css_AtomicTypes.Cascading.toString(x)
                  ) : (
                    x >= -1021944796 ? Css_AtomicTypes.PositionalAlignment.toString(x) : Css_AtomicTypes.AlignSelf.toString(x)
                  )
              )
          ]);
}

function animationDelay(x) {
  return /* D */Block.__(0, [
            "animationDelay",
            x.toString() + "ms"
          ]);
}

function animationDirection(x) {
  return /* D */Block.__(0, [
            "animationDirection",
            Css_AtomicTypes.AnimationDirection.toString(x)
          ]);
}

function animationDuration(x) {
  return /* D */Block.__(0, [
            "animationDuration",
            x.toString() + "ms"
          ]);
}

function animationFillMode(x) {
  return /* D */Block.__(0, [
            "animationFillMode",
            Css_AtomicTypes.AnimationFillMode.toString(x)
          ]);
}

function animationIterationCount(x) {
  return /* D */Block.__(0, [
            "animationIterationCount",
            Css_AtomicTypes.AnimationIterationCount.toString(x)
          ]);
}

function animationPlayState(x) {
  return /* D */Block.__(0, [
            "animationPlayState",
            Css_AtomicTypes.AnimationPlayState.toString(x)
          ]);
}

function animationTimingFunction(x) {
  return /* D */Block.__(0, [
            "animationTimingFunction",
            Css_AtomicTypes.TimingFunction.toString(x)
          ]);
}

function backfaceVisibility(x) {
  return /* D */Block.__(0, [
            "backfaceVisibility",
            x !== -862584982 && x !== 589592690 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.BackfaceVisibility.toString(x)
          ]);
}

function backdropFilter(x) {
  return /* D */Block.__(0, [
            "backdrop-filter",
            join(Belt_List.map(x, Css_AtomicTypes.BackdropFilter.toString), ", ")
          ]);
}

function backgroundAttachment(x) {
  return /* D */Block.__(0, [
            "backgroundAttachment",
            x >= -118437525 ? (
                x >= 653193961 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.BackgroundAttachment.toString(x)
              ) : (
                x >= -878767996 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.BackgroundAttachment.toString(x)
              )
          ]);
}

function backgroundColor(x) {
  return /* D */Block.__(0, [
            "backgroundColor",
            Css_AtomicTypes.Color.toString(x)
          ]);
}

function backgroundClip(x) {
  return /* D */Block.__(0, [
            "backgroundClip",
            x >= 9307263 ? (
                x !== 653193961 ? Css_AtomicTypes.BackgroundClip.toString(x) : Css_AtomicTypes.Cascading.toString(x)
              ) : (
                x >= -878767996 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.BackgroundClip.toString(x)
              )
          ]);
}

function backgroundImage(x) {
  return /* D */Block.__(0, [
            "backgroundImage",
            typeof x === "number" ? Css_AtomicTypes.BackgroundImage.toString(x) : (
                x[0] !== 5843823 ? Css_AtomicTypes.Gradient.toString(x) : Css_AtomicTypes.Url.toString(x)
              )
          ]);
}

function backgroundOrigin(x) {
  return /* D */Block.__(0, [
            "backgroundOrigin",
            x >= 9307263 ? (
                x !== 653193961 ? Css_AtomicTypes.BackgroundOrigin.toString(x) : Css_AtomicTypes.Cascading.toString(x)
              ) : (
                x >= -878767996 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.BackgroundOrigin.toString(x)
              )
          ]);
}

function string_of_backgroundposition(bp) {
  if (typeof bp === "number") {
    if (bp !== -789508312) {
      if (bp >= -445061397) {
        if (bp >= 653193961) {
          return Css_AtomicTypes.Cascading.toString(bp);
        } else {
          return Css_AtomicTypes.BackgroundPosition.toString(bp);
        }
      } else if (bp >= -944764920) {
        return Css_AtomicTypes.Cascading.toString(bp);
      } else {
        return Css_AtomicTypes.BackgroundPosition.toString(bp);
      }
    } else {
      return Css_AtomicTypes.Length.toString(bp);
    }
  } else if (bp[0] !== 23310) {
    return Css_AtomicTypes.Length.toString(bp);
  } else {
    var match = bp[1];
    var v = match[1];
    var h = match[0];
    return (
            typeof h === "number" && h !== -789508312 ? Curry._1(Css_AtomicTypes.BackgroundPosition.X.toString, h) : Css_AtomicTypes.Length.toString(h)
          ) + (" " + (
              typeof v === "number" && v >= -445061397 ? Curry._1(Css_AtomicTypes.BackgroundPosition.Y.toString, v) : Css_AtomicTypes.Length.toString(v)
            ));
  }
}

function backgroundPosition(x) {
  return /* D */Block.__(0, [
            "backgroundPosition",
            string_of_backgroundposition(x)
          ]);
}

function backgroundPositions(bp) {
  return /* D */Block.__(0, [
            "backgroundPosition",
            join(Belt_List.map(bp, string_of_backgroundposition), ", ")
          ]);
}

function backgroundPosition4(x, offsetX, y, offsetY) {
  return /* D */Block.__(0, [
            "backgroundPosition",
            Curry._1(Css_AtomicTypes.BackgroundPosition.X.toString, x) + (" " + (Css_AtomicTypes.Length.toString(offsetX) + (" " + (Curry._1(Css_AtomicTypes.BackgroundPosition.Y.toString, y) + (" " + Css_AtomicTypes.Length.toString(offsetY))))))
          ]);
}

function backgroundRepeat(x) {
  var tmp;
  if (typeof x === "number") {
    tmp = x >= -312084402 ? (
        x >= 653193961 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.BackgroundRepeat.toString(x)
      ) : (
        x !== -695430532 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.BackgroundRepeat.toString(x)
      );
  } else {
    var match = x[1];
    tmp = Css_AtomicTypes.BackgroundRepeat.toString(match[0]) + (" " + Css_AtomicTypes.BackgroundRepeat.toString(match[1]));
  }
  return /* D */Block.__(0, [
            "backgroundRepeat",
            tmp
          ]);
}

function borderBottomColor(x) {
  return /* D */Block.__(0, [
            "borderBottomColor",
            Css_AtomicTypes.Color.toString(x)
          ]);
}

function borderBottomLeftRadius(x) {
  return /* D */Block.__(0, [
            "borderBottomLeftRadius",
            Css_AtomicTypes.Length.toString(x)
          ]);
}

function borderBottomRightRadius(x) {
  return /* D */Block.__(0, [
            "borderBottomRightRadius",
            Css_AtomicTypes.Length.toString(x)
          ]);
}

function borderBottomWidth(x) {
  return /* D */Block.__(0, [
            "borderBottomWidth",
            Css_AtomicTypes.Length.toString(x)
          ]);
}

function borderCollapse(x) {
  return /* D */Block.__(0, [
            "borderCollapse",
            x !== 119283555 && x >= -996847250 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.BorderCollapse.toString(x)
          ]);
}

function borderColor(x) {
  return /* D */Block.__(0, [
            "borderColor",
            Css_AtomicTypes.Color.toString(x)
          ]);
}

function borderLeftColor(x) {
  return /* D */Block.__(0, [
            "borderLeftColor",
            Css_AtomicTypes.Color.toString(x)
          ]);
}

function borderLeftWidth(x) {
  return /* D */Block.__(0, [
            "borderLeftWidth",
            Css_AtomicTypes.Length.toString(x)
          ]);
}

function borderSpacing(x) {
  return /* D */Block.__(0, [
            "borderSpacing",
            Css_AtomicTypes.Length.toString(x)
          ]);
}

function borderRadius(x) {
  return /* D */Block.__(0, [
            "borderRadius",
            Css_AtomicTypes.Length.toString(x)
          ]);
}

function borderRightColor(x) {
  return /* D */Block.__(0, [
            "borderRightColor",
            Css_AtomicTypes.Color.toString(x)
          ]);
}

function borderRightWidth(x) {
  return /* D */Block.__(0, [
            "borderRightWidth",
            Css_AtomicTypes.Length.toString(x)
          ]);
}

function borderTopColor(x) {
  return /* D */Block.__(0, [
            "borderTopColor",
            Css_AtomicTypes.Color.toString(x)
          ]);
}

function borderTopLeftRadius(x) {
  return /* D */Block.__(0, [
            "borderTopLeftRadius",
            Css_AtomicTypes.Length.toString(x)
          ]);
}

function borderTopRightRadius(x) {
  return /* D */Block.__(0, [
            "borderTopRightRadius",
            Css_AtomicTypes.Length.toString(x)
          ]);
}

function borderTopWidth(x) {
  return /* D */Block.__(0, [
            "borderTopWidth",
            Css_AtomicTypes.Length.toString(x)
          ]);
}

function borderWidth(x) {
  return /* D */Block.__(0, [
            "borderWidth",
            Css_AtomicTypes.Length.toString(x)
          ]);
}

function bottom(x) {
  return /* D */Block.__(0, [
            "bottom",
            typeof x === "number" && x !== -789508312 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.Length.toString(x)
          ]);
}

function boxSizing(x) {
  return /* D */Block.__(0, [
            "boxSizing",
            x !== 9307263 && x >= -1008848301 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.BoxSizing.toString(x)
          ]);
}

function clear(x) {
  return /* D */Block.__(0, [
            "clear",
            x >= -379319332 ? (
                x !== 653193961 ? Css_AtomicTypes.Clear.toString(x) : Css_AtomicTypes.Cascading.toString(x)
              ) : (
                x >= -878767996 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.Clear.toString(x)
              )
          ]);
}

function clipPath(x) {
  return /* D */Block.__(0, [
            "clipPath",
            typeof x === "number" ? (
                x !== -922086728 ? (
                    x >= -298658179 ? (
                        x !== 653193961 ? Css_AtomicTypes.GeometyBox.toString(x) : Css_AtomicTypes.Cascading.toString(x)
                      ) : (
                        x >= -1008848301 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.GeometyBox.toString(x)
                      )
                  ) : Css_AtomicTypes.ClipPath.toString(x)
              ) : Css_AtomicTypes.Url.toString(x)
          ]);
}

function color(x) {
  return /* D */Block.__(0, [
            "color",
            Css_AtomicTypes.Color.toString(x)
          ]);
}

function columnCount(x) {
  return /* D */Block.__(0, [
            "columnCount",
            typeof x === "number" && x >= -878767996 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.ColumnCount.toString(x)
          ]);
}

function contentRule(x) {
  return /* D */Block.__(0, [
            "content",
            string_of_content(x)
          ]);
}

function contentRules(xs) {
  return /* D */Block.__(0, [
            "content",
            join(Belt_List.map(xs, string_of_content), " ")
          ]);
}

function counterIncrement(x) {
  return /* D */Block.__(0, [
            "counter-increment",
            string_of_counter_increment(x)
          ]);
}

function countersIncrement(xs) {
  return /* D */Block.__(0, [
            "counter-increment",
            join(Belt_List.map(xs, string_of_counter_increment), " ")
          ]);
}

function counterReset(x) {
  return /* D */Block.__(0, [
            "counter-reset",
            string_of_counter_reset(x)
          ]);
}

function countersReset(xs) {
  return /* D */Block.__(0, [
            "counter-reset",
            join(Belt_List.map(xs, string_of_counter_reset), " ")
          ]);
}

function counterSet(x) {
  return /* D */Block.__(0, [
            "counter-set",
            string_of_counter_set(x)
          ]);
}

function countersSet(xs) {
  return /* D */Block.__(0, [
            "counter-set",
            join(Belt_List.map(xs, string_of_counter_set), " ")
          ]);
}

function cursor(x) {
  return /* D */Block.__(0, [
            "cursor",
            Css_AtomicTypes.Cursor.toString(x)
          ]);
}

function direction(x) {
  return /* D */Block.__(0, [
            "direction",
            x >= 5396714 && x < 653193961 ? Css_AtomicTypes.Direction.toString(x) : Css_AtomicTypes.Cascading.toString(x)
          ]);
}

function display(x) {
  return /* D */Block.__(0, [
            "display",
            x >= 97122692 ? (
                x < 653193961 ? (
                    x !== 182695950 ? (
                        x !== 423610969 ? (
                            x >= 632591505 ? Css_AtomicTypes.DisplayListItem.toString(x) : Css_AtomicTypes.DisplayInternal.toString(x)
                          ) : Css_AtomicTypes.DisplayOutside.toString(x)
                      ) : Css_AtomicTypes.DisplayInside.toString(x)
                  ) : (
                    x >= 793912528 ? (
                        x !== 888960333 ? Css_AtomicTypes.DisplayInternal.toString(x) : Css_AtomicTypes.DisplayOutside.toString(x)
                      ) : (
                        x >= 790889754 ? Css_AtomicTypes.DisplayBox.toString(x) : Css_AtomicTypes.Cascading.toString(x)
                      )
                  )
              ) : (
                x >= -854050059 ? (
                    x !== -601204732 ? (
                        x >= -245903344 ? (
                            x >= -147785676 ? Css_AtomicTypes.DisplayLegacy.toString(x) : Css_AtomicTypes.DisplayOutside.toString(x)
                          ) : (
                            x >= -843129172 ? Css_AtomicTypes.DisplayInternal.toString(x) : Css_AtomicTypes.DisplayLegacy.toString(x)
                          )
                      ) : Css_AtomicTypes.Cascading.toString(x)
                  ) : (
                    x >= -922086728 ? (
                        x >= -878767996 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.DisplayBox.toString(x)
                      ) : (
                        x >= -1010954439 ? Css_AtomicTypes.DisplayInside.toString(x) : Css_AtomicTypes.DisplayInternal.toString(x)
                      )
                  )
              )
          ]);
}

function flex(x) {
  return /* D */Block.__(0, [
            "flex",
            typeof x === "number" ? Css_AtomicTypes.Flex.toString(x) : x[1].toString()
          ]);
}

function flexDirection(x) {
  return /* D */Block.__(0, [
            "flexDirection",
            x >= 5693978 ? (
                x >= 653193961 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.FlexDirection.toString(x)
              ) : (
                x >= -878767996 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.FlexDirection.toString(x)
              )
          ]);
}

function flexGrow(x) {
  return /* D */Block.__(0, [
            "flexGrow",
            x.toString()
          ]);
}

function flexShrink(x) {
  return /* D */Block.__(0, [
            "flexShrink",
            x.toString()
          ]);
}

function flexWrap(x) {
  return /* D */Block.__(0, [
            "flexWrap",
            x >= -463121288 ? (
                x !== 653193961 ? Css_AtomicTypes.FlexWrap.toString(x) : Css_AtomicTypes.Cascading.toString(x)
              ) : (
                x !== -822134326 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.FlexWrap.toString(x)
              )
          ]);
}

function $$float(x) {
  return /* D */Block.__(0, [
            "float",
            x >= -379319332 ? (
                x !== 653193961 ? Css_AtomicTypes.Float.toString(x) : Css_AtomicTypes.Cascading.toString(x)
              ) : (
                x >= -878767996 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.Float.toString(x)
              )
          ]);
}

function fontFamily(x) {
  return /* D */Block.__(0, [
            "fontFamily",
            typeof x === "number" && !(x !== -878767996 && x !== -601204732 && x < 653193961) ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.FontFamilyName.toString(x)
          ]);
}

function fontFamilies(xs) {
  return /* D */Block.__(0, [
            "fontFamily",
            join(Belt_List.map(xs, Css_AtomicTypes.FontFamilyName.toString), ", ")
          ]);
}

function fontSize(x) {
  return /* D */Block.__(0, [
            "fontSize",
            typeof x === "number" && x !== -789508312 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.Length.toString(x)
          ]);
}

function fontStyle(x) {
  return /* D */Block.__(0, [
            "fontStyle",
            x !== 107228912 && x < 734792881 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.FontStyle.toString(x)
          ]);
}

function fontVariant(x) {
  return /* D */Block.__(0, [
            "fontVariant",
            x !== -386684792 && x < 812216871 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.FontVariant.toString(x)
          ]);
}

function fontWeight(x) {
  return /* D */Block.__(0, [
            "fontWeight",
            typeof x === "number" && !(x !== -878767996 && x !== -601204732 && x !== 653193961) ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.FontWeight.toString(x)
          ]);
}

function gridAutoFlow(x) {
  return /* D */Block.__(0, [
            "gridAutoFlow",
            x >= -227605271 ? (
                x >= 653193961 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.GridAutoFlow.toString(x)
              ) : (
                x >= -878767996 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.GridAutoFlow.toString(x)
              )
          ]);
}

function gridColumn(start, end$prime) {
  return /* D */Block.__(0, [
            "gridColumn",
            start.toString() + (" / " + end$prime.toString())
          ]);
}

function gridColumnGap(x) {
  return /* D */Block.__(0, [
            "gridColumnGap",
            typeof x === "number" ? (
                x !== -789508312 ? (
                    x >= 812216871 ? Css_AtomicTypes.GridColumnGap.toString(x) : Css_AtomicTypes.Cascading.toString(x)
                  ) : Css_AtomicTypes.Length.toString(x)
              ) : (
                x[0] !== -119887163 ? Css_AtomicTypes.Length.toString(x) : Css_AtomicTypes.Percentage.toString(x)
              )
          ]);
}

function gridColumnStart(n) {
  return /* D */Block.__(0, [
            "gridColumnStart",
            n.toString()
          ]);
}

function gridColumnEnd(n) {
  return /* D */Block.__(0, [
            "gridColumnEnd",
            n.toString()
          ]);
}

function gridRow(start, end$prime) {
  return /* D */Block.__(0, [
            "gridRow",
            start.toString() + (" / " + end$prime.toString())
          ]);
}

function gridGap(x) {
  return /* D */Block.__(0, [
            "gridGap",
            typeof x === "number" ? (
                x !== -789508312 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.Length.toString(x)
              ) : (
                x[0] !== -119887163 ? Css_AtomicTypes.Length.toString(x) : Css_AtomicTypes.Percentage.toString(x)
              )
          ]);
}

function gridRowGap(x) {
  return /* D */Block.__(0, [
            "gridRowGap",
            typeof x === "number" ? (
                x !== -789508312 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.Length.toString(x)
              ) : (
                x[0] !== -119887163 ? Css_AtomicTypes.Length.toString(x) : Css_AtomicTypes.Percentage.toString(x)
              )
          ]);
}

function gridRowEnd(n) {
  return /* D */Block.__(0, [
            "gridRowEnd",
            n.toString()
          ]);
}

function gridRowStart(n) {
  return /* D */Block.__(0, [
            "gridRowStart",
            n.toString()
          ]);
}

function height(x) {
  return /* D */Block.__(0, [
            "height",
            typeof x === "number" ? (
                x !== -789508312 ? (
                    x >= -1065951376 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.Height.toString(x)
                  ) : Css_AtomicTypes.Length.toString(x)
              ) : (
                x[0] !== -119887163 ? Css_AtomicTypes.Length.toString(x) : Css_AtomicTypes.Percentage.toString(x)
              )
          ]);
}

function justifyContent(x) {
  return /* D */Block.__(0, [
            "justifyContent",
            x >= -162316795 ? (
                x >= 516682146 ? (
                    x >= 662439529 ? (
                        x !== 812216871 ? Css_AtomicTypes.PositionalAlignment.toString(x) : Css_AtomicTypes.NormalAlignment.toString(x)
                      ) : (
                        x >= 653193961 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.DistributedAlignment.toString(x)
                      )
                  ) : (
                    x >= 51834799 ? Css_AtomicTypes.PositionalAlignment.toString(x) : Css_AtomicTypes.DistributedAlignment.toString(x)
                  )
              ) : (
                x !== -1016061555 ? (
                    x >= -485895757 ? (
                        x >= -379319332 ? Css_AtomicTypes.PositionalAlignment.toString(x) : Css_AtomicTypes.DistributedAlignment.toString(x)
                      ) : (
                        x >= -878767996 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.PositionalAlignment.toString(x)
                      )
                  ) : Css_AtomicTypes.DistributedAlignment.toString(x)
              )
          ]);
}

function left(x) {
  return /* D */Block.__(0, [
            "left",
            typeof x === "number" && x !== -789508312 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.Length.toString(x)
          ]);
}

function letterSpacing(x) {
  return /* D */Block.__(0, [
            "letterSpacing",
            typeof x === "number" && x !== -789508312 ? (
                x >= 812216871 ? Css_AtomicTypes.LetterSpacing.toString(x) : Css_AtomicTypes.Cascading.toString(x)
              ) : Css_AtomicTypes.Length.toString(x)
          ]);
}

function lineHeight(x) {
  return /* D */Block.__(0, [
            "lineHeight",
            typeof x === "number" ? (
                x !== -789508312 ? (
                    x >= 812216871 ? Css_AtomicTypes.LineHeight.toString(x) : Css_AtomicTypes.Cascading.toString(x)
                  ) : Css_AtomicTypes.Length.toString(x)
              ) : (
                x[0] !== 4845682 ? Css_AtomicTypes.Length.toString(x) : Css_AtomicTypes.LineHeight.toString(x)
              )
          ]);
}

function listStyle(style, position, image) {
  return /* D */Block.__(0, [
            "listStyle",
            Css_AtomicTypes.ListStyleType.toString(style) + (" " + (Css_AtomicTypes.ListStylePosition.toString(position) + (" " + (
                    typeof image === "number" ? Css_AtomicTypes.ListStyleImage.toString(image) : Css_AtomicTypes.Url.toString(image)
                  ))))
          ]);
}

function listStyleImage(x) {
  return /* D */Block.__(0, [
            "listStyleImage",
            typeof x === "number" ? (
                x >= -878767996 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.ListStyleImage.toString(x)
              ) : Css_AtomicTypes.Url.toString(x)
          ]);
}

function listStyleType(x) {
  return /* D */Block.__(0, [
            "listStyleType",
            x !== -878767996 && x !== -601204732 && x !== 653193961 ? Css_AtomicTypes.ListStyleType.toString(x) : Css_AtomicTypes.Cascading.toString(x)
          ]);
}

function listStylePosition(x) {
  return /* D */Block.__(0, [
            "listStylePosition",
            x >= -430011099 && x < 653193961 ? Css_AtomicTypes.ListStylePosition.toString(x) : Css_AtomicTypes.Cascading.toString(x)
          ]);
}

function marginToString(x) {
  if (typeof x === "number" && x < -789508312) {
    return Css_AtomicTypes.Margin.toString(x);
  } else {
    return Css_AtomicTypes.Length.toString(x);
  }
}

function margin(x) {
  return /* D */Block.__(0, [
            "margin",
            marginToString(x)
          ]);
}

function margin2(v, h) {
  return /* D */Block.__(0, [
            "margin",
            marginToString(v) + (" " + marginToString(h))
          ]);
}

function margin3(top, h, bottom) {
  return /* D */Block.__(0, [
            "margin",
            marginToString(top) + (" " + (marginToString(h) + (" " + marginToString(bottom))))
          ]);
}

function margin4(top, right, bottom, left) {
  return /* D */Block.__(0, [
            "margin",
            marginToString(top) + (" " + (marginToString(right) + (" " + (marginToString(bottom) + (" " + marginToString(left))))))
          ]);
}

function marginLeft(x) {
  return /* D */Block.__(0, [
            "marginLeft",
            marginToString(x)
          ]);
}

function marginRight(x) {
  return /* D */Block.__(0, [
            "marginRight",
            marginToString(x)
          ]);
}

function marginTop(x) {
  return /* D */Block.__(0, [
            "marginTop",
            marginToString(x)
          ]);
}

function marginBottom(x) {
  return /* D */Block.__(0, [
            "marginBottom",
            marginToString(x)
          ]);
}

function maxHeight(x) {
  return /* D */Block.__(0, [
            "maxHeight",
            typeof x === "number" ? (
                x !== -789508312 ? (
                    x >= -922086727 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.MaxHeight.toString(x)
                  ) : Css_AtomicTypes.Length.toString(x)
              ) : (
                x[0] !== -119887163 ? Css_AtomicTypes.Length.toString(x) : Css_AtomicTypes.Percentage.toString(x)
              )
          ]);
}

function maxWidth(x) {
  return /* D */Block.__(0, [
            "maxWidth",
            typeof x === "number" ? (
                x !== -789508312 ? (
                    x >= -922086727 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.MaxWidth.toString(x)
                  ) : Css_AtomicTypes.Length.toString(x)
              ) : (
                x[0] !== -119887163 ? Css_AtomicTypes.Length.toString(x) : Css_AtomicTypes.Percentage.toString(x)
              )
          ]);
}

function minHeight(x) {
  return /* D */Block.__(0, [
            "minHeight",
            typeof x === "number" ? (
                x !== -789508312 ? (
                    x >= -1065951376 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.Height.toString(x)
                  ) : Css_AtomicTypes.Length.toString(x)
              ) : (
                x[0] !== -119887163 ? Css_AtomicTypes.Length.toString(x) : Css_AtomicTypes.Percentage.toString(x)
              )
          ]);
}

function minWidth(x) {
  return /* D */Block.__(0, [
            "minWidth",
            typeof x === "number" ? (
                x !== -789508312 ? (
                    x !== -195805336 && x >= -1065951376 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.Width.toString(x)
                  ) : Css_AtomicTypes.Length.toString(x)
              ) : (
                x[0] !== -119887163 ? Css_AtomicTypes.Length.toString(x) : Css_AtomicTypes.Percentage.toString(x)
              )
          ]);
}

function objectFit(x) {
  return /* D */Block.__(0, [
            "objectFit",
            x >= 427065300 ? (
                x >= 653193961 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.ObjectFit.toString(x)
              ) : (
                x >= -878767996 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.ObjectFit.toString(x)
              )
          ]);
}

function objectPosition(x) {
  return /* D */Block.__(0, [
            "objectPosition",
            string_of_backgroundposition(x)
          ]);
}

function opacity(x) {
  return /* D */Block.__(0, [
            "opacity",
            x.toString()
          ]);
}

function outline(size, style, color) {
  return /* D */Block.__(0, [
            "outline",
            Css_AtomicTypes.Length.toString(size) + (" " + (Css_AtomicTypes.OutlineStyle.toString(style) + (" " + Css_AtomicTypes.Color.toString(color))))
          ]);
}

function outlineColor(x) {
  return /* D */Block.__(0, [
            "outlineColor",
            Css_AtomicTypes.Color.toString(x)
          ]);
}

function outlineOffset(x) {
  return /* D */Block.__(0, [
            "outlineOffset",
            Css_AtomicTypes.Length.toString(x)
          ]);
}

function outlineStyle(x) {
  return /* D */Block.__(0, [
            "outlineStyle",
            Css_AtomicTypes.OutlineStyle.toString(x)
          ]);
}

function outlineWidth(x) {
  return /* D */Block.__(0, [
            "outlineWidth",
            Css_AtomicTypes.Length.toString(x)
          ]);
}

function overflow(x) {
  return /* D */Block.__(0, [
            "overflow",
            Css_AtomicTypes.Overflow.toString(x)
          ]);
}

function overflowX(x) {
  return /* D */Block.__(0, [
            "overflowX",
            Css_AtomicTypes.Overflow.toString(x)
          ]);
}

function overflowY(x) {
  return /* D */Block.__(0, [
            "overflowY",
            Css_AtomicTypes.Overflow.toString(x)
          ]);
}

function overflowWrap(x) {
  return /* D */Block.__(0, [
            "overflowWrap",
            x >= 812216871 ? Css_AtomicTypes.OverflowWrap.toString(x) : Css_AtomicTypes.Cascading.toString(x)
          ]);
}

function padding(x) {
  return /* D */Block.__(0, [
            "padding",
            Css_AtomicTypes.Length.toString(x)
          ]);
}

function padding2(v, h) {
  return /* D */Block.__(0, [
            "padding",
            Css_AtomicTypes.Length.toString(v) + (" " + Css_AtomicTypes.Length.toString(h))
          ]);
}

function padding3(top, h, bottom) {
  return /* D */Block.__(0, [
            "padding",
            Css_AtomicTypes.Length.toString(top) + (" " + (Css_AtomicTypes.Length.toString(h) + (" " + Css_AtomicTypes.Length.toString(bottom))))
          ]);
}

function padding4(top, right, bottom, left) {
  return /* D */Block.__(0, [
            "padding",
            Css_AtomicTypes.Length.toString(top) + (" " + (Css_AtomicTypes.Length.toString(right) + (" " + (Css_AtomicTypes.Length.toString(bottom) + (" " + Css_AtomicTypes.Length.toString(left))))))
          ]);
}

function paddingBottom(x) {
  return /* D */Block.__(0, [
            "paddingBottom",
            Css_AtomicTypes.Length.toString(x)
          ]);
}

function paddingLeft(x) {
  return /* D */Block.__(0, [
            "paddingLeft",
            Css_AtomicTypes.Length.toString(x)
          ]);
}

function paddingRight(x) {
  return /* D */Block.__(0, [
            "paddingRight",
            Css_AtomicTypes.Length.toString(x)
          ]);
}

function paddingTop(x) {
  return /* D */Block.__(0, [
            "paddingTop",
            Css_AtomicTypes.Length.toString(x)
          ]);
}

function perspective(x) {
  return /* D */Block.__(0, [
            "perspective",
            typeof x === "number" && x !== -789508312 ? (
                x >= -922086727 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.Perspective.toString(x)
              ) : Css_AtomicTypes.Length.toString(x)
          ]);
}

function perspectiveOrigin(x, y) {
  return /* D */Block.__(0, [
            "perspectiveOrigin",
            (
              typeof x === "number" && x < -789508312 ? Css_AtomicTypes.Perspective.toString(x) : Css_AtomicTypes.Length.toString(x)
            ) + (" " + (
                typeof y === "number" && y < -789508312 ? Css_AtomicTypes.Perspective.toString(y) : Css_AtomicTypes.Length.toString(y)
              ))
          ]);
}

function pointerEvents(x) {
  return /* D */Block.__(0, [
            "pointerEvents",
            x >= -878767996 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.PointerEvents.toString(x)
          ]);
}

function position(x) {
  return /* D */Block.__(0, [
            "position",
            x >= 10615156 ? (
                x !== 653193961 ? Css_AtomicTypes.Position.toString(x) : Css_AtomicTypes.Cascading.toString(x)
              ) : (
                x >= -878767996 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.Position.toString(x)
              )
          ]);
}

function justifySelf(x) {
  return /* D */Block.__(0, [
            "justifySelf",
            x >= 51834799 ? (
                x !== 287825029 ? (
                    x !== 653193961 ? (
                        x !== 812216871 ? Css_AtomicTypes.PositionalAlignment.toString(x) : Css_AtomicTypes.JustifySelf.toString(x)
                      ) : Css_AtomicTypes.Cascading.toString(x)
                  ) : Css_AtomicTypes.BaselineAlignment.toString(x)
              ) : (
                x !== -379319332 ? (
                    x >= -878767996 ? (
                        x >= -162316795 ? Css_AtomicTypes.JustifySelf.toString(x) : Css_AtomicTypes.Cascading.toString(x)
                      ) : (
                        x >= -1021944796 ? Css_AtomicTypes.PositionalAlignment.toString(x) : Css_AtomicTypes.JustifySelf.toString(x)
                      )
                  ) : Css_AtomicTypes.PositionalAlignment.toString(x)
              )
          ]);
}

function resize(x) {
  return /* D */Block.__(0, [
            "resize",
            x >= -367674250 ? (
                x !== 653193961 ? Css_AtomicTypes.Resize.toString(x) : Css_AtomicTypes.Cascading.toString(x)
              ) : (
                x >= -878767996 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.Resize.toString(x)
              )
          ]);
}

function right(x) {
  return /* D */Block.__(0, [
            "right",
            typeof x === "number" && x !== -789508312 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.Length.toString(x)
          ]);
}

function tableLayout(x) {
  return /* D */Block.__(0, [
            "tableLayout",
            x !== 10615156 && x >= -1065951376 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.TableLayout.toString(x)
          ]);
}

function textAlign(x) {
  return /* D */Block.__(0, [
            "textAlign",
            x >= -379319332 ? (
                x >= 653193961 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.TextAlign.toString(x)
              ) : (
                x >= -878767996 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.TextAlign.toString(x)
              )
          ]);
}

function textDecorationColor(x) {
  return /* D */Block.__(0, [
            "textDecorationColor",
            typeof x === "number" && !(x >= 292050538 && x < 653193961) ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.Color.toString(x)
          ]);
}

function textDecorationLine(x) {
  return /* D */Block.__(0, [
            "textDecorationLine",
            x >= 131142924 ? (
                x !== 653193961 ? Css_AtomicTypes.TextDecorationLine.toString(x) : Css_AtomicTypes.Cascading.toString(x)
              ) : (
                x >= -878767996 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.TextDecorationLine.toString(x)
              )
          ]);
}

function textDecorationStyle(x) {
  return /* D */Block.__(0, [
            "textDecorationStyle",
            x >= 12956715 ? (
                x !== 653193961 ? Css_AtomicTypes.TextDecorationStyle.toString(x) : Css_AtomicTypes.Cascading.toString(x)
              ) : (
                x !== -822975027 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.TextDecorationStyle.toString(x)
              )
          ]);
}

function textIndent(x) {
  return /* D */Block.__(0, [
            "textIndent",
            typeof x === "number" ? (
                x !== -789508312 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.Length.toString(x)
              ) : (
                x[0] !== -119887163 ? Css_AtomicTypes.Length.toString(x) : Css_AtomicTypes.Percentage.toString(x)
              )
          ]);
}

function textOverflow(x) {
  return /* D */Block.__(0, [
            "textOverflow",
            typeof x === "number" && x !== 166888785 && x >= -1044222255 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.TextOverflow.toString(x)
          ]);
}

function textTransform(x) {
  return /* D */Block.__(0, [
            "textTransform",
            x >= -509867604 ? (
                x >= 653193961 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.TextTransform.toString(x)
              ) : (
                x >= -878767996 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.TextTransform.toString(x)
              )
          ]);
}

function top(x) {
  return /* D */Block.__(0, [
            "top",
            typeof x === "number" && x !== -789508312 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.Length.toString(x)
          ]);
}

function transform(x) {
  return /* D */Block.__(0, [
            "transform",
            Css_AtomicTypes.Transform.toString(x)
          ]);
}

function transforms(x) {
  return /* D */Block.__(0, [
            "transform",
            join(Belt_List.map(x, Css_AtomicTypes.Transform.toString), " ")
          ]);
}

function transformOrigin(x, y) {
  return /* D */Block.__(0, [
            "transformOrigin",
            Css_AtomicTypes.Length.toString(x) + (" " + Css_AtomicTypes.Length.toString(y))
          ]);
}

function transformOrigin3d(x, y, z) {
  return /* D */Block.__(0, [
            "transformOrigin",
            Css_AtomicTypes.Length.toString(x) + (" " + (Css_AtomicTypes.Length.toString(y) + (" " + (Css_AtomicTypes.Length.toString(z) + " "))))
          ]);
}

function unsafe(property, value) {
  return /* D */Block.__(0, [
            property,
            value
          ]);
}

function userSelect(x) {
  return /* D */Block.__(0, [
            "userSelect",
            x !== -878767996 && x !== -601204732 && x < 653193961 ? Css_AtomicTypes.UserSelect.toString(x) : Css_AtomicTypes.Cascading.toString(x)
          ]);
}

function verticalAlign(x) {
  return /* D */Block.__(0, [
            "verticalAlign",
            typeof x === "number" ? (
                x !== -866200747 ? (
                    x >= -445061397 ? (
                        x >= 653193961 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.VerticalAlign.toString(x)
                      ) : (
                        x !== -789508312 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.Length.toString(x)
                      )
                  ) : Css_AtomicTypes.VerticalAlign.toString(x)
              ) : Css_AtomicTypes.Length.toString(x)
          ]);
}

function visibility(x) {
  return /* D */Block.__(0, [
            "visibility",
            x >= -601204732 ? (
                x !== 589592690 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.Visibility.toString(x)
              ) : (
                x !== -878767996 ? Css_AtomicTypes.Visibility.toString(x) : Css_AtomicTypes.Cascading.toString(x)
              )
          ]);
}

function width(x) {
  return /* D */Block.__(0, [
            "width",
            typeof x === "number" ? (
                x !== -789508312 ? (
                    x !== -195805336 && x >= -1065951376 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.Width.toString(x)
                  ) : Css_AtomicTypes.Length.toString(x)
              ) : (
                x[0] !== -119887163 ? Css_AtomicTypes.Length.toString(x) : Css_AtomicTypes.Percentage.toString(x)
              )
          ]);
}

function whiteSpace(x) {
  return /* D */Block.__(0, [
            "whiteSpace",
            x !== 653193961 && x >= -601204731 ? Css_AtomicTypes.WhiteSpace.toString(x) : Css_AtomicTypes.Cascading.toString(x)
          ]);
}

function wordBreak(x) {
  return /* D */Block.__(0, [
            "wordBreak",
            x !== -323760734 && x < 812216871 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.WordBreak.toString(x)
          ]);
}

function wordSpacing(x) {
  return /* D */Block.__(0, [
            "wordSpacing",
            typeof x === "number" ? (
                x !== -789508312 ? (
                    x >= 812216871 ? Css_AtomicTypes.WordSpacing.toString(x) : Css_AtomicTypes.Cascading.toString(x)
                  ) : Css_AtomicTypes.Length.toString(x)
              ) : (
                x[0] !== -119887163 ? Css_AtomicTypes.Length.toString(x) : Css_AtomicTypes.Percentage.toString(x)
              )
          ]);
}

function zIndex(x) {
  return /* D */Block.__(0, [
            "zIndex",
            x.toString()
          ]);
}

function media(query, rules) {
  return /* S */Block.__(1, [
            "@media " + query,
            rules
          ]);
}

function selector(selector$1, rules) {
  return /* S */Block.__(1, [
            selector$1,
            rules
          ]);
}

function active(param) {
  return /* PseudoClass */Block.__(2, [
            "active",
            param
          ]);
}

function checked(param) {
  return /* PseudoClass */Block.__(2, [
            "checked",
            param
          ]);
}

function $$default(param) {
  return /* PseudoClass */Block.__(2, [
            "default",
            param
          ]);
}

function defined(param) {
  return /* PseudoClass */Block.__(2, [
            "defined",
            param
          ]);
}

function disabled(param) {
  return /* PseudoClass */Block.__(2, [
            "disabled",
            param
          ]);
}

function empty(param) {
  return /* PseudoClass */Block.__(2, [
            "empty",
            param
          ]);
}

function enabled(param) {
  return /* PseudoClass */Block.__(2, [
            "enabled",
            param
          ]);
}

function first(param) {
  return /* PseudoClass */Block.__(2, [
            "first",
            param
          ]);
}

function firstChild(param) {
  return /* PseudoClass */Block.__(2, [
            "first-child",
            param
          ]);
}

function firstOfType(param) {
  return /* PseudoClass */Block.__(2, [
            "first-of-type",
            param
          ]);
}

function focus(param) {
  return /* PseudoClass */Block.__(2, [
            "focus",
            param
          ]);
}

function focusWithin(param) {
  return /* PseudoClass */Block.__(2, [
            "focus-within",
            param
          ]);
}

function host(selector, rules) {
  if (selector !== undefined) {
    return /* PseudoClassParam */Block.__(3, [
              "host",
              selector,
              rules
            ]);
  } else {
    return /* PseudoClass */Block.__(2, [
              "host",
              rules
            ]);
  }
}

function hover(param) {
  return /* PseudoClass */Block.__(2, [
            "hover",
            param
          ]);
}

function indeterminate(param) {
  return /* PseudoClass */Block.__(2, [
            "indeterminate",
            param
          ]);
}

function inRange(param) {
  return /* PseudoClass */Block.__(2, [
            "in-range",
            param
          ]);
}

function invalid(param) {
  return /* PseudoClass */Block.__(2, [
            "invalid",
            param
          ]);
}

function lang(code, rules) {
  return /* PseudoClassParam */Block.__(3, [
            "lang",
            code,
            rules
          ]);
}

function lastChild(param) {
  return /* PseudoClass */Block.__(2, [
            "last-child",
            param
          ]);
}

function lastOfType(param) {
  return /* PseudoClass */Block.__(2, [
            "last-of-type",
            param
          ]);
}

function link(param) {
  return /* PseudoClass */Block.__(2, [
            "link",
            param
          ]);
}

function not__(selector, rules) {
  return /* PseudoClassParam */Block.__(3, [
            "not",
            selector,
            rules
          ]);
}

function toString(param) {
  if (typeof param === "number") {
    if (param >= 5542319) {
      return "odd";
    } else {
      return "even";
    }
  } else if (param[0] >= 4846113) {
    var match = param[1];
    return match[0].toString() + ("n+" + match[1].toString());
  } else {
    return param[1].toString() + "n";
  }
}

var Nth = {
  toString: toString
};

function nthChild(x, rules) {
  return /* PseudoClassParam */Block.__(3, [
            "nth-child",
            toString(x),
            rules
          ]);
}

function nthLastChild(x, rules) {
  return /* PseudoClassParam */Block.__(3, [
            "nth-last-child",
            toString(x),
            rules
          ]);
}

function nthLastOfType(x, rules) {
  return /* PseudoClassParam */Block.__(3, [
            "nth-last-of-type",
            toString(x),
            rules
          ]);
}

function nthOfType(x, rules) {
  return /* PseudoClassParam */Block.__(3, [
            "nth-of-type",
            toString(x),
            rules
          ]);
}

function onlyChild(param) {
  return /* PseudoClass */Block.__(2, [
            "only-child",
            param
          ]);
}

function onlyOfType(param) {
  return /* PseudoClass */Block.__(2, [
            "only-of-type",
            param
          ]);
}

function optional(param) {
  return /* PseudoClass */Block.__(2, [
            "optional",
            param
          ]);
}

function outOfRange(param) {
  return /* PseudoClass */Block.__(2, [
            "out-of-range",
            param
          ]);
}

function readOnly(param) {
  return /* PseudoClass */Block.__(2, [
            "read-only",
            param
          ]);
}

function readWrite(param) {
  return /* PseudoClass */Block.__(2, [
            "read-write",
            param
          ]);
}

function required(param) {
  return /* PseudoClass */Block.__(2, [
            "required",
            param
          ]);
}

function root(param) {
  return /* PseudoClass */Block.__(2, [
            "root",
            param
          ]);
}

function scope(param) {
  return /* PseudoClass */Block.__(2, [
            "scope",
            param
          ]);
}

function target(param) {
  return /* PseudoClass */Block.__(2, [
            "target",
            param
          ]);
}

function valid(param) {
  return /* PseudoClass */Block.__(2, [
            "valid",
            param
          ]);
}

function visited(param) {
  return /* PseudoClass */Block.__(2, [
            "visited",
            param
          ]);
}

function after(param) {
  return /* S */Block.__(1, [
            "::after",
            param
          ]);
}

function before(param) {
  return /* S */Block.__(1, [
            "::before",
            param
          ]);
}

function firstLetter(param) {
  return /* S */Block.__(1, [
            "::first-letter",
            param
          ]);
}

function firstLine(param) {
  return /* S */Block.__(1, [
            "::first-line",
            param
          ]);
}

function selection(param) {
  return /* S */Block.__(1, [
            "::selection",
            param
          ]);
}

function child(x) {
  var partial_arg = " > " + x;
  return (function (param) {
      return /* S */Block.__(1, [
                partial_arg,
                param
              ]);
    });
}

function children(param) {
  return /* S */Block.__(1, [
            " > *",
            param
          ]);
}

function directSibling(param) {
  return /* S */Block.__(1, [
            " + ",
            param
          ]);
}

function placeholder(param) {
  return /* S */Block.__(1, [
            "::placeholder",
            param
          ]);
}

function siblings(param) {
  return /* S */Block.__(1, [
            " ~ ",
            param
          ]);
}

function anyLink(param) {
  return /* S */Block.__(1, [
            ":any-link",
            param
          ]);
}

var initial = Css_AtomicTypes.Cascading.initial;

var inherit_ = Css_AtomicTypes.Cascading.inherit_;

var unset = Css_AtomicTypes.Cascading.unset;

var pct = Css_AtomicTypes.Percentage.pct;

var ch = Css_AtomicTypes.Length.ch;

var cm = Css_AtomicTypes.Length.cm;

var em = Css_AtomicTypes.Length.em;

var ex = Css_AtomicTypes.Length.ex;

var mm = Css_AtomicTypes.Length.mm;

var pt = Css_AtomicTypes.Length.pt;

var px = Css_AtomicTypes.Length.px;

var pxFloat = Css_AtomicTypes.Length.pxFloat;

var rem = Css_AtomicTypes.Length.rem;

var vh = Css_AtomicTypes.Length.vh;

var vmin = Css_AtomicTypes.Length.vmin;

var vmax = Css_AtomicTypes.Length.vmax;

var zero = Css_AtomicTypes.Length.zero;

var deg = Css_AtomicTypes.Angle.deg;

var rad = Css_AtomicTypes.Angle.rad;

var grad = Css_AtomicTypes.Angle.grad;

var turn = Css_AtomicTypes.Angle.turn;

var ltr = Css_AtomicTypes.Direction.ltr;

var rtl = Css_AtomicTypes.Direction.rtl;

var absolute = Css_AtomicTypes.Position.absolute;

var relative = Css_AtomicTypes.Position.relative;

var $$static = Css_AtomicTypes.Position.$$static;

var sticky = Css_AtomicTypes.Position.sticky;

var horizontal = Css_AtomicTypes.Resize.horizontal;

var vertical = Css_AtomicTypes.Resize.vertical;

var smallCaps = Css_AtomicTypes.FontVariant.smallCaps;

var italic = Css_AtomicTypes.FontStyle.italic;

var oblique = Css_AtomicTypes.FontStyle.oblique;

var rgb = Css_AtomicTypes.Color.rgb;

var rgba = Css_AtomicTypes.Color.rgba;

var hsl = Css_AtomicTypes.Color.hsl;

var hsla = Css_AtomicTypes.Color.hsla;

var hex = Css_AtomicTypes.Color.hex;

var currentColor = Css_AtomicTypes.Color.currentColor;

var transparent = Css_AtomicTypes.Color.transparent;

var linear = Css_AtomicTypes.TimingFunction.linear;

var ease = Css_AtomicTypes.TimingFunction.ease;

var easeIn = Css_AtomicTypes.TimingFunction.easeIn;

var easeInOut = Css_AtomicTypes.TimingFunction.easeInOut;

var easeOut = Css_AtomicTypes.TimingFunction.easeOut;

var stepStart = Css_AtomicTypes.TimingFunction.stepStart;

var stepEnd = Css_AtomicTypes.TimingFunction.stepEnd;

var steps = Css_AtomicTypes.TimingFunction.steps;

var cubicBezier = Css_AtomicTypes.TimingFunction.cubicBezier;

var marginBox = Css_AtomicTypes.GeometyBox.marginBox;

var fillBox = Css_AtomicTypes.GeometyBox.fillBox;

var strokeBox = Css_AtomicTypes.GeometyBox.strokeBox;

var viewBox = Css_AtomicTypes.GeometyBox.viewBox;

var translate = Css_AtomicTypes.Transform.translate;

var translate3d = Css_AtomicTypes.Transform.translate3d;

var translateX = Css_AtomicTypes.Transform.translateX;

var translateY = Css_AtomicTypes.Transform.translateY;

var translateZ = Css_AtomicTypes.Transform.translateZ;

var scaleX = Css_AtomicTypes.Transform.scaleX;

var scaleY = Css_AtomicTypes.Transform.scaleY;

var scaleZ = Css_AtomicTypes.Transform.scaleZ;

var rotateX = Css_AtomicTypes.Transform.rotateX;

var rotateY = Css_AtomicTypes.Transform.rotateY;

var rotateZ = Css_AtomicTypes.Transform.rotateZ;

var scale = Css_AtomicTypes.Transform.scale;

var scale3d = Css_AtomicTypes.Transform.scale3d;

var skew = Css_AtomicTypes.Transform.skew;

var skewX = Css_AtomicTypes.Transform.skewX;

var skewY = Css_AtomicTypes.Transform.skewY;

var thin = Css_AtomicTypes.FontWeight.thin;

var extraLight = Css_AtomicTypes.FontWeight.extraLight;

var light = Css_AtomicTypes.FontWeight.light;

var medium = Css_AtomicTypes.FontWeight.medium;

var semiBold = Css_AtomicTypes.FontWeight.semiBold;

var bold = Css_AtomicTypes.FontWeight.bold;

var extraBold = Css_AtomicTypes.FontWeight.extraBold;

var lighter = Css_AtomicTypes.FontWeight.lighter;

var bolder = Css_AtomicTypes.FontWeight.bolder;

var linearGradient = Css_AtomicTypes.Gradient.linearGradient;

var repeatingLinearGradient = Css_AtomicTypes.Gradient.repeatingLinearGradient;

var radialGradient = Css_AtomicTypes.Gradient.radialGradient;

var repeatingRadialGradient = Css_AtomicTypes.Gradient.repeatingRadialGradient;

var areas = Css_AtomicTypes.GridTemplateAreas.areas;

var ident = Css_AtomicTypes.GridArea.ident;

var numIdent = Css_AtomicTypes.GridArea.numIdent;

var contextMenu = Css_AtomicTypes.Cursor.contextMenu;

var help = Css_AtomicTypes.Cursor.help;

var pointer = Css_AtomicTypes.Cursor.pointer;

var progress = Css_AtomicTypes.Cursor.progress;

var wait = Css_AtomicTypes.Cursor.wait;

var cell = Css_AtomicTypes.Cursor.cell;

var crosshair = Css_AtomicTypes.Cursor.crosshair;

var verticalText = Css_AtomicTypes.Cursor.verticalText;

var alias = Css_AtomicTypes.Cursor.alias;

var copy = Css_AtomicTypes.Cursor.copy;

var move = Css_AtomicTypes.Cursor.move;

var noDrop = Css_AtomicTypes.Cursor.noDrop;

var notAllowed = Css_AtomicTypes.Cursor.notAllowed;

var grab = Css_AtomicTypes.Cursor.grab;

var grabbing = Css_AtomicTypes.Cursor.grabbing;

var allScroll = Css_AtomicTypes.Cursor.allScroll;

var colResize = Css_AtomicTypes.Cursor.colResize;

var rowResize = Css_AtomicTypes.Cursor.rowResize;

var nResize = Css_AtomicTypes.Cursor.nResize;

var eResize = Css_AtomicTypes.Cursor.eResize;

var sResize = Css_AtomicTypes.Cursor.sResize;

var wResize = Css_AtomicTypes.Cursor.wResize;

var neResize = Css_AtomicTypes.Cursor.neResize;

var nwResize = Css_AtomicTypes.Cursor.nwResize;

var seResize = Css_AtomicTypes.Cursor.seResize;

var swResize = Css_AtomicTypes.Cursor.swResize;

var ewResize = Css_AtomicTypes.Cursor.ewResize;

var nsResize = Css_AtomicTypes.Cursor.nsResize;

var neswResize = Css_AtomicTypes.Cursor.neswResize;

var nwseResize = Css_AtomicTypes.Cursor.nwseResize;

var zoomIn = Css_AtomicTypes.Cursor.zoomIn;

var zoomOut = Css_AtomicTypes.Cursor.zoomOut;

function vw(x) {
  return /* `vw */[
          26433,
          x
        ];
}

function fr(x) {
  return /* `fr */[
          22860,
          x
        ];
}

function $neg(a, b) {
  return /* `calc */[
          -1044768619,
          /* tuple */[
            /* sub */5745024,
            a,
            b
          ]
        ];
}

function $plus(a, b) {
  return /* `calc */[
          -1044768619,
          /* tuple */[
            /* add */4846113,
            a,
            b
          ]
        ];
}

var Calc = {
  $neg: $neg,
  $plus: $plus
};

function size(x, y) {
  return /* `size */[
          -866934591,
          /* tuple */[
            x,
            y
          ]
        ];
}

function count(x) {
  return /* `count */[
          -899463985,
          x
        ];
}

function localUrl(x) {
  return /* `localUrl */[
          -538079548,
          x
        ];
}

function rotate(a) {
  return /* `rotate */[
          -887077285,
          a
        ];
}

function rotate3d(x, y, z, a) {
  return /* `rotate3d */[
          142802924,
          /* tuple */[
            x,
            y,
            z,
            a
          ]
        ];
}

function url(x) {
  return /* `url */[
          5843823,
          x
        ];
}

function flex3(grow, shrink, basis) {
  return /* D */Block.__(0, [
            "flex",
            grow.toString() + (" " + (shrink.toString() + (" " + (
                    typeof basis === "number" && basis !== -789508312 ? Css_AtomicTypes.FlexBasis.toString(basis) : Css_AtomicTypes.Length.toString(basis)
                  ))))
          ]);
}

function flexBasis(x) {
  return /* D */Block.__(0, [
            "flexBasis",
            typeof x === "number" && x !== -789508312 ? Css_AtomicTypes.FlexBasis.toString(x) : Css_AtomicTypes.Length.toString(x)
          ]);
}

function order(x) {
  return /* D */Block.__(0, [
            "order",
            x.toString()
          ]);
}

function string_of_minmax(param) {
  if (typeof param === "number") {
    if (param >= -550577721) {
      if (param >= 60557045) {
        return "max-content";
      } else {
        return "min-content";
      }
    } else if (param >= -789508312) {
      return "0";
    } else {
      return "auto";
    }
  } else {
    var variant = param[0];
    if (variant >= 22643) {
      if (variant >= 25092) {
        if (variant !== 25096) {
          if (variant >= 26433) {
            if (variant >= 5691738) {
              return param[1].toString() + "rem";
            } else {
              return param[1].toString() + "vw";
            }
          } else if (variant >= 26418) {
            return param[1].toString() + "vh";
          } else {
            return param[1].toString() + "pt";
          }
        } else {
          return param[1].toString() + "px";
        }
      } else if (variant >= 24416) {
        if (variant >= 25075) {
          return param[1].toString() + "pc";
        } else {
          return param[1].toString() + "mm";
        }
      } else if (variant >= 22860) {
        return param[1].toString() + "fr";
      } else {
        return param[1].toString() + "ex";
      }
    } else if (variant >= -833470756) {
      if (variant !== -119887163) {
        if (variant >= 22186) {
          if (variant >= 22632) {
            return param[1].toString() + "em";
          } else {
            return param[1].toString() + "cm";
          }
        } else if (variant >= 22181) {
          return param[1].toString() + "ch";
        } else {
          return param[1].toString() + "vmin";
        }
      } else {
        return param[1].toString() + "%";
      }
    } else if (variant >= -977586742) {
      if (variant >= -833472530) {
        return param[1].toString() + "vmax";
      } else {
        return param[1].toString() + "in";
      }
    } else if (variant >= -1040484748) {
      return param[1].toString() + "px";
    } else {
      var match = param[1];
      if (match[0] >= 5745024) {
        return "calc(" + (Css_AtomicTypes.Length.toString(match[1]) + (" - " + (Css_AtomicTypes.Length.toString(match[2]) + ")")));
      } else {
        return "calc(" + (Css_AtomicTypes.Length.toString(match[1]) + (" + " + (Css_AtomicTypes.Length.toString(match[2]) + ")")));
      }
    }
  }
}

function string_of_dimension(param) {
  if (typeof param === "number") {
    if (param >= -789508312) {
      if (param >= -195805336) {
        if (param >= 60557045) {
          return "max-content";
        } else {
          return "fit-content";
        }
      } else if (param >= -550577721) {
        return "min-content";
      } else {
        return "0";
      }
    } else if (param >= -922086728) {
      return "none";
    } else {
      return "auto";
    }
  } else {
    var variant = param[0];
    if (variant >= 22632) {
      if (variant >= 25092) {
        if (variant !== 25096) {
          if (variant >= 26433) {
            if (variant >= 5691738) {
              return param[1].toString() + "rem";
            } else {
              return param[1].toString() + "vw";
            }
          } else if (variant >= 26418) {
            return param[1].toString() + "vh";
          } else {
            return param[1].toString() + "pt";
          }
        } else {
          return param[1].toString() + "px";
        }
      } else if (variant !== 22643) {
        if (variant >= 24416) {
          if (variant >= 25075) {
            return param[1].toString() + "pc";
          } else {
            return param[1].toString() + "mm";
          }
        } else if (variant >= 22860) {
          return param[1].toString() + "fr";
        } else {
          return param[1].toString() + "em";
        }
      } else {
        return param[1].toString() + "ex";
      }
    } else if (variant >= -833470756) {
      if (variant !== -754859950) {
        if (variant >= 22181) {
          if (variant >= 22186) {
            return param[1].toString() + "cm";
          } else {
            return param[1].toString() + "ch";
          }
        } else if (variant >= -119887163) {
          return param[1].toString() + "%";
        } else {
          return param[1].toString() + "vmin";
        }
      } else {
        var match = param[1];
        return "minmax(" + (string_of_minmax(match[0]) + ("," + (string_of_minmax(match[1]) + ")")));
      }
    } else if (variant >= -977586742) {
      if (variant >= -833472530) {
        return param[1].toString() + "vmax";
      } else {
        return param[1].toString() + "in";
      }
    } else if (variant >= -1040484748) {
      return param[1].toString() + "px";
    } else {
      var match$1 = param[1];
      if (match$1[0] >= 5745024) {
        return "calc(" + (Css_AtomicTypes.Length.toString(match$1[1]) + (" - " + (Css_AtomicTypes.Length.toString(match$1[2]) + ")")));
      } else {
        return "calc(" + (Css_AtomicTypes.Length.toString(match$1[1]) + (" + " + (Css_AtomicTypes.Length.toString(match$1[2]) + ")")));
      }
    }
  }
}

function gridLengthToJs(param) {
  if (typeof param === "number") {
    if (param >= -550577721) {
      if (param >= 60557045) {
        return "max-content";
      } else {
        return "min-content";
      }
    } else if (param >= -789508312) {
      return "0";
    } else {
      return "auto";
    }
  } else {
    var variant = param[0];
    if (variant >= 22643) {
      if (variant >= 25096) {
        if (variant !== 26418) {
          if (variant >= 5691738) {
            if (variant >= 108828507) {
              var match = param[1];
              return "repeat(" + (Css_AtomicTypes.RepeatValue.toString(match[0]) + (", " + (string_of_dimension(match[1]) + ")")));
            } else {
              return param[1].toString() + "rem";
            }
          } else if (variant >= 26433) {
            return param[1].toString() + "vw";
          } else {
            return param[1].toString() + "px";
          }
        } else {
          return param[1].toString() + "vh";
        }
      } else if (variant !== 22860) {
        if (variant >= 25075) {
          if (variant >= 25092) {
            return param[1].toString() + "pt";
          } else {
            return param[1].toString() + "pc";
          }
        } else if (variant >= 24416) {
          return param[1].toString() + "mm";
        } else {
          return param[1].toString() + "ex";
        }
      } else {
        return param[1].toString() + "fr";
      }
    } else if (variant >= -754859950) {
      if (variant !== -119887163) {
        if (variant >= 22186) {
          if (variant >= 22632) {
            return param[1].toString() + "em";
          } else {
            return param[1].toString() + "cm";
          }
        } else if (variant >= 22181) {
          return param[1].toString() + "ch";
        } else {
          var match$1 = param[1];
          return "minmax(" + (string_of_minmax(match$1[0]) + ("," + (string_of_minmax(match$1[1]) + ")")));
        }
      } else {
        return param[1].toString() + "%";
      }
    } else if (variant !== -1040484748) {
      if (variant >= -833472530) {
        if (variant >= -833470756) {
          return param[1].toString() + "vmin";
        } else {
          return param[1].toString() + "vmax";
        }
      } else if (variant >= -977586742) {
        return param[1].toString() + "in";
      } else {
        var match$2 = param[1];
        if (match$2[0] >= 5745024) {
          return "calc(" + (Css_AtomicTypes.Length.toString(match$2[1]) + (" - " + (Css_AtomicTypes.Length.toString(match$2[2]) + ")")));
        } else {
          return "calc(" + (Css_AtomicTypes.Length.toString(match$2[1]) + (" + " + (Css_AtomicTypes.Length.toString(match$2[2]) + ")")));
        }
      }
    } else {
      return param[1].toString() + "px";
    }
  }
}

function string_of_dimensions(dimensions) {
  return $$String.concat(" ", List.map(gridLengthToJs, dimensions));
}

function gridTemplateColumns(dimensions) {
  return /* D */Block.__(0, [
            "gridTemplateColumns",
            string_of_dimensions(dimensions)
          ]);
}

function gridTemplateRows(dimensions) {
  return /* D */Block.__(0, [
            "gridTemplateRows",
            string_of_dimensions(dimensions)
          ]);
}

function gridAutoColumns(dimensions) {
  return /* D */Block.__(0, [
            "gridAutoColumns",
            string_of_dimension(dimensions)
          ]);
}

function gridAutoRows(dimensions) {
  return /* D */Block.__(0, [
            "gridAutoRows",
            string_of_dimension(dimensions)
          ]);
}

function gridArea(s) {
  return /* D */Block.__(0, [
            "gridArea",
            typeof s === "number" && s >= -878767996 ? Css_AtomicTypes.Cascading.toString(s) : Css_AtomicTypes.GridArea.toString(s)
          ]);
}

function gridArea2(s, s2) {
  return /* D */Block.__(0, [
            "gridArea",
            Css_AtomicTypes.GridArea.toString(s) + (" / " + Css_AtomicTypes.GridArea.toString(s2))
          ]);
}

function gridArea3(s, s2, s3) {
  return /* D */Block.__(0, [
            "gridArea",
            Css_AtomicTypes.GridArea.toString(s) + (" / " + (Css_AtomicTypes.GridArea.toString(s2) + (" / " + Css_AtomicTypes.GridArea.toString(s3))))
          ]);
}

function gridArea4(s, s2, s3, s4) {
  return /* D */Block.__(0, [
            "gridArea",
            Css_AtomicTypes.GridArea.toString(s) + (" / " + (Css_AtomicTypes.GridArea.toString(s2) + (" / " + (Css_AtomicTypes.GridArea.toString(s3) + (" / " + Css_AtomicTypes.GridArea.toString(s4))))))
          ]);
}

function gridTemplateAreas(l) {
  return /* D */Block.__(0, [
            "gridTemplateAreas",
            typeof l === "number" && l >= -878767996 ? Css_AtomicTypes.Cascading.toString(l) : Css_AtomicTypes.GridTemplateAreas.toString(l)
          ]);
}

function string_of_filter(param) {
  if (typeof param === "number") {
    if (param >= -601204732) {
      if (param >= 653193961) {
        return "unset";
      } else {
        return "inherit";
      }
    } else if (param >= -878767996) {
      return "initial";
    } else {
      return "none";
    }
  } else {
    var variant = param[0];
    if (variant >= -97740042) {
      if (variant >= 5843823) {
        if (variant >= 934407426) {
          if (variant >= 1036870963) {
            return "hue-rotate(" + (Css_AtomicTypes.Angle.toString(param[1]) + ")");
          } else {
            return "contrast(" + (param[1].toString() + "%)");
          }
        } else if (variant >= 534308630) {
          return "invert(" + (param[1].toString() + "%)");
        } else {
          return "url(" + (param[1] + ")");
        }
      } else if (variant >= -29929489) {
        return "saturate(" + (param[1].toString() + "%)");
      } else {
        return "sepia(" + (param[1].toString() + "%)");
      }
    } else if (variant !== -594525841) {
      if (variant >= -345678389) {
        if (variant >= -214464569) {
          return "grayscale(" + (param[1].toString() + "%)");
        } else {
          return "opacity(" + (param[1].toString() + "%)");
        }
      } else if (variant >= -445542959) {
        return "brightness(" + (param[1].toString() + "%)");
      } else {
        return "blur(" + (Css_AtomicTypes.Length.toString(param[1]) + ")");
      }
    } else {
      var match = param[1];
      return "drop-shadow(" + (Css_AtomicTypes.Length.toString(match[0]) + (" " + (Css_AtomicTypes.Length.toString(match[1]) + (" " + (Css_AtomicTypes.Length.toString(match[2]) + (" " + (Css_AtomicTypes.Color.toString(match[3]) + ")")))))));
    }
  }
}

function filter(x) {
  return /* D */Block.__(0, [
            "filter",
            join(Belt_List.map(x, string_of_filter), " ")
          ]);
}

function box(xOpt, yOpt, blurOpt, spreadOpt, insetOpt, color) {
  var x = xOpt !== undefined ? xOpt : zero;
  var y = yOpt !== undefined ? yOpt : zero;
  var blur = blurOpt !== undefined ? blurOpt : zero;
  var spread = spreadOpt !== undefined ? spreadOpt : zero;
  var inset = insetOpt !== undefined ? insetOpt : false;
  return /* `shadow */[
          488687584,
          Css_AtomicTypes.Length.toString(x) + (" " + (Css_AtomicTypes.Length.toString(y) + (" " + (Css_AtomicTypes.Length.toString(blur) + (" " + (Css_AtomicTypes.Length.toString(spread) + (" " + (Css_AtomicTypes.Color.toString(color) + (
                            inset ? " inset" : ""
                          )))))))))
        ];
}

function text(xOpt, yOpt, blurOpt, color) {
  var x = xOpt !== undefined ? xOpt : zero;
  var y = yOpt !== undefined ? yOpt : zero;
  var blur = blurOpt !== undefined ? blurOpt : zero;
  return /* `shadow */[
          488687584,
          Css_AtomicTypes.Length.toString(x) + (" " + (Css_AtomicTypes.Length.toString(y) + (" " + (Css_AtomicTypes.Length.toString(blur) + (" " + Css_AtomicTypes.Color.toString(color))))))
        ];
}

function toString$1(param) {
  if (typeof param === "number") {
    return "none";
  } else {
    return param[1];
  }
}

var Shadow = {
  box: box,
  text: text,
  toString: toString$1
};

function boxShadow(x) {
  return /* D */Block.__(0, [
            "boxShadow",
            typeof x === "number" && x >= -878767996 ? Css_AtomicTypes.Cascading.toString(x) : toString$1(x)
          ]);
}

function boxShadows(x) {
  return /* D */Block.__(0, [
            "boxShadow",
            join(Belt_List.map(x, toString$1), ", ")
          ]);
}

function string_of_borderstyle(b) {
  if (b !== -878767996 && b !== -601204732 && b !== 653193961) {
    return Css_AtomicTypes.BorderStyle.toString(b);
  } else {
    return Css_AtomicTypes.Cascading.toString(b);
  }
}

function border(px, style, color) {
  return /* D */Block.__(0, [
            "border",
            Css_AtomicTypes.Length.toString(px) + (" " + (string_of_borderstyle(style) + (" " + Css_AtomicTypes.Color.toString(color))))
          ]);
}

function borderStyle(x) {
  return /* D */Block.__(0, [
            "borderStyle",
            string_of_borderstyle(x)
          ]);
}

function borderLeft(px, style, color) {
  return /* D */Block.__(0, [
            "borderLeft",
            Css_AtomicTypes.Length.toString(px) + (" " + (string_of_borderstyle(style) + (" " + Css_AtomicTypes.Color.toString(color))))
          ]);
}

function borderLeftStyle(x) {
  return /* D */Block.__(0, [
            "borderLeftStyle",
            string_of_borderstyle(x)
          ]);
}

function borderRight(px, style, color) {
  return /* D */Block.__(0, [
            "borderRight",
            Css_AtomicTypes.Length.toString(px) + (" " + (string_of_borderstyle(style) + (" " + Css_AtomicTypes.Color.toString(color))))
          ]);
}

function borderRightStyle(x) {
  return /* D */Block.__(0, [
            "borderRightStyle",
            string_of_borderstyle(x)
          ]);
}

function borderTop(px, style, color) {
  return /* D */Block.__(0, [
            "borderTop",
            Css_AtomicTypes.Length.toString(px) + (" " + (string_of_borderstyle(style) + (" " + Css_AtomicTypes.Color.toString(color))))
          ]);
}

function borderTopStyle(x) {
  return /* D */Block.__(0, [
            "borderTopStyle",
            string_of_borderstyle(x)
          ]);
}

function borderBottom(px, style, color) {
  return /* D */Block.__(0, [
            "borderBottom",
            Css_AtomicTypes.Length.toString(px) + (" " + (string_of_borderstyle(style) + (" " + Css_AtomicTypes.Color.toString(color))))
          ]);
}

function borderBottomStyle(x) {
  return /* D */Block.__(0, [
            "borderBottomStyle",
            string_of_borderstyle(x)
          ]);
}

function background(x) {
  return /* D */Block.__(0, [
            "background",
            string_of_background(x)
          ]);
}

function backgrounds(bg) {
  return /* D */Block.__(0, [
            "background",
            join(Belt_List.map(bg, string_of_background), ", ")
          ]);
}

function backgroundSize(x) {
  var tmp;
  if (typeof x === "number") {
    tmp = x !== -899416265 ? (
        x >= 427065300 ? "contain" : "auto"
      ) : "cover";
  } else {
    var match = x[1];
    tmp = Css_AtomicTypes.Length.toString(match[0]) + (" " + Css_AtomicTypes.Length.toString(match[1]));
  }
  return /* D */Block.__(0, [
            "backgroundSize",
            tmp
          ]);
}

function fontFace(fontFamily, src, fontStyle, fontWeight, fontDisplay, param) {
  var fontStyle$1 = Js_option.map((function (value) {
          return Css_AtomicTypes.FontStyle.toString(value);
        }), fontStyle);
  var src$1 = $$String.concat(", ", List.map((function (param) {
              if (param[0] >= 5843823) {
                return "url(\"" + (String(param[1]) + "\")");
              } else {
                return "local(\"" + (String(param[1]) + "\")");
              }
            }), src));
  var fontStyle$2 = Belt_Option.mapWithDefault(fontStyle$1, "", (function (s) {
          return "font-style: " + (s + ";");
        }));
  var fontWeight$1 = Belt_Option.mapWithDefault(fontWeight, "", (function (w) {
          return "font-weight: " + ((
                    typeof w === "number" && !(w !== -878767996 && w !== -601204732 && w !== 653193961) ? Css_AtomicTypes.Cascading.toString(w) : Css_AtomicTypes.FontWeight.toString(w)
                  ) + ";");
        }));
  var fontDisplay$1 = Belt_Option.mapWithDefault(fontDisplay, "", (function (f) {
          return "font-display: " + (Css_AtomicTypes.FontDisplay.toString(f) + ";");
        }));
  return "@font-face {\r\n     font-family: " + (String(fontFamily) + (";\r\n     src: " + (String(src$1) + (";\r\n     " + (String(fontStyle$2) + ("\r\n     " + (String(fontWeight$1) + ("\r\n     " + (String(fontDisplay$1) + "\r\n   }")))))))));
}

function textDecoration(x) {
  return /* D */Block.__(0, [
            "textDecoration",
            x >= -601204732 ? (
                x >= 412155569 ? (
                    x >= 653193961 ? "unset" : "line-through"
                  ) : (
                    x >= 131142924 ? "underline" : "inherit"
                  )
              ) : (
                x !== -922086728 ? (
                    x >= -878767996 ? "initial" : "overline"
                  ) : "none"
              )
          ]);
}

function textShadow(x) {
  return /* D */Block.__(0, [
            "textShadow",
            typeof x === "number" && x >= -878767996 ? Css_AtomicTypes.Cascading.toString(x) : toString$1(x)
          ]);
}

function textShadows(x) {
  return /* D */Block.__(0, [
            "textShadow",
            join(Belt_List.map(x, toString$1), ", ")
          ]);
}

function transformStyle(x) {
  return /* D */Block.__(0, [
            "transformStyle",
            x !== 589702045 && x >= -1010955334 ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.TransformStyle.toString(x)
          ]);
}

function shorthand(durationOpt, delayOpt, timingFunctionOpt, property) {
  var duration = durationOpt !== undefined ? durationOpt : 0;
  var delay = delayOpt !== undefined ? delayOpt : 0;
  var timingFunction = timingFunctionOpt !== undefined ? timingFunctionOpt : /* ease */-1022587922;
  return /* `value */[
          834174833,
          duration.toString() + "ms " + (Css_AtomicTypes.TimingFunction.toString(timingFunction) + (" " + (delay.toString() + "ms " + property)))
        ];
}

function toString$2(param) {
  return param[1];
}

var Transition = {
  shorthand: shorthand,
  toString: toString$2
};

function transitionValue(x) {
  return /* D */Block.__(0, [
            "transition",
            x[1]
          ]);
}

function transitionList(x) {
  return /* D */Block.__(0, [
            "transition",
            join(Belt_List.map(x, toString$2), ", ")
          ]);
}

function transition(duration, delay, timingFunction, property) {
  var x = shorthand(duration, delay, timingFunction, property);
  return /* D */Block.__(0, [
            "transition",
            x[1]
          ]);
}

function transitionDelay(i) {
  return /* D */Block.__(0, [
            "transitionDelay",
            i.toString() + "ms"
          ]);
}

function transitionDuration(i) {
  return /* D */Block.__(0, [
            "transitionDuration",
            i.toString() + "ms"
          ]);
}

function transitionTimingFunction(x) {
  return /* D */Block.__(0, [
            "transitionTimingFunction",
            Css_AtomicTypes.TimingFunction.toString(x)
          ]);
}

function transitionProperty(x) {
  return /* D */Block.__(0, [
            "transitionProperty",
            x
          ]);
}

function shorthand$1(durationOpt, delayOpt, directionOpt, timingFunctionOpt, fillModeOpt, playStateOpt, iterationCountOpt, name) {
  var duration = durationOpt !== undefined ? durationOpt : 0;
  var delay = delayOpt !== undefined ? delayOpt : 0;
  var direction = directionOpt !== undefined ? directionOpt : /* normal */812216871;
  var timingFunction = timingFunctionOpt !== undefined ? timingFunctionOpt : /* ease */-1022587922;
  var fillMode = fillModeOpt !== undefined ? fillModeOpt : /* none */-922086728;
  var playState = playStateOpt !== undefined ? playStateOpt : /* running */-345412097;
  var iterationCount = iterationCountOpt !== undefined ? iterationCountOpt : /* `count */[
      -899463985,
      1
    ];
  return /* `value */[
          834174833,
          name + (" " + (duration.toString() + "ms " + (Css_AtomicTypes.TimingFunction.toString(timingFunction) + (" " + (delay.toString() + "ms " + (Css_AtomicTypes.AnimationIterationCount.toString(iterationCount) + (" " + (Css_AtomicTypes.AnimationDirection.toString(direction) + (" " + (Css_AtomicTypes.AnimationFillMode.toString(fillMode) + (" " + Css_AtomicTypes.AnimationPlayState.toString(playState))))))))))))
        ];
}

function toString$3(param) {
  return param[1];
}

var Animation = {
  shorthand: shorthand$1,
  toString: toString$3
};

function animationValue(x) {
  return /* D */Block.__(0, [
            "animation",
            x[1]
          ]);
}

function animation(duration, delay, direction, timingFunction, fillMode, playState, iterationCount, name) {
  var x = shorthand$1(duration, delay, direction, timingFunction, fillMode, playState, iterationCount, name);
  return /* D */Block.__(0, [
            "animation",
            x[1]
          ]);
}

function animations(x) {
  return /* D */Block.__(0, [
            "animation",
            join(Belt_List.map(x, toString$3), ", ")
          ]);
}

function animationName(x) {
  return /* D */Block.__(0, [
            "animationName",
            x
          ]);
}

function fill(x) {
  return /* D */Block.__(0, [
            "fill",
            Css_AtomicTypes.Color.toString(x)
          ]);
}

function fillOpacity(opacity) {
  return /* D */Block.__(0, [
            "fillOpacity",
            opacity.toString()
          ]);
}

function fillRule(x) {
  return /* D */Block.__(0, [
            "fillRule",
            x >= 110563029 ? "evenodd" : "nonzero"
          ]);
}

function stroke(x) {
  return /* D */Block.__(0, [
            "stroke",
            Css_AtomicTypes.Color.toString(x)
          ]);
}

function strokeWidth(x) {
  return /* D */Block.__(0, [
            "strokeWidth",
            Css_AtomicTypes.Length.toString(x)
          ]);
}

function strokeOpacity(opacity) {
  return /* D */Block.__(0, [
            "strokeOpacity",
            opacity.toString()
          ]);
}

function strokeMiterlimit(x) {
  return /* D */Block.__(0, [
            "strokeMiterlimit",
            x.toString()
          ]);
}

function strokeLinecap(x) {
  return /* D */Block.__(0, [
            "strokeLinecap",
            x !== -655228771 ? (
                x >= -312084402 ? "round" : "butt"
              ) : "square"
          ]);
}

function strokeLinejoin(x) {
  return /* D */Block.__(0, [
            "strokeLinejoin",
            x !== 141361157 ? (
                x >= 811681914 ? "bevel" : "round"
              ) : "miter"
          ]);
}

function stopColor(x) {
  return /* D */Block.__(0, [
            "stopColor",
            Css_AtomicTypes.Color.toString(x)
          ]);
}

function stopOpacity(x) {
  return /* D */Block.__(0, [
            "stopOpacity",
            x.toString()
          ]);
}

var Types = /* alias */0;

function Make(funarg) {
  var merge = function (stylenames) {
    return funarg.mergeStyles($$Array.of_list(stylenames));
  };
  var insertRule = function (s) {
    return funarg.injectRaw(s);
  };
  var style = function (rules) {
    return funarg.make(toJson(rules));
  };
  var $$global = function (selector, rules) {
    return funarg.injectRule(Js_dict.fromList(/* :: */[
                    /* tuple */[
                      selector,
                      toJson(rules)
                    ],
                    /* [] */0
                  ]));
  };
  var keyframes = function (frames) {
    return funarg.makeKeyFrames(List.fold_left(addStop, { }, frames));
  };
  return {
          $$global: $$global,
          insertRule: insertRule,
          merge: merge,
          style: style,
          keyframes: keyframes
        };
}

var wordWrap = overflowWrap;

var auto = /* auto */-1065951377;

var none = /* none */-922086728;

var text$1 = /* text */-856044371;

var fixed = /* fixed */10615156;

var hidden = /* hidden */-862584982;

var visible = /* visible */589592690;

var scroll = /* scroll */-949692403;

var local = /* local */-118437525;

var paddingBox = /* paddingBox */972575930;

var borderBox = /* borderBox */9307263;

var contentBox = /* contentBox */-1008848302;

var noRepeat = /* noRepeat */-695430532;

var space = /* space */23497926;

var repeat = /* repeat */108828507;

var minmax = /* minmax */-754859950;

var repeatX = /* repeatX */646437021;

var repeatY = /* repeatY */646437022;

var contain = /* contain */427065300;

var cover = /* cover */-899416265;

var row = /* row */5693978;

var rowReverse = /* rowReverse */378951208;

var column = /* column */-963948842;

var columnReverse = /* columnReverse */135477484;

var wrap = /* wrap */-822134326;

var nowrap = /* nowrap */867913355;

var wrapReverse = /* wrapReverse */-463121288;

var inline = /* inline */423610969;

var block = /* block */888960333;

var contents = /* contents */790889754;

var flexBox = /* flex */-1010954439;

var grid = /* grid */-999565626;

var inlineBlock = /* inlineBlock */-147785676;

var inlineFlex = /* inlineFlex */53323314;

var inlineGrid = /* inlineGrid */64712127;

var inlineTable = /* inlineTable */-854050059;

var listItem = /* listItem */632591505;

var runIn = /* runIn */-245903344;

var table = /* table */182695950;

var tableCaption = /* tableCaption */1054826616;

var tableColumnGroup = /* tableColumnGroup */850209563;

var tableHeaderGroup = /* tableHeaderGroup */606419204;

var tableFooterGroup = /* tableFooterGroup */-1045696202;

var tableRowGroup = /* tableRowGroup */-836725517;

var tableCell = /* tableCell */793912528;

var tableColumn = /* tableColumn */97122692;

var tableRow = /* tableRow */-843129172;

var flexStart = /* flexStart */662439529;

var flexEnd = /* flexEnd */924268066;

var center = /* center */98248149;

var stretch = /* stretch */-162316795;

var spaceBetween = /* spaceBetween */516682146;

var spaceAround = /* spaceAround */-485895757;

var spaceEvenly = /* spaceEvenly */-1016061555;

var baseline = /* baseline */287825029;

var forwards = /* forwards */733653774;

var backwards = /* backwards */245861168;

var both = /* both */-1055160191;

var infinite = /* infinite */-630647084;

var paused = /* paused */-276545362;

var running = /* running */-345412097;

var inside = /* inside */501235708;

var outside = /* outside */-430011099;

var solid = /* solid */12956715;

var dotted = /* dotted */841979626;

var dashed = /* dashed */568403505;

var underline = /* underline */131142924;

var overline = /* overline */-986651832;

var lineThrough = /* lineThrough */412155569;

var clip = /* clip */-1044222256;

var ellipsis = /* ellipsis */166888785;

var wavy = /* wavy */-822975027;

var $$double = /* double */852175633;

var uppercase = /* uppercase */-415330030;

var lowercase = /* lowercase */-425349839;

var capitalize = /* capitalize */-509867604;

var sub = /* sub */5745024;

var $$super = /* super */79692155;

var textTop = /* textTop */516406248;

var textBottom = /* textBottom */476669816;

var middle = /* middle */-866200747;

var normal = /* normal */812216871;

var breakAll = /* breakAll */-323760734;

var keepAll = /* keepAll */892748188;

var breakWord = /* breakWord */1059921449;

var reverse = /* reverse */452737314;

var alternate = /* alternate */892586298;

var alternateReverse = /* alternateReverse */545428232;

var fill$1 = /* fill */-1011102077;

var content = /* content */427265337;

var maxContent = /* maxContent */60557045;

var minContent = /* minContent */-550577721;

var fitContent = /* fitContent */-195805336;

var all = /* all */4847905;

var round = /* round */-312084402;

var miter = /* miter */141361157;

var bevel = /* bevel */811681914;

var butt = /* butt */-1054861805;

var square = /* square */-655228771;

var transitions = transitionList;

var SVG = {
  fill: fill,
  fillRule: fillRule,
  fillOpacity: fillOpacity,
  stroke: stroke,
  strokeLinecap: strokeLinecap,
  strokeLinejoin: strokeLinejoin,
  strokeMiterlimit: strokeMiterlimit,
  strokeWidth: strokeWidth,
  strokeOpacity: strokeOpacity,
  stopColor: stopColor,
  stopOpacity: stopOpacity
};

var aliceblue = Css_Colors.aliceblue;

var antiquewhite = Css_Colors.antiquewhite;

var aqua = Css_Colors.aqua;

var aquamarine = Css_Colors.aquamarine;

var azure = Css_Colors.azure;

var beige = Css_Colors.beige;

var bisque = Css_Colors.bisque;

var black = Css_Colors.black;

var blanchedalmond = Css_Colors.blanchedalmond;

var blue = Css_Colors.blue;

var blueviolet = Css_Colors.blueviolet;

var brown = Css_Colors.brown;

var burlywood = Css_Colors.burlywood;

var cadetblue = Css_Colors.cadetblue;

var chartreuse = Css_Colors.chartreuse;

var chocolate = Css_Colors.chocolate;

var coral = Css_Colors.coral;

var cornflowerblue = Css_Colors.cornflowerblue;

var cornsilk = Css_Colors.cornsilk;

var crimson = Css_Colors.crimson;

var cyan = Css_Colors.cyan;

var darkblue = Css_Colors.darkblue;

var darkcyan = Css_Colors.darkcyan;

var darkgoldenrod = Css_Colors.darkgoldenrod;

var darkgray = Css_Colors.darkgray;

var darkgrey = Css_Colors.darkgrey;

var darkgreen = Css_Colors.darkgreen;

var darkkhaki = Css_Colors.darkkhaki;

var darkmagenta = Css_Colors.darkmagenta;

var darkolivegreen = Css_Colors.darkolivegreen;

var darkorange = Css_Colors.darkorange;

var darkorchid = Css_Colors.darkorchid;

var darkred = Css_Colors.darkred;

var darksalmon = Css_Colors.darksalmon;

var darkseagreen = Css_Colors.darkseagreen;

var darkslateblue = Css_Colors.darkslateblue;

var darkslategray = Css_Colors.darkslategray;

var darkslategrey = Css_Colors.darkslategrey;

var darkturquoise = Css_Colors.darkturquoise;

var darkviolet = Css_Colors.darkviolet;

var deeppink = Css_Colors.deeppink;

var deepskyblue = Css_Colors.deepskyblue;

var dimgray = Css_Colors.dimgray;

var dimgrey = Css_Colors.dimgrey;

var dodgerblue = Css_Colors.dodgerblue;

var firebrick = Css_Colors.firebrick;

var floralwhite = Css_Colors.floralwhite;

var forestgreen = Css_Colors.forestgreen;

var fuchsia = Css_Colors.fuchsia;

var gainsboro = Css_Colors.gainsboro;

var ghostwhite = Css_Colors.ghostwhite;

var gold = Css_Colors.gold;

var goldenrod = Css_Colors.goldenrod;

var gray = Css_Colors.gray;

var grey = Css_Colors.grey;

var green = Css_Colors.green;

var greenyellow = Css_Colors.greenyellow;

var honeydew = Css_Colors.honeydew;

var hotpink = Css_Colors.hotpink;

var indianred = Css_Colors.indianred;

var indigo = Css_Colors.indigo;

var ivory = Css_Colors.ivory;

var khaki = Css_Colors.khaki;

var lavender = Css_Colors.lavender;

var lavenderblush = Css_Colors.lavenderblush;

var lawngreen = Css_Colors.lawngreen;

var lemonchiffon = Css_Colors.lemonchiffon;

var lightblue = Css_Colors.lightblue;

var lightcoral = Css_Colors.lightcoral;

var lightcyan = Css_Colors.lightcyan;

var lightgoldenrodyellow = Css_Colors.lightgoldenrodyellow;

var lightgray = Css_Colors.lightgray;

var lightgrey = Css_Colors.lightgrey;

var lightgreen = Css_Colors.lightgreen;

var lightpink = Css_Colors.lightpink;

var lightsalmon = Css_Colors.lightsalmon;

var lightseagreen = Css_Colors.lightseagreen;

var lightskyblue = Css_Colors.lightskyblue;

var lightslategray = Css_Colors.lightslategray;

var lightslategrey = Css_Colors.lightslategrey;

var lightsteelblue = Css_Colors.lightsteelblue;

var lightyellow = Css_Colors.lightyellow;

var lime = Css_Colors.lime;

var limegreen = Css_Colors.limegreen;

var linen = Css_Colors.linen;

var magenta = Css_Colors.magenta;

var maroon = Css_Colors.maroon;

var mediumaquamarine = Css_Colors.mediumaquamarine;

var mediumblue = Css_Colors.mediumblue;

var mediumorchid = Css_Colors.mediumorchid;

var mediumpurple = Css_Colors.mediumpurple;

var mediumseagreen = Css_Colors.mediumseagreen;

var mediumslateblue = Css_Colors.mediumslateblue;

var mediumspringgreen = Css_Colors.mediumspringgreen;

var mediumturquoise = Css_Colors.mediumturquoise;

var mediumvioletred = Css_Colors.mediumvioletred;

var midnightblue = Css_Colors.midnightblue;

var mintcream = Css_Colors.mintcream;

var mistyrose = Css_Colors.mistyrose;

var moccasin = Css_Colors.moccasin;

var navajowhite = Css_Colors.navajowhite;

var navy = Css_Colors.navy;

var oldlace = Css_Colors.oldlace;

var olive = Css_Colors.olive;

var olivedrab = Css_Colors.olivedrab;

var orange = Css_Colors.orange;

var orangered = Css_Colors.orangered;

var orchid = Css_Colors.orchid;

var palegoldenrod = Css_Colors.palegoldenrod;

var palegreen = Css_Colors.palegreen;

var paleturquoise = Css_Colors.paleturquoise;

var palevioletred = Css_Colors.palevioletred;

var papayawhip = Css_Colors.papayawhip;

var peachpuff = Css_Colors.peachpuff;

var peru = Css_Colors.peru;

var pink = Css_Colors.pink;

var plum = Css_Colors.plum;

var powderblue = Css_Colors.powderblue;

var purple = Css_Colors.purple;

var rebeccapurple = Css_Colors.rebeccapurple;

var red = Css_Colors.red;

var rosybrown = Css_Colors.rosybrown;

var royalblue = Css_Colors.royalblue;

var saddlebrown = Css_Colors.saddlebrown;

var salmon = Css_Colors.salmon;

var sandybrown = Css_Colors.sandybrown;

var seagreen = Css_Colors.seagreen;

var seashell = Css_Colors.seashell;

var sienna = Css_Colors.sienna;

var silver = Css_Colors.silver;

var skyblue = Css_Colors.skyblue;

var slateblue = Css_Colors.slateblue;

var slategray = Css_Colors.slategray;

var slategrey = Css_Colors.slategrey;

var snow = Css_Colors.snow;

var springgreen = Css_Colors.springgreen;

var steelblue = Css_Colors.steelblue;

var tan = Css_Colors.tan;

var teal = Css_Colors.teal;

var thistle = Css_Colors.thistle;

var tomato = Css_Colors.tomato;

var turquoise = Css_Colors.turquoise;

var violet = Css_Colors.violet;

var wheat = Css_Colors.wheat;

var white = Css_Colors.white;

var whitesmoke = Css_Colors.whitesmoke;

var yellow = Css_Colors.yellow;

var yellowgreen = Css_Colors.yellowgreen;

exports.Types = Types;
exports.Make = Make;
exports.toJson = toJson;
exports.important = important;
exports.label = label;
exports.Shadow = Shadow;
exports.unsafe = unsafe;
exports.alignContent = alignContent;
exports.alignItems = alignItems;
exports.alignSelf = alignSelf;
exports.animationDelay = animationDelay;
exports.animationDirection = animationDirection;
exports.animationDuration = animationDuration;
exports.animationFillMode = animationFillMode;
exports.animationIterationCount = animationIterationCount;
exports.animationPlayState = animationPlayState;
exports.animationTimingFunction = animationTimingFunction;
exports.backdropFilter = backdropFilter;
exports.backfaceVisibility = backfaceVisibility;
exports.backgroundAttachment = backgroundAttachment;
exports.backgroundColor = backgroundColor;
exports.backgroundClip = backgroundClip;
exports.backgroundImage = backgroundImage;
exports.backgroundOrigin = backgroundOrigin;
exports.backgroundPosition = backgroundPosition;
exports.backgroundPositions = backgroundPositions;
exports.backgroundPosition4 = backgroundPosition4;
exports.backgroundRepeat = backgroundRepeat;
exports.borderBottom = borderBottom;
exports.borderBottomColor = borderBottomColor;
exports.borderBottomLeftRadius = borderBottomLeftRadius;
exports.borderBottomRightRadius = borderBottomRightRadius;
exports.borderBottomStyle = borderBottomStyle;
exports.borderBottomWidth = borderBottomWidth;
exports.borderCollapse = borderCollapse;
exports.borderColor = borderColor;
exports.borderLeft = borderLeft;
exports.borderLeftColor = borderLeftColor;
exports.borderLeftStyle = borderLeftStyle;
exports.borderLeftWidth = borderLeftWidth;
exports.borderRight = borderRight;
exports.borderRightColor = borderRightColor;
exports.borderRightStyle = borderRightStyle;
exports.borderRightWidth = borderRightWidth;
exports.borderRadius = borderRadius;
exports.borderSpacing = borderSpacing;
exports.borderStyle = borderStyle;
exports.borderTopColor = borderTopColor;
exports.borderTopLeftRadius = borderTopLeftRadius;
exports.borderTopRightRadius = borderTopRightRadius;
exports.borderTopStyle = borderTopStyle;
exports.borderTopWidth = borderTopWidth;
exports.borderWidth = borderWidth;
exports.bottom = bottom;
exports.boxSizing = boxSizing;
exports.boxShadow = boxShadow;
exports.boxShadows = boxShadows;
exports.clear = clear;
exports.clipPath = clipPath;
exports.color = color;
exports.columnCount = columnCount;
exports.contentRule = contentRule;
exports.contentRules = contentRules;
exports.counterIncrement = counterIncrement;
exports.countersIncrement = countersIncrement;
exports.counterReset = counterReset;
exports.countersReset = countersReset;
exports.counterSet = counterSet;
exports.countersSet = countersSet;
exports.cursor = cursor;
exports.direction = direction;
exports.display = display;
exports.flex = flex;
exports.flexBasis = flexBasis;
exports.flexDirection = flexDirection;
exports.flexGrow = flexGrow;
exports.flexShrink = flexShrink;
exports.flexWrap = flexWrap;
exports.$$float = $$float;
exports.fontFamily = fontFamily;
exports.fontFamilies = fontFamilies;
exports.fontSize = fontSize;
exports.fontStyle = fontStyle;
exports.fontVariant = fontVariant;
exports.fontWeight = fontWeight;
exports.gridArea = gridArea;
exports.gridArea2 = gridArea2;
exports.gridArea3 = gridArea3;
exports.gridArea4 = gridArea4;
exports.gridAutoFlow = gridAutoFlow;
exports.gridColumn = gridColumn;
exports.gridColumnEnd = gridColumnEnd;
exports.gridColumnGap = gridColumnGap;
exports.gridColumnStart = gridColumnStart;
exports.gridGap = gridGap;
exports.gridRow = gridRow;
exports.gridRowEnd = gridRowEnd;
exports.gridRowGap = gridRowGap;
exports.gridRowStart = gridRowStart;
exports.gridTemplateAreas = gridTemplateAreas;
exports.height = height;
exports.justifyContent = justifyContent;
exports.justifySelf = justifySelf;
exports.left = left;
exports.letterSpacing = letterSpacing;
exports.lineHeight = lineHeight;
exports.listStyle = listStyle;
exports.listStyleImage = listStyleImage;
exports.listStyleType = listStyleType;
exports.listStylePosition = listStylePosition;
exports.margin = margin;
exports.margin2 = margin2;
exports.margin3 = margin3;
exports.margin4 = margin4;
exports.marginLeft = marginLeft;
exports.marginRight = marginRight;
exports.marginTop = marginTop;
exports.marginBottom = marginBottom;
exports.maxHeight = maxHeight;
exports.maxWidth = maxWidth;
exports.minHeight = minHeight;
exports.minWidth = minWidth;
exports.objectFit = objectFit;
exports.objectPosition = objectPosition;
exports.opacity = opacity;
exports.order = order;
exports.outline = outline;
exports.outlineColor = outlineColor;
exports.outlineOffset = outlineOffset;
exports.outlineStyle = outlineStyle;
exports.outlineWidth = outlineWidth;
exports.overflow = overflow;
exports.overflowX = overflowX;
exports.overflowY = overflowY;
exports.overflowWrap = overflowWrap;
exports.padding = padding;
exports.padding2 = padding2;
exports.padding3 = padding3;
exports.padding4 = padding4;
exports.paddingLeft = paddingLeft;
exports.paddingRight = paddingRight;
exports.paddingTop = paddingTop;
exports.paddingBottom = paddingBottom;
exports.perspective = perspective;
exports.perspectiveOrigin = perspectiveOrigin;
exports.pointerEvents = pointerEvents;
exports.position = position;
exports.resize = resize;
exports.right = right;
exports.tableLayout = tableLayout;
exports.textAlign = textAlign;
exports.textDecorationColor = textDecorationColor;
exports.textDecorationLine = textDecorationLine;
exports.textDecorationStyle = textDecorationStyle;
exports.textIndent = textIndent;
exports.textOverflow = textOverflow;
exports.textShadow = textShadow;
exports.textShadows = textShadows;
exports.textTransform = textTransform;
exports.top = top;
exports.transform = transform;
exports.transforms = transforms;
exports.transformOrigin = transformOrigin;
exports.transformOrigin3d = transformOrigin3d;
exports.transitionDelay = transitionDelay;
exports.transitionDuration = transitionDuration;
exports.transitionProperty = transitionProperty;
exports.transformStyle = transformStyle;
exports.transitionTimingFunction = transitionTimingFunction;
exports.userSelect = userSelect;
exports.verticalAlign = verticalAlign;
exports.visibility = visibility;
exports.width = width;
exports.whiteSpace = whiteSpace;
exports.wordBreak = wordBreak;
exports.wordSpacing = wordSpacing;
exports.wordWrap = wordWrap;
exports.zIndex = zIndex;
exports.selector = selector;
exports.media = media;
exports.active = active;
exports.checked = checked;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
exports.defined = defined;
exports.disabled = disabled;
exports.empty = empty;
exports.enabled = enabled;
exports.first = first;
exports.firstChild = firstChild;
exports.firstOfType = firstOfType;
exports.focus = focus;
exports.focusWithin = focusWithin;
exports.host = host;
exports.hover = hover;
exports.indeterminate = indeterminate;
exports.inRange = inRange;
exports.invalid = invalid;
exports.lang = lang;
exports.lastChild = lastChild;
exports.lastOfType = lastOfType;
exports.link = link;
exports.not__ = not__;
exports.Nth = Nth;
exports.nthChild = nthChild;
exports.nthLastChild = nthLastChild;
exports.nthLastOfType = nthLastOfType;
exports.nthOfType = nthOfType;
exports.onlyChild = onlyChild;
exports.onlyOfType = onlyOfType;
exports.optional = optional;
exports.outOfRange = outOfRange;
exports.readOnly = readOnly;
exports.readWrite = readWrite;
exports.required = required;
exports.root = root;
exports.scope = scope;
exports.target = target;
exports.valid = valid;
exports.visited = visited;
exports.after = after;
exports.before = before;
exports.firstLetter = firstLetter;
exports.firstLine = firstLine;
exports.placeholder = placeholder;
exports.selection = selection;
exports.child = child;
exports.children = children;
exports.directSibling = directSibling;
exports.siblings = siblings;
exports.anyLink = anyLink;
exports.initial = initial;
exports.inherit_ = inherit_;
exports.unset = unset;
exports.auto = auto;
exports.none = none;
exports.text = text$1;
exports.pct = pct;
exports.ch = ch;
exports.cm = cm;
exports.em = em;
exports.ex = ex;
exports.mm = mm;
exports.pt = pt;
exports.px = px;
exports.pxFloat = pxFloat;
exports.rem = rem;
exports.vh = vh;
exports.vmin = vmin;
exports.vmax = vmax;
exports.zero = zero;
exports.deg = deg;
exports.rad = rad;
exports.grad = grad;
exports.turn = turn;
exports.ltr = ltr;
exports.rtl = rtl;
exports.absolute = absolute;
exports.relative = relative;
exports.$$static = $$static;
exports.fixed = fixed;
exports.sticky = sticky;
exports.horizontal = horizontal;
exports.vertical = vertical;
exports.smallCaps = smallCaps;
exports.italic = italic;
exports.oblique = oblique;
exports.hidden = hidden;
exports.visible = visible;
exports.scroll = scroll;
exports.rgb = rgb;
exports.rgba = rgba;
exports.hsl = hsl;
exports.hsla = hsla;
exports.hex = hex;
exports.transparent = transparent;
exports.currentColor = currentColor;
exports.local = local;
exports.paddingBox = paddingBox;
exports.borderBox = borderBox;
exports.contentBox = contentBox;
exports.noRepeat = noRepeat;
exports.space = space;
exports.repeat = repeat;
exports.minmax = minmax;
exports.repeatX = repeatX;
exports.repeatY = repeatY;
exports.contain = contain;
exports.cover = cover;
exports.row = row;
exports.rowReverse = rowReverse;
exports.column = column;
exports.columnReverse = columnReverse;
exports.wrap = wrap;
exports.nowrap = nowrap;
exports.wrapReverse = wrapReverse;
exports.inline = inline;
exports.block = block;
exports.contents = contents;
exports.flexBox = flexBox;
exports.grid = grid;
exports.inlineBlock = inlineBlock;
exports.inlineFlex = inlineFlex;
exports.inlineGrid = inlineGrid;
exports.inlineTable = inlineTable;
exports.listItem = listItem;
exports.runIn = runIn;
exports.table = table;
exports.tableCaption = tableCaption;
exports.tableColumnGroup = tableColumnGroup;
exports.tableHeaderGroup = tableHeaderGroup;
exports.tableFooterGroup = tableFooterGroup;
exports.tableRowGroup = tableRowGroup;
exports.tableCell = tableCell;
exports.tableColumn = tableColumn;
exports.tableRow = tableRow;
exports.flexStart = flexStart;
exports.flexEnd = flexEnd;
exports.center = center;
exports.stretch = stretch;
exports.spaceBetween = spaceBetween;
exports.spaceAround = spaceAround;
exports.spaceEvenly = spaceEvenly;
exports.baseline = baseline;
exports.forwards = forwards;
exports.backwards = backwards;
exports.both = both;
exports.infinite = infinite;
exports.count = count;
exports.paused = paused;
exports.running = running;
exports.inside = inside;
exports.outside = outside;
exports.solid = solid;
exports.dotted = dotted;
exports.dashed = dashed;
exports.underline = underline;
exports.overline = overline;
exports.lineThrough = lineThrough;
exports.clip = clip;
exports.ellipsis = ellipsis;
exports.wavy = wavy;
exports.$$double = $$double;
exports.uppercase = uppercase;
exports.lowercase = lowercase;
exports.capitalize = capitalize;
exports.sub = sub;
exports.$$super = $$super;
exports.textTop = textTop;
exports.textBottom = textBottom;
exports.middle = middle;
exports.normal = normal;
exports.breakAll = breakAll;
exports.keepAll = keepAll;
exports.breakWord = breakWord;
exports.reverse = reverse;
exports.alternate = alternate;
exports.alternateReverse = alternateReverse;
exports.fill = fill$1;
exports.content = content;
exports.maxContent = maxContent;
exports.minContent = minContent;
exports.fitContent = fitContent;
exports.all = all;
exports.round = round;
exports.miter = miter;
exports.bevel = bevel;
exports.butt = butt;
exports.square = square;
exports.thin = thin;
exports.extraLight = extraLight;
exports.light = light;
exports.medium = medium;
exports.semiBold = semiBold;
exports.bold = bold;
exports.extraBold = extraBold;
exports.lighter = lighter;
exports.bolder = bolder;
exports.fr = fr;
exports.vw = vw;
exports.localUrl = localUrl;
exports.url = url;
exports.linear = linear;
exports.ease = ease;
exports.easeIn = easeIn;
exports.easeOut = easeOut;
exports.easeInOut = easeInOut;
exports.stepStart = stepStart;
exports.stepEnd = stepEnd;
exports.steps = steps;
exports.cubicBezier = cubicBezier;
exports.marginBox = marginBox;
exports.fillBox = fillBox;
exports.strokeBox = strokeBox;
exports.viewBox = viewBox;
exports.translate = translate;
exports.translate3d = translate3d;
exports.translateX = translateX;
exports.translateY = translateY;
exports.translateZ = translateZ;
exports.scale = scale;
exports.scale3d = scale3d;
exports.scaleX = scaleX;
exports.scaleY = scaleY;
exports.scaleZ = scaleZ;
exports.rotate = rotate;
exports.rotate3d = rotate3d;
exports.rotateX = rotateX;
exports.rotateY = rotateY;
exports.rotateZ = rotateZ;
exports.skew = skew;
exports.skewX = skewX;
exports.skewY = skewY;
exports.linearGradient = linearGradient;
exports.repeatingLinearGradient = repeatingLinearGradient;
exports.radialGradient = radialGradient;
exports.repeatingRadialGradient = repeatingRadialGradient;
exports.areas = areas;
exports.ident = ident;
exports.numIdent = numIdent;
exports.contextMenu = contextMenu;
exports.help = help;
exports.pointer = pointer;
exports.progress = progress;
exports.wait = wait;
exports.cell = cell;
exports.crosshair = crosshair;
exports.verticalText = verticalText;
exports.alias = alias;
exports.copy = copy;
exports.move = move;
exports.noDrop = noDrop;
exports.notAllowed = notAllowed;
exports.grab = grab;
exports.grabbing = grabbing;
exports.allScroll = allScroll;
exports.colResize = colResize;
exports.rowResize = rowResize;
exports.nResize = nResize;
exports.eResize = eResize;
exports.sResize = sResize;
exports.wResize = wResize;
exports.neResize = neResize;
exports.nwResize = nwResize;
exports.seResize = seResize;
exports.swResize = swResize;
exports.ewResize = ewResize;
exports.nsResize = nsResize;
exports.neswResize = neswResize;
exports.nwseResize = nwseResize;
exports.zoomIn = zoomIn;
exports.zoomOut = zoomOut;
exports.flex3 = flex3;
exports.border = border;
exports.borderTop = borderTop;
exports.backgroundSize = backgroundSize;
exports.textDecoration = textDecoration;
exports.background = background;
exports.backgrounds = backgrounds;
exports.gridAutoColumns = gridAutoColumns;
exports.gridAutoRows = gridAutoRows;
exports.gridTemplateColumns = gridTemplateColumns;
exports.gridTemplateRows = gridTemplateRows;
exports.Calc = Calc;
exports.size = size;
exports.filter = filter;
exports.fontFace = fontFace;
exports.Transition = Transition;
exports.transitionValue = transitionValue;
exports.transitionList = transitionList;
exports.transition = transition;
exports.transitions = transitions;
exports.Animation = Animation;
exports.animationValue = animationValue;
exports.animation = animation;
exports.animations = animations;
exports.animationName = animationName;
exports.SVG = SVG;
exports.aliceblue = aliceblue;
exports.antiquewhite = antiquewhite;
exports.aqua = aqua;
exports.aquamarine = aquamarine;
exports.azure = azure;
exports.beige = beige;
exports.bisque = bisque;
exports.black = black;
exports.blanchedalmond = blanchedalmond;
exports.blue = blue;
exports.blueviolet = blueviolet;
exports.brown = brown;
exports.burlywood = burlywood;
exports.cadetblue = cadetblue;
exports.chartreuse = chartreuse;
exports.chocolate = chocolate;
exports.coral = coral;
exports.cornflowerblue = cornflowerblue;
exports.cornsilk = cornsilk;
exports.crimson = crimson;
exports.cyan = cyan;
exports.darkblue = darkblue;
exports.darkcyan = darkcyan;
exports.darkgoldenrod = darkgoldenrod;
exports.darkgray = darkgray;
exports.darkgrey = darkgrey;
exports.darkgreen = darkgreen;
exports.darkkhaki = darkkhaki;
exports.darkmagenta = darkmagenta;
exports.darkolivegreen = darkolivegreen;
exports.darkorange = darkorange;
exports.darkorchid = darkorchid;
exports.darkred = darkred;
exports.darksalmon = darksalmon;
exports.darkseagreen = darkseagreen;
exports.darkslateblue = darkslateblue;
exports.darkslategray = darkslategray;
exports.darkslategrey = darkslategrey;
exports.darkturquoise = darkturquoise;
exports.darkviolet = darkviolet;
exports.deeppink = deeppink;
exports.deepskyblue = deepskyblue;
exports.dimgray = dimgray;
exports.dimgrey = dimgrey;
exports.dodgerblue = dodgerblue;
exports.firebrick = firebrick;
exports.floralwhite = floralwhite;
exports.forestgreen = forestgreen;
exports.fuchsia = fuchsia;
exports.gainsboro = gainsboro;
exports.ghostwhite = ghostwhite;
exports.gold = gold;
exports.goldenrod = goldenrod;
exports.gray = gray;
exports.grey = grey;
exports.green = green;
exports.greenyellow = greenyellow;
exports.honeydew = honeydew;
exports.hotpink = hotpink;
exports.indianred = indianred;
exports.indigo = indigo;
exports.ivory = ivory;
exports.khaki = khaki;
exports.lavender = lavender;
exports.lavenderblush = lavenderblush;
exports.lawngreen = lawngreen;
exports.lemonchiffon = lemonchiffon;
exports.lightblue = lightblue;
exports.lightcoral = lightcoral;
exports.lightcyan = lightcyan;
exports.lightgoldenrodyellow = lightgoldenrodyellow;
exports.lightgray = lightgray;
exports.lightgrey = lightgrey;
exports.lightgreen = lightgreen;
exports.lightpink = lightpink;
exports.lightsalmon = lightsalmon;
exports.lightseagreen = lightseagreen;
exports.lightskyblue = lightskyblue;
exports.lightslategray = lightslategray;
exports.lightslategrey = lightslategrey;
exports.lightsteelblue = lightsteelblue;
exports.lightyellow = lightyellow;
exports.lime = lime;
exports.limegreen = limegreen;
exports.linen = linen;
exports.magenta = magenta;
exports.maroon = maroon;
exports.mediumaquamarine = mediumaquamarine;
exports.mediumblue = mediumblue;
exports.mediumorchid = mediumorchid;
exports.mediumpurple = mediumpurple;
exports.mediumseagreen = mediumseagreen;
exports.mediumslateblue = mediumslateblue;
exports.mediumspringgreen = mediumspringgreen;
exports.mediumturquoise = mediumturquoise;
exports.mediumvioletred = mediumvioletred;
exports.midnightblue = midnightblue;
exports.mintcream = mintcream;
exports.mistyrose = mistyrose;
exports.moccasin = moccasin;
exports.navajowhite = navajowhite;
exports.navy = navy;
exports.oldlace = oldlace;
exports.olive = olive;
exports.olivedrab = olivedrab;
exports.orange = orange;
exports.orangered = orangered;
exports.orchid = orchid;
exports.palegoldenrod = palegoldenrod;
exports.palegreen = palegreen;
exports.paleturquoise = paleturquoise;
exports.palevioletred = palevioletred;
exports.papayawhip = papayawhip;
exports.peachpuff = peachpuff;
exports.peru = peru;
exports.pink = pink;
exports.plum = plum;
exports.powderblue = powderblue;
exports.purple = purple;
exports.rebeccapurple = rebeccapurple;
exports.red = red;
exports.rosybrown = rosybrown;
exports.royalblue = royalblue;
exports.saddlebrown = saddlebrown;
exports.salmon = salmon;
exports.sandybrown = sandybrown;
exports.seagreen = seagreen;
exports.seashell = seashell;
exports.sienna = sienna;
exports.silver = silver;
exports.skyblue = skyblue;
exports.slateblue = slateblue;
exports.slategray = slategray;
exports.slategrey = slategrey;
exports.snow = snow;
exports.springgreen = springgreen;
exports.steelblue = steelblue;
exports.tan = tan;
exports.teal = teal;
exports.thistle = thistle;
exports.tomato = tomato;
exports.turquoise = turquoise;
exports.violet = violet;
exports.wheat = wheat;
exports.white = white;
exports.whitesmoke = whitesmoke;
exports.yellow = yellow;
exports.yellowgreen = yellowgreen;
/* Css_Colors Not a pure module */
