// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Theme$WordpressSeamonsterStarter = require("../Lib/Theme.bs.js");

var progressBar = Curry._1(Css.style, /* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.width(Css.pct(100.0)),
        /* :: */[
          Css.top(Css.px(-5)),
          /* :: */[
            Css.display(/* flex */-1010954439),
            /* :: */[
              Css.height(Css.px(6)),
              /* :: */[
                Css.backgroundColor(/* `hex */[
                      5194459,
                      Theme$WordpressSeamonsterStarter.lightBackground
                    ]),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var progressBlock = Curry._1(Css.style, /* :: */[
      Css.flexGrow(1.0),
      /* [] */0
    ]);

var lit = Curry._1(Css.style, /* :: */[
      Css.backgroundColor(/* `hex */[
            5194459,
            Theme$WordpressSeamonsterStarter.brandBlue
          ]),
      /* [] */0
    ]);

var Styles = {
  progressBar: progressBar,
  progressBlock: progressBlock,
  lit: lit
};

function Quiz__ProgressBar(Props) {
  var progress = Props.progress;
  var max = Props.max;
  return React.createElement("div", {
              className: progressBar
            }, Belt_Array.map(Belt_Array.range(1, max), (function (n) {
                    return React.createElement("div", {
                                key: String(n),
                                className: n <= progress ? Curry._1(Css.merge, /* :: */[
                                        progressBlock,
                                        /* :: */[
                                          lit,
                                          /* [] */0
                                        ]
                                      ]) : progressBlock
                              });
                  })));
}

var make = Quiz__ProgressBar;

exports.Styles = Styles;
exports.make = make;
/* progressBar Not a pure module */
