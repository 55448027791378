const $ = jQuery;

$(document).ready(() => {
    const $loadMore = $("[data-publications--load-more]")
    const $publications = $("[data-publications--publication]")
    const incrementer = $loadMore.attr("data-publications--load-more");
    let count = incrementer

    if ($loadMore.length && $publications.length) {

        function loadMore() {
            $publications.map(i => {
                const $publication = $($publications[i])

                if ($publication.length && i < count) {
                    $publication.show();
                } else {
                    $publication.hide();

                }
            })

            if (count >= $publications.length) {
                $loadMore.hide()
            }
        }

        loadMore()

        $loadMore.on("click", () => {
            count += incrementer;
            loadMore()
        })
    }
})