// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function captureDeccoError(error, decoderName) {
  var path = error.path;
  var message = error.message;
  var value = JSON.stringify(error.value);
  var report = "\n    DECODE ERROR WITH " + (String(decoderName) + ("\n    Path: " + (String(path) + ("\n\n    Message: " + (String(message) + ("\n\n    Value: " + (String(value) + "\n  ")))))));
  console.log(report);
  return /* () */0;
}

exports.captureDeccoError = captureDeccoError;
/* No side effect */
