// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Utils$Sms = require("@seamonster-studios/reason/src/utils/Utils.bs.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var ReactUseMediaQueryHook = require("react-use-media-query-hook");
var Theme$WordpressSeamonsterStarter = require("../../Lib/Theme.bs.js");

var root = Curry._1(Css.style, /* :: */[
      Css.selector("h3", /* :: */[
            Css.color(Css.hex(Theme$WordpressSeamonsterStarter.brandBlue)),
            /* :: */[
              Css.marginBottom(Css.px(10)),
              /* [] */0
            ]
          ]),
      /* :: */[
        Css.selector("h4", /* :: */[
              Css.color(Css.hex("000")),
              /* :: */[
                Css.marginBottom(Css.px(10)),
                /* :: */[
                  Css.textTransform(/* none */-922086728),
                  /* [] */0
                ]
              ]
            ]),
        /* :: */[
          Css.paddingLeft(Css.px(20)),
          /* :: */[
            Css.paddingRight(Css.px(20)),
            /* :: */[
              Theme$WordpressSeamonsterStarter.MediaQuery.sml(/* :: */[
                    Css.paddingLeft(Css.px(0)),
                    /* :: */[
                      Css.paddingRight(Css.px(0)),
                      /* :: */[
                        Css.selector("h4", /* :: */[
                              Css.fontSize(Css.px(21)),
                              /* [] */0
                            ]),
                        /* [] */0
                      ]
                    ]
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var provider = Curry._1(Css.style, /* :: */[
      Theme$WordpressSeamonsterStarter.Borders.sectionTop,
      /* :: */[
        Css.paddingTop(Css.px(25)),
        /* :: */[
          Css.paddingBottom(Css.px(25)),
          /* :: */[
            Theme$WordpressSeamonsterStarter.MediaQuery.sml(/* :: */[
                  Css.display(/* flex */-1010954439),
                  /* :: */[
                    Css.justifyContent(/* spaceBetween */516682146),
                    /* :: */[
                      Css.selector("> div", /* :: */[
                            Css.selector("&:nth-of-type(odd)", /* :: */[
                                  Css.marginRight(Css.px(50)),
                                  /* [] */0
                                ]),
                            /* :: */[
                              Css.width(/* `calc */[
                                    -1044768619,
                                    /* tuple */[
                                      /* sub */5745024,
                                      Css.pct(50),
                                      Css.px(25)
                                    ]
                                  ]),
                              /* [] */0
                            ]
                          ]),
                      /* [] */0
                    ]
                  ]
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var contact = Curry._1(Css.style, /* :: */[
      Css.selector("a", /* :: */[
            Css.textDecoration(/* underline */131142924),
            /* [] */0
          ]),
      /* :: */[
        Css.selector("p", /* :: */[
              Css.marginBottom(Css.px(2)),
              /* [] */0
            ]),
        /* :: */[
          Css.marginBottom(Css.px(20)),
          /* [] */0
        ]
      ]
    ]);

var contact_methods = Curry._1(Css.style, /* :: */[
      Css.selector("li", /* :: */[
            Css.fontSize(Css.px(15)),
            /* :: */[
              Css.display(/* flex */-1010954439),
              /* :: */[
                Css.flexDirection(/* column */-963948842),
                /* :: */[
                  Css.marginBottom(Css.px(15)),
                  /* :: */[
                    Css.selector("a", /* :: */[
                          Css.marginTop(Css.px(2)),
                          /* [] */0
                        ]),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]),
      /* :: */[
        Theme$WordpressSeamonsterStarter.MediaQuery.sml(/* :: */[
              Css.selector("li", /* :: */[
                    Css.fontSize(Css.px(18)),
                    /* :: */[
                      Css.flexDirection(/* row */5693978),
                      /* :: */[
                        Css.marginBottom(Css.px(5)),
                        /* :: */[
                          Css.justifyContent(/* flexStart */662439529),
                          /* :: */[
                            Css.selector("a", /* :: */[
                                  Css.marginTop(Css.px(0)),
                                  /* [] */0
                                ]),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var label = Curry._1(Css.style, /* :: */[
      Css.fontWeight(/* bold */-1055161979),
      /* :: */[
        Css.marginRight(Css.px(15)),
        /* :: */[
          Css.color(Css.hex("000")),
          /* [] */0
        ]
      ]
    ]);

var Style = {
  root: root,
  provider: provider,
  contact: contact,
  contact_methods: contact_methods,
  label: label
};

function ProvidersList(Props) {
  var providers = Props.providers;
  var isDesktop = ReactUseMediaQueryHook("(min-width: " + (String(Theme$WordpressSeamonsterStarter.Breakpoint.md + 1 | 0) + "px)"));
  var match = React.useState((function () {
          if (isDesktop) {
            return 5;
          } else {
            return 2;
          }
        }));
  var setShowCount = match[1];
  var showCount = match[0];
  React.useEffect((function (param) {
          if (isDesktop) {
            Curry._1(setShowCount, (function (param) {
                    return 5;
                  }));
          } else {
            Curry._1(setShowCount, (function (param) {
                    return 2;
                  }));
          }
          return ;
        }), /* tuple */[
        isDesktop,
        setShowCount,
        providers
      ]);
  return React.createElement("div", {
              className: root
            }, Belt_Array.mapWithIndex(providers, (function (index, p) {
                    if (index < showCount) {
                      return React.createElement("div", {
                                  key: String(index),
                                  className: provider
                                }, React.createElement("div", undefined, Utils$Sms.DomU.createMarkup(p.info)), React.createElement("div", {
                                      className: contact
                                    }, Belt_Option.getWithDefault(Belt_Option.map(p.address_line_1, (function (i) {
                                                if (i !== "") {
                                                  return React.createElement("p", undefined, i);
                                                } else {
                                                  return null;
                                                }
                                              })), null), Belt_Option.getWithDefault(Belt_Option.map(p.address_line_2, (function (i) {
                                                if (i !== "") {
                                                  return React.createElement("p", undefined, i);
                                                } else {
                                                  return null;
                                                }
                                              })), null), Belt_Option.getWithDefault(Belt_Option.map(p.google_maps_address, (function (i) {
                                                if (i !== "") {
                                                  return React.createElement("p", undefined, React.createElement("a", {
                                                                  href: i,
                                                                  rel: "noopener noreferrer",
                                                                  target: "_blank"
                                                                }, "See on Google Maps"));
                                                } else {
                                                  return null;
                                                }
                                              })), null), React.createElement("div", {
                                          className: contact_methods
                                        }, React.createElement("ul", undefined, Belt_Array.map(p.contact_methods, (function (cm) {
                                                    return React.createElement("li", {
                                                                key: cm.title
                                                              }, React.createElement("span", {
                                                                    className: label
                                                                  }, cm.title + ":"), React.createElement("a", {
                                                                    href: cm.link,
                                                                    rel: "noopener noreferrer",
                                                                    target: "_blank"
                                                                  }, cm.label));
                                                  }))))));
                    } else {
                      return React.createElement(React.Fragment, {
                                  children: null,
                                  key: String(index)
                                });
                    }
                  })), showCount < providers.length ? React.createElement("div", undefined, React.createElement("button", {
                        className: "load-more",
                        onClick: (function (param) {
                            return Curry._1(setShowCount, (function (count) {
                                          return count + (
                                                  isDesktop ? 5 : 2
                                                ) | 0;
                                        }));
                          })
                      }, "See More", React.createElement("span", {
                            className: "arrow-down"
                          }))) : null);
}

var mobileCountInc = 2;

var desktopCountInc = 5;

var make = ProvidersList;

exports.Style = Style;
exports.mobileCountInc = mobileCountInc;
exports.desktopCountInc = desktopCountInc;
exports.make = make;
/* root Not a pure module */
