// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Utils$Sms = require("@seamonster-studios/reason/src/utils/Utils.bs.js");
var Theme$WordpressSeamonsterStarter = require("../../Lib/Theme.bs.js");

var root = Curry._1(Css.style, /* :: */[
      Css.marginBottom(Css.px(20)),
      /* :: */[
        Css.paddingLeft(Css.px(20)),
        /* :: */[
          Css.paddingRight(Css.px(20)),
          /* :: */[
            Css.selector("ul", /* :: */[
                  Css.marginTop(Css.px(21)),
                  /* :: */[
                    Theme$WordpressSeamonsterStarter.Borders.sectionTop,
                    /* [] */0
                  ]
                ]),
            /* :: */[
              Css.selector("li", /* :: */[
                    Css.lineHeight(Css.em(2.2)),
                    /* :: */[
                      Css.fontSize(Css.px(15)),
                      /* [] */0
                    ]
                  ]),
              /* :: */[
                Theme$WordpressSeamonsterStarter.MediaQuery.sml(/* :: */[
                      Css.paddingLeft(Css.px(0)),
                      /* :: */[
                        Css.paddingRight(Css.px(0)),
                        /* :: */[
                          Css.selector("ul", /* :: */[
                                Css.unsafe("webkitColumnCount", "3"),
                                /* :: */[
                                  Css.unsafe("mozColumnCount", "3"),
                                  /* :: */[
                                    Css.columnCount(/* `count */[
                                          -899463985,
                                          3
                                        ]),
                                    /* [] */0
                                  ]
                                ]
                              ]),
                          /* [] */0
                        ]
                      ]
                    ]),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var Style = {
  root: root
};

function MapCountyAreas(Props) {
  var areas = Props.areas;
  return React.createElement("div", {
              className: root
            }, Utils$Sms.DomU.createMarkup(areas));
}

var make = MapCountyAreas;

exports.Style = Style;
exports.make = make;
/* root Not a pure module */
