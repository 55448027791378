// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Belt_Result = require("bs-platform/lib/js/belt_Result.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function falseableEncode(encoder, opt) {
  if (opt !== undefined) {
    return Curry._1(encoder, Caml_option.valFromOption(opt));
  } else {
    return false;
  }
}

function falseableDecode(decoder, json) {
  var match = Js_json.decodeBoolean(json);
  if (match !== undefined && !match) {
    return /* Ok */Block.__(0, [undefined]);
  }
  var __x = Curry._1(decoder, json);
  return Belt_Result.map(__x, (function (v) {
                return Caml_option.some(v);
              }));
}

var falseable = /* tuple */[
  falseableEncode,
  falseableDecode
];

function magicDecode(j) {
  return /* Ok */Block.__(0, [j]);
}

function magic_000(prim) {
  return prim;
}

var magic = /* tuple */[
  magic_000,
  magicDecode
];

exports.falseableEncode = falseableEncode;
exports.falseableDecode = falseableDecode;
exports.falseable = falseable;
exports.magicDecode = magicDecode;
exports.magic = magic;
/* No side effect */
