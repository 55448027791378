// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Theme$WordpressSeamonsterStarter = require("../Lib/Theme.bs.js");

var progressText = Curry._1(Css.style, /* :: */[
      Css.fontWeight(/* semiBold */-812709613),
      /* :: */[
        Css.lineHeight(Css.px(18)),
        /* :: */[
          Css.color(/* `hex */[
                5194459,
                Theme$WordpressSeamonsterStarter.brandBlue
              ]),
          /* :: */[
            Css.marginBottom(Css.px(16)),
            /* :: */[
              Css.textTransform(/* uppercase */-415330030),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var numbers = Curry._1(Css.style, /* :: */[
      Css.unsafe("fontVariantNumeric", "tabular-nums"),
      /* [] */0
    ]);

var Styles = {
  progressText: progressText,
  numbers: numbers
};

function Quiz__ProgressText(Props) {
  var progress = Props.progress;
  var max = Props.max;
  var progress$1 = String(progress);
  var max$1 = String(max);
  return React.createElement("div", {
              className: progressText
            }, "Question ", React.createElement("span", {
                  className: numbers
                }, "" + (String(progress$1) + ("/" + (String(max$1) + "")))));
}

var make = Quiz__ProgressText;

exports.Styles = Styles;
exports.make = make;
/* progressText Not a pure module */
