// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_math = require("bs-platform/lib/js/js_math.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int64 = require("bs-platform/lib/js/caml_int64.js");
var Belt_Result = require("bs-platform/lib/js/belt_Result.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Decco_Codecs = require("./Decco_Codecs.js");

function error(path, message, value) {
  var path$1 = path !== undefined ? path : "";
  return /* Error */Block.__(1, [{
              path: path$1,
              message: message,
              value: value
            }]);
}

function stringToJson(s) {
  return s;
}

function stringFromJson(j) {
  var match = Js_json.decodeString(j);
  if (match !== undefined) {
    return /* Ok */Block.__(0, [match]);
  } else {
    return /* Error */Block.__(1, [{
                path: "",
                message: "Not a string",
                value: j
              }]);
  }
}

function intToJson(i) {
  return i;
}

function intFromJson(j) {
  var match = Js_json.decodeNumber(j);
  if (match !== undefined) {
    var f = match;
    if (Js_math.floor(f) === f) {
      return /* Ok */Block.__(0, [Js_math.floor(f)]);
    } else {
      return /* Error */Block.__(1, [{
                  path: "",
                  message: "Not an integer",
                  value: j
                }]);
    }
  } else {
    return /* Error */Block.__(1, [{
                path: "",
                message: "Not a number",
                value: j
              }]);
  }
}

var int64ToJson = Caml_int64.float_of_bits;

function int64FromJson(j) {
  var match = Js_json.decodeNumber(j);
  if (match !== undefined) {
    return /* Ok */Block.__(0, [Caml_int64.bits_of_float(match)]);
  } else {
    return error(undefined, "Not a number", j);
  }
}

var int64ToJsonUnsafe = Caml_int64.to_float;

function int64FromJsonUnsafe(j) {
  var match = Js_json.decodeNumber(j);
  if (match !== undefined) {
    return /* Ok */Block.__(0, [Caml_int64.of_float(match)]);
  } else {
    return error(undefined, "Not a number", j);
  }
}

function floatToJson(v) {
  return v;
}

function floatFromJson(j) {
  var match = Js_json.decodeNumber(j);
  if (match !== undefined) {
    return /* Ok */Block.__(0, [match]);
  } else {
    return /* Error */Block.__(1, [{
                path: "",
                message: "Not a number",
                value: j
              }]);
  }
}

function boolToJson(v) {
  return v;
}

function boolFromJson(j) {
  var match = Js_json.decodeBoolean(j);
  if (match !== undefined) {
    return /* Ok */Block.__(0, [match]);
  } else {
    return /* Error */Block.__(1, [{
                path: "",
                message: "Not a boolean",
                value: j
              }]);
  }
}

function unitToJson(param) {
  return 0.0;
}

function unitFromJson(param) {
  return /* Ok */Block.__(0, [/* () */0]);
}

function arrayToJson(encoder, arr) {
  return arr.map(Curry.__1(encoder));
}

function arrayFromJson(decoder, json) {
  var match = Js_json.decodeArray(json);
  if (match !== undefined) {
    return match.reduce((function (acc, jsonI, i) {
                  var match = Curry._1(decoder, jsonI);
                  if (acc.tag) {
                    return acc;
                  } else if (match.tag) {
                    var error = match[0];
                    return /* Error */Block.__(1, [{
                                path: "[" + (String(i) + ("]" + error.path)),
                                message: error.message,
                                value: error.value
                              }]);
                  } else {
                    return /* Ok */Block.__(0, [acc[0].concat([match[0]])]);
                  }
                }), /* Ok */Block.__(0, [[]]));
  } else {
    return /* Error */Block.__(1, [{
                path: "",
                message: "Not an array",
                value: json
              }]);
  }
}

function listToJson(encoder, list) {
  var arr = $$Array.of_list(list);
  return arr.map(Curry.__1(encoder));
}

function listFromJson(decoder, json) {
  var __x = arrayFromJson(decoder, json);
  return Belt_Result.map(__x, $$Array.to_list);
}

function optionToJson(encoder, opt) {
  if (opt !== undefined) {
    return Curry._1(encoder, Caml_option.valFromOption(opt));
  } else {
    return null;
  }
}

function optionFromJson(decoder, json) {
  var match = Js_json.decodeNull(json);
  if (match !== undefined) {
    return /* Ok */Block.__(0, [undefined]);
  } else {
    var __x = Curry._1(decoder, json);
    return Belt_Result.map(__x, (function (v) {
                  return Caml_option.some(v);
                }));
  }
}

function resultToJson(okEncoder, errorEncoder, result) {
  if (result.tag) {
    return [
            "Error",
            Curry._1(errorEncoder, result[0])
          ];
  } else {
    return [
            "Ok",
            Curry._1(okEncoder, result[0])
          ];
  }
}

function resultFromJson(okDecoder, errorDecoder, json) {
  var match = Js_json.decodeArray(json);
  if (match !== undefined) {
    var match$1 = match;
    if (match$1.length !== 2) {
      return error(undefined, "Expected exactly 2 values in array", json);
    } else {
      var variantConstructorId = match$1[0];
      var payload = match$1[1];
      var match$2 = Js_json.decodeString(variantConstructorId);
      if (match$2 !== undefined) {
        switch (match$2) {
          case "Error" :
              var match$3 = Curry._1(errorDecoder, payload);
              if (match$3.tag) {
                return /* Error */Block.__(1, [match$3[0]]);
              } else {
                return /* Ok */Block.__(0, [/* Error */Block.__(1, [match$3[0]])]);
              }
          case "Ok" :
              return Belt_Result.map(Curry._1(okDecoder, payload), (function (v) {
                            return /* Ok */Block.__(0, [v]);
                          }));
          default:
            return error(undefined, "Expected either \"Ok\" or \"Error\"", variantConstructorId);
        }
      } else {
        return error(undefined, "Not a string", variantConstructorId);
      }
    }
  } else {
    return error(undefined, "Not an array", json);
  }
}

function dictToJson(encoder, dict) {
  return Js_dict.map(Curry.__1(encoder), dict);
}

function dictFromJson(decoder, json) {
  var match = Js_json.decodeObject(json);
  if (match !== undefined) {
    return Belt_Array.reduce(Js_dict.entries(Caml_option.valFromOption(match)), /* Ok */Block.__(0, [{ }]), (function (acc, param) {
                  var key = param[0];
                  var match = Curry._1(decoder, param[1]);
                  if (acc.tag) {
                    return acc;
                  } else {
                    var prev = acc[0];
                    if (match.tag) {
                      var error = match[0];
                      return /* Error */Block.__(1, [{
                                  path: "." + (key + error.path),
                                  message: error.message,
                                  value: error.value
                                }]);
                    } else {
                      prev[key] = match[0];
                      return /* Ok */Block.__(0, [prev]);
                    }
                  }
                }));
  } else {
    return /* Error */Block.__(1, [{
                path: "",
                message: "Not a dict",
                value: json
              }]);
  }
}

var string = /* tuple */[
  stringToJson,
  stringFromJson
];

var $$int = /* tuple */[
  intToJson,
  intFromJson
];

var int64Unsafe = /* tuple */[
  int64ToJsonUnsafe,
  int64FromJsonUnsafe
];

var $$float = /* tuple */[
  floatToJson,
  floatFromJson
];

var bool = /* tuple */[
  boolToJson,
  boolFromJson
];

var array = /* tuple */[
  arrayToJson,
  arrayFromJson
];

var list = /* tuple */[
  listToJson,
  listFromJson
];

var option = /* tuple */[
  optionToJson,
  optionFromJson
];

var unit = /* tuple */[
  unitToJson,
  unitFromJson
];

var Codecs = {
  falseableEncode: Decco_Codecs.falseableEncode,
  falseableDecode: Decco_Codecs.falseableDecode,
  falseable: Decco_Codecs.falseable,
  magicDecode: Decco_Codecs.magicDecode,
  magic: Decco_Codecs.magic,
  string: string,
  $$int: $$int,
  int64Unsafe: int64Unsafe,
  $$float: $$float,
  bool: bool,
  array: array,
  list: list,
  option: option,
  unit: unit
};

exports.error = error;
exports.stringToJson = stringToJson;
exports.stringFromJson = stringFromJson;
exports.intToJson = intToJson;
exports.intFromJson = intFromJson;
exports.int64ToJson = int64ToJson;
exports.int64FromJson = int64FromJson;
exports.int64ToJsonUnsafe = int64ToJsonUnsafe;
exports.int64FromJsonUnsafe = int64FromJsonUnsafe;
exports.floatToJson = floatToJson;
exports.floatFromJson = floatFromJson;
exports.boolToJson = boolToJson;
exports.boolFromJson = boolFromJson;
exports.unitToJson = unitToJson;
exports.unitFromJson = unitFromJson;
exports.arrayToJson = arrayToJson;
exports.arrayFromJson = arrayFromJson;
exports.listToJson = listToJson;
exports.listFromJson = listFromJson;
exports.optionToJson = optionToJson;
exports.optionFromJson = optionFromJson;
exports.resultToJson = resultToJson;
exports.resultFromJson = resultFromJson;
exports.dictToJson = dictToJson;
exports.dictFromJson = dictFromJson;
exports.Codecs = Codecs;
/* No side effect */
