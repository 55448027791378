// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Emotion = require("emotion");
var Css_Core = require("bs-css/src/Css_Core.js");

var include = Css_Core.Make({
      mergeStyles: Emotion.cx,
      injectRule: Emotion.injectGlobal,
      injectRaw: Emotion.injectGlobal,
      make: Emotion.css,
      makeKeyFrames: Emotion.keyframes
    });

var insertRule = include.insertRule;

function fontFace(fontFamily, src, fontStyle, fontWeight, fontDisplay, param) {
  var asString = Css_Core.fontFace(fontFamily, src, fontStyle, fontWeight, fontDisplay, /* () */0);
  Curry._1(insertRule, asString);
  return fontFamily;
}

var Types = Css_Core.Types;

var Make = Css_Core.Make;

var toJson = Css_Core.toJson;

var important = Css_Core.important;

var label = Css_Core.label;

var Shadow = Css_Core.Shadow;

var unsafe = Css_Core.unsafe;

var alignContent = Css_Core.alignContent;

var alignItems = Css_Core.alignItems;

var alignSelf = Css_Core.alignSelf;

var animationDelay = Css_Core.animationDelay;

var animationDirection = Css_Core.animationDirection;

var animationDuration = Css_Core.animationDuration;

var animationFillMode = Css_Core.animationFillMode;

var animationIterationCount = Css_Core.animationIterationCount;

var animationPlayState = Css_Core.animationPlayState;

var animationTimingFunction = Css_Core.animationTimingFunction;

var backdropFilter = Css_Core.backdropFilter;

var backfaceVisibility = Css_Core.backfaceVisibility;

var backgroundAttachment = Css_Core.backgroundAttachment;

var backgroundColor = Css_Core.backgroundColor;

var backgroundClip = Css_Core.backgroundClip;

var backgroundImage = Css_Core.backgroundImage;

var backgroundOrigin = Css_Core.backgroundOrigin;

var backgroundPosition = Css_Core.backgroundPosition;

var backgroundPositions = Css_Core.backgroundPositions;

var backgroundPosition4 = Css_Core.backgroundPosition4;

var backgroundRepeat = Css_Core.backgroundRepeat;

var borderBottom = Css_Core.borderBottom;

var borderBottomColor = Css_Core.borderBottomColor;

var borderBottomLeftRadius = Css_Core.borderBottomLeftRadius;

var borderBottomRightRadius = Css_Core.borderBottomRightRadius;

var borderBottomStyle = Css_Core.borderBottomStyle;

var borderBottomWidth = Css_Core.borderBottomWidth;

var borderCollapse = Css_Core.borderCollapse;

var borderColor = Css_Core.borderColor;

var borderLeft = Css_Core.borderLeft;

var borderLeftColor = Css_Core.borderLeftColor;

var borderLeftStyle = Css_Core.borderLeftStyle;

var borderLeftWidth = Css_Core.borderLeftWidth;

var borderRight = Css_Core.borderRight;

var borderRightColor = Css_Core.borderRightColor;

var borderRightStyle = Css_Core.borderRightStyle;

var borderRightWidth = Css_Core.borderRightWidth;

var borderRadius = Css_Core.borderRadius;

var borderSpacing = Css_Core.borderSpacing;

var borderStyle = Css_Core.borderStyle;

var borderTopColor = Css_Core.borderTopColor;

var borderTopLeftRadius = Css_Core.borderTopLeftRadius;

var borderTopRightRadius = Css_Core.borderTopRightRadius;

var borderTopStyle = Css_Core.borderTopStyle;

var borderTopWidth = Css_Core.borderTopWidth;

var borderWidth = Css_Core.borderWidth;

var bottom = Css_Core.bottom;

var boxSizing = Css_Core.boxSizing;

var boxShadow = Css_Core.boxShadow;

var boxShadows = Css_Core.boxShadows;

var clear = Css_Core.clear;

var clipPath = Css_Core.clipPath;

var color = Css_Core.color;

var columnCount = Css_Core.columnCount;

var contentRule = Css_Core.contentRule;

var contentRules = Css_Core.contentRules;

var counterIncrement = Css_Core.counterIncrement;

var countersIncrement = Css_Core.countersIncrement;

var counterReset = Css_Core.counterReset;

var countersReset = Css_Core.countersReset;

var counterSet = Css_Core.counterSet;

var countersSet = Css_Core.countersSet;

var cursor = Css_Core.cursor;

var direction = Css_Core.direction;

var display = Css_Core.display;

var flex = Css_Core.flex;

var flexBasis = Css_Core.flexBasis;

var flexDirection = Css_Core.flexDirection;

var flexGrow = Css_Core.flexGrow;

var flexShrink = Css_Core.flexShrink;

var flexWrap = Css_Core.flexWrap;

var $$float = Css_Core.$$float;

var fontFamily = Css_Core.fontFamily;

var fontFamilies = Css_Core.fontFamilies;

var fontSize = Css_Core.fontSize;

var fontStyle = Css_Core.fontStyle;

var fontVariant = Css_Core.fontVariant;

var fontWeight = Css_Core.fontWeight;

var gridArea = Css_Core.gridArea;

var gridArea2 = Css_Core.gridArea2;

var gridArea3 = Css_Core.gridArea3;

var gridArea4 = Css_Core.gridArea4;

var gridAutoFlow = Css_Core.gridAutoFlow;

var gridColumn = Css_Core.gridColumn;

var gridColumnEnd = Css_Core.gridColumnEnd;

var gridColumnGap = Css_Core.gridColumnGap;

var gridColumnStart = Css_Core.gridColumnStart;

var gridGap = Css_Core.gridGap;

var gridRow = Css_Core.gridRow;

var gridRowEnd = Css_Core.gridRowEnd;

var gridRowGap = Css_Core.gridRowGap;

var gridRowStart = Css_Core.gridRowStart;

var gridTemplateAreas = Css_Core.gridTemplateAreas;

var height = Css_Core.height;

var justifyContent = Css_Core.justifyContent;

var justifySelf = Css_Core.justifySelf;

var left = Css_Core.left;

var letterSpacing = Css_Core.letterSpacing;

var lineHeight = Css_Core.lineHeight;

var listStyle = Css_Core.listStyle;

var listStyleImage = Css_Core.listStyleImage;

var listStyleType = Css_Core.listStyleType;

var listStylePosition = Css_Core.listStylePosition;

var margin = Css_Core.margin;

var margin2 = Css_Core.margin2;

var margin3 = Css_Core.margin3;

var margin4 = Css_Core.margin4;

var marginLeft = Css_Core.marginLeft;

var marginRight = Css_Core.marginRight;

var marginTop = Css_Core.marginTop;

var marginBottom = Css_Core.marginBottom;

var maxHeight = Css_Core.maxHeight;

var maxWidth = Css_Core.maxWidth;

var minHeight = Css_Core.minHeight;

var minWidth = Css_Core.minWidth;

var objectFit = Css_Core.objectFit;

var objectPosition = Css_Core.objectPosition;

var opacity = Css_Core.opacity;

var order = Css_Core.order;

var outline = Css_Core.outline;

var outlineColor = Css_Core.outlineColor;

var outlineOffset = Css_Core.outlineOffset;

var outlineStyle = Css_Core.outlineStyle;

var outlineWidth = Css_Core.outlineWidth;

var overflow = Css_Core.overflow;

var overflowX = Css_Core.overflowX;

var overflowY = Css_Core.overflowY;

var overflowWrap = Css_Core.overflowWrap;

var padding = Css_Core.padding;

var padding2 = Css_Core.padding2;

var padding3 = Css_Core.padding3;

var padding4 = Css_Core.padding4;

var paddingLeft = Css_Core.paddingLeft;

var paddingRight = Css_Core.paddingRight;

var paddingTop = Css_Core.paddingTop;

var paddingBottom = Css_Core.paddingBottom;

var perspective = Css_Core.perspective;

var perspectiveOrigin = Css_Core.perspectiveOrigin;

var pointerEvents = Css_Core.pointerEvents;

var position = Css_Core.position;

var resize = Css_Core.resize;

var right = Css_Core.right;

var tableLayout = Css_Core.tableLayout;

var textAlign = Css_Core.textAlign;

var textDecorationColor = Css_Core.textDecorationColor;

var textDecorationLine = Css_Core.textDecorationLine;

var textDecorationStyle = Css_Core.textDecorationStyle;

var textIndent = Css_Core.textIndent;

var textOverflow = Css_Core.textOverflow;

var textShadow = Css_Core.textShadow;

var textShadows = Css_Core.textShadows;

var textTransform = Css_Core.textTransform;

var top = Css_Core.top;

var transform = Css_Core.transform;

var transforms = Css_Core.transforms;

var transformOrigin = Css_Core.transformOrigin;

var transformOrigin3d = Css_Core.transformOrigin3d;

var transitionDelay = Css_Core.transitionDelay;

var transitionDuration = Css_Core.transitionDuration;

var transitionProperty = Css_Core.transitionProperty;

var transformStyle = Css_Core.transformStyle;

var transitionTimingFunction = Css_Core.transitionTimingFunction;

var userSelect = Css_Core.userSelect;

var verticalAlign = Css_Core.verticalAlign;

var visibility = Css_Core.visibility;

var width = Css_Core.width;

var whiteSpace = Css_Core.whiteSpace;

var wordBreak = Css_Core.wordBreak;

var wordSpacing = Css_Core.wordSpacing;

var wordWrap = Css_Core.wordWrap;

var zIndex = Css_Core.zIndex;

var selector = Css_Core.selector;

var media = Css_Core.media;

var active = Css_Core.active;

var checked = Css_Core.checked;

var $$default = Css_Core.$$default;

var defined = Css_Core.defined;

var disabled = Css_Core.disabled;

var empty = Css_Core.empty;

var enabled = Css_Core.enabled;

var first = Css_Core.first;

var firstChild = Css_Core.firstChild;

var firstOfType = Css_Core.firstOfType;

var focus = Css_Core.focus;

var focusWithin = Css_Core.focusWithin;

var host = Css_Core.host;

var hover = Css_Core.hover;

var indeterminate = Css_Core.indeterminate;

var inRange = Css_Core.inRange;

var invalid = Css_Core.invalid;

var lang = Css_Core.lang;

var lastChild = Css_Core.lastChild;

var lastOfType = Css_Core.lastOfType;

var link = Css_Core.link;

var not__ = Css_Core.not__;

var Nth = Css_Core.Nth;

var nthChild = Css_Core.nthChild;

var nthLastChild = Css_Core.nthLastChild;

var nthLastOfType = Css_Core.nthLastOfType;

var nthOfType = Css_Core.nthOfType;

var onlyChild = Css_Core.onlyChild;

var onlyOfType = Css_Core.onlyOfType;

var optional = Css_Core.optional;

var outOfRange = Css_Core.outOfRange;

var readOnly = Css_Core.readOnly;

var readWrite = Css_Core.readWrite;

var required = Css_Core.required;

var root = Css_Core.root;

var scope = Css_Core.scope;

var target = Css_Core.target;

var valid = Css_Core.valid;

var visited = Css_Core.visited;

var after = Css_Core.after;

var before = Css_Core.before;

var firstLetter = Css_Core.firstLetter;

var firstLine = Css_Core.firstLine;

var placeholder = Css_Core.placeholder;

var selection = Css_Core.selection;

var child = Css_Core.child;

var children = Css_Core.children;

var directSibling = Css_Core.directSibling;

var siblings = Css_Core.siblings;

var anyLink = Css_Core.anyLink;

var initial = Css_Core.initial;

var inherit_ = Css_Core.inherit_;

var unset = Css_Core.unset;

var auto = Css_Core.auto;

var none = Css_Core.none;

var text = Css_Core.text;

var pct = Css_Core.pct;

var ch = Css_Core.ch;

var cm = Css_Core.cm;

var em = Css_Core.em;

var ex = Css_Core.ex;

var mm = Css_Core.mm;

var pt = Css_Core.pt;

var px = Css_Core.px;

var pxFloat = Css_Core.pxFloat;

var rem = Css_Core.rem;

var vh = Css_Core.vh;

var vmin = Css_Core.vmin;

var vmax = Css_Core.vmax;

var zero = Css_Core.zero;

var deg = Css_Core.deg;

var rad = Css_Core.rad;

var grad = Css_Core.grad;

var turn = Css_Core.turn;

var ltr = Css_Core.ltr;

var rtl = Css_Core.rtl;

var absolute = Css_Core.absolute;

var relative = Css_Core.relative;

var $$static = Css_Core.$$static;

var fixed = Css_Core.fixed;

var sticky = Css_Core.sticky;

var horizontal = Css_Core.horizontal;

var vertical = Css_Core.vertical;

var smallCaps = Css_Core.smallCaps;

var italic = Css_Core.italic;

var oblique = Css_Core.oblique;

var hidden = Css_Core.hidden;

var visible = Css_Core.visible;

var scroll = Css_Core.scroll;

var rgb = Css_Core.rgb;

var rgba = Css_Core.rgba;

var hsl = Css_Core.hsl;

var hsla = Css_Core.hsla;

var hex = Css_Core.hex;

var transparent = Css_Core.transparent;

var currentColor = Css_Core.currentColor;

var local = Css_Core.local;

var paddingBox = Css_Core.paddingBox;

var borderBox = Css_Core.borderBox;

var contentBox = Css_Core.contentBox;

var noRepeat = Css_Core.noRepeat;

var space = Css_Core.space;

var repeat = Css_Core.repeat;

var minmax = Css_Core.minmax;

var repeatX = Css_Core.repeatX;

var repeatY = Css_Core.repeatY;

var contain = Css_Core.contain;

var cover = Css_Core.cover;

var row = Css_Core.row;

var rowReverse = Css_Core.rowReverse;

var column = Css_Core.column;

var columnReverse = Css_Core.columnReverse;

var wrap = Css_Core.wrap;

var nowrap = Css_Core.nowrap;

var wrapReverse = Css_Core.wrapReverse;

var inline = Css_Core.inline;

var block = Css_Core.block;

var contents = Css_Core.contents;

var flexBox = Css_Core.flexBox;

var grid = Css_Core.grid;

var inlineBlock = Css_Core.inlineBlock;

var inlineFlex = Css_Core.inlineFlex;

var inlineGrid = Css_Core.inlineGrid;

var inlineTable = Css_Core.inlineTable;

var listItem = Css_Core.listItem;

var runIn = Css_Core.runIn;

var table = Css_Core.table;

var tableCaption = Css_Core.tableCaption;

var tableColumnGroup = Css_Core.tableColumnGroup;

var tableHeaderGroup = Css_Core.tableHeaderGroup;

var tableFooterGroup = Css_Core.tableFooterGroup;

var tableRowGroup = Css_Core.tableRowGroup;

var tableCell = Css_Core.tableCell;

var tableColumn = Css_Core.tableColumn;

var tableRow = Css_Core.tableRow;

var flexStart = Css_Core.flexStart;

var flexEnd = Css_Core.flexEnd;

var center = Css_Core.center;

var stretch = Css_Core.stretch;

var spaceBetween = Css_Core.spaceBetween;

var spaceAround = Css_Core.spaceAround;

var spaceEvenly = Css_Core.spaceEvenly;

var baseline = Css_Core.baseline;

var forwards = Css_Core.forwards;

var backwards = Css_Core.backwards;

var both = Css_Core.both;

var infinite = Css_Core.infinite;

var count = Css_Core.count;

var paused = Css_Core.paused;

var running = Css_Core.running;

var inside = Css_Core.inside;

var outside = Css_Core.outside;

var solid = Css_Core.solid;

var dotted = Css_Core.dotted;

var dashed = Css_Core.dashed;

var underline = Css_Core.underline;

var overline = Css_Core.overline;

var lineThrough = Css_Core.lineThrough;

var clip = Css_Core.clip;

var ellipsis = Css_Core.ellipsis;

var wavy = Css_Core.wavy;

var $$double = Css_Core.$$double;

var uppercase = Css_Core.uppercase;

var lowercase = Css_Core.lowercase;

var capitalize = Css_Core.capitalize;

var sub = Css_Core.sub;

var $$super = Css_Core.$$super;

var textTop = Css_Core.textTop;

var textBottom = Css_Core.textBottom;

var middle = Css_Core.middle;

var normal = Css_Core.normal;

var breakAll = Css_Core.breakAll;

var keepAll = Css_Core.keepAll;

var breakWord = Css_Core.breakWord;

var reverse = Css_Core.reverse;

var alternate = Css_Core.alternate;

var alternateReverse = Css_Core.alternateReverse;

var fill = Css_Core.fill;

var content = Css_Core.content;

var maxContent = Css_Core.maxContent;

var minContent = Css_Core.minContent;

var fitContent = Css_Core.fitContent;

var all = Css_Core.all;

var round = Css_Core.round;

var miter = Css_Core.miter;

var bevel = Css_Core.bevel;

var butt = Css_Core.butt;

var square = Css_Core.square;

var thin = Css_Core.thin;

var extraLight = Css_Core.extraLight;

var light = Css_Core.light;

var medium = Css_Core.medium;

var semiBold = Css_Core.semiBold;

var bold = Css_Core.bold;

var extraBold = Css_Core.extraBold;

var lighter = Css_Core.lighter;

var bolder = Css_Core.bolder;

var fr = Css_Core.fr;

var vw = Css_Core.vw;

var localUrl = Css_Core.localUrl;

var url = Css_Core.url;

var linear = Css_Core.linear;

var ease = Css_Core.ease;

var easeIn = Css_Core.easeIn;

var easeOut = Css_Core.easeOut;

var easeInOut = Css_Core.easeInOut;

var stepStart = Css_Core.stepStart;

var stepEnd = Css_Core.stepEnd;

var steps = Css_Core.steps;

var cubicBezier = Css_Core.cubicBezier;

var marginBox = Css_Core.marginBox;

var fillBox = Css_Core.fillBox;

var strokeBox = Css_Core.strokeBox;

var viewBox = Css_Core.viewBox;

var translate = Css_Core.translate;

var translate3d = Css_Core.translate3d;

var translateX = Css_Core.translateX;

var translateY = Css_Core.translateY;

var translateZ = Css_Core.translateZ;

var scale = Css_Core.scale;

var scale3d = Css_Core.scale3d;

var scaleX = Css_Core.scaleX;

var scaleY = Css_Core.scaleY;

var scaleZ = Css_Core.scaleZ;

var rotate = Css_Core.rotate;

var rotate3d = Css_Core.rotate3d;

var rotateX = Css_Core.rotateX;

var rotateY = Css_Core.rotateY;

var rotateZ = Css_Core.rotateZ;

var skew = Css_Core.skew;

var skewX = Css_Core.skewX;

var skewY = Css_Core.skewY;

var linearGradient = Css_Core.linearGradient;

var repeatingLinearGradient = Css_Core.repeatingLinearGradient;

var radialGradient = Css_Core.radialGradient;

var repeatingRadialGradient = Css_Core.repeatingRadialGradient;

var areas = Css_Core.areas;

var ident = Css_Core.ident;

var numIdent = Css_Core.numIdent;

var contextMenu = Css_Core.contextMenu;

var help = Css_Core.help;

var pointer = Css_Core.pointer;

var progress = Css_Core.progress;

var wait = Css_Core.wait;

var cell = Css_Core.cell;

var crosshair = Css_Core.crosshair;

var verticalText = Css_Core.verticalText;

var alias = Css_Core.alias;

var copy = Css_Core.copy;

var move = Css_Core.move;

var noDrop = Css_Core.noDrop;

var notAllowed = Css_Core.notAllowed;

var grab = Css_Core.grab;

var grabbing = Css_Core.grabbing;

var allScroll = Css_Core.allScroll;

var colResize = Css_Core.colResize;

var rowResize = Css_Core.rowResize;

var nResize = Css_Core.nResize;

var eResize = Css_Core.eResize;

var sResize = Css_Core.sResize;

var wResize = Css_Core.wResize;

var neResize = Css_Core.neResize;

var nwResize = Css_Core.nwResize;

var seResize = Css_Core.seResize;

var swResize = Css_Core.swResize;

var ewResize = Css_Core.ewResize;

var nsResize = Css_Core.nsResize;

var neswResize = Css_Core.neswResize;

var nwseResize = Css_Core.nwseResize;

var zoomIn = Css_Core.zoomIn;

var zoomOut = Css_Core.zoomOut;

var flex3 = Css_Core.flex3;

var border = Css_Core.border;

var borderTop = Css_Core.borderTop;

var backgroundSize = Css_Core.backgroundSize;

var textDecoration = Css_Core.textDecoration;

var background = Css_Core.background;

var backgrounds = Css_Core.backgrounds;

var gridAutoColumns = Css_Core.gridAutoColumns;

var gridAutoRows = Css_Core.gridAutoRows;

var gridTemplateColumns = Css_Core.gridTemplateColumns;

var gridTemplateRows = Css_Core.gridTemplateRows;

var Calc = Css_Core.Calc;

var size = Css_Core.size;

var filter = Css_Core.filter;

var Transition = Css_Core.Transition;

var transitionValue = Css_Core.transitionValue;

var transitionList = Css_Core.transitionList;

var transition = Css_Core.transition;

var transitions = Css_Core.transitions;

var Animation = Css_Core.Animation;

var animationValue = Css_Core.animationValue;

var animation = Css_Core.animation;

var animations = Css_Core.animations;

var animationName = Css_Core.animationName;

var SVG = Css_Core.SVG;

var aliceblue = Css_Core.aliceblue;

var antiquewhite = Css_Core.antiquewhite;

var aqua = Css_Core.aqua;

var aquamarine = Css_Core.aquamarine;

var azure = Css_Core.azure;

var beige = Css_Core.beige;

var bisque = Css_Core.bisque;

var black = Css_Core.black;

var blanchedalmond = Css_Core.blanchedalmond;

var blue = Css_Core.blue;

var blueviolet = Css_Core.blueviolet;

var brown = Css_Core.brown;

var burlywood = Css_Core.burlywood;

var cadetblue = Css_Core.cadetblue;

var chartreuse = Css_Core.chartreuse;

var chocolate = Css_Core.chocolate;

var coral = Css_Core.coral;

var cornflowerblue = Css_Core.cornflowerblue;

var cornsilk = Css_Core.cornsilk;

var crimson = Css_Core.crimson;

var cyan = Css_Core.cyan;

var darkblue = Css_Core.darkblue;

var darkcyan = Css_Core.darkcyan;

var darkgoldenrod = Css_Core.darkgoldenrod;

var darkgray = Css_Core.darkgray;

var darkgrey = Css_Core.darkgrey;

var darkgreen = Css_Core.darkgreen;

var darkkhaki = Css_Core.darkkhaki;

var darkmagenta = Css_Core.darkmagenta;

var darkolivegreen = Css_Core.darkolivegreen;

var darkorange = Css_Core.darkorange;

var darkorchid = Css_Core.darkorchid;

var darkred = Css_Core.darkred;

var darksalmon = Css_Core.darksalmon;

var darkseagreen = Css_Core.darkseagreen;

var darkslateblue = Css_Core.darkslateblue;

var darkslategray = Css_Core.darkslategray;

var darkslategrey = Css_Core.darkslategrey;

var darkturquoise = Css_Core.darkturquoise;

var darkviolet = Css_Core.darkviolet;

var deeppink = Css_Core.deeppink;

var deepskyblue = Css_Core.deepskyblue;

var dimgray = Css_Core.dimgray;

var dimgrey = Css_Core.dimgrey;

var dodgerblue = Css_Core.dodgerblue;

var firebrick = Css_Core.firebrick;

var floralwhite = Css_Core.floralwhite;

var forestgreen = Css_Core.forestgreen;

var fuchsia = Css_Core.fuchsia;

var gainsboro = Css_Core.gainsboro;

var ghostwhite = Css_Core.ghostwhite;

var gold = Css_Core.gold;

var goldenrod = Css_Core.goldenrod;

var gray = Css_Core.gray;

var grey = Css_Core.grey;

var green = Css_Core.green;

var greenyellow = Css_Core.greenyellow;

var honeydew = Css_Core.honeydew;

var hotpink = Css_Core.hotpink;

var indianred = Css_Core.indianred;

var indigo = Css_Core.indigo;

var ivory = Css_Core.ivory;

var khaki = Css_Core.khaki;

var lavender = Css_Core.lavender;

var lavenderblush = Css_Core.lavenderblush;

var lawngreen = Css_Core.lawngreen;

var lemonchiffon = Css_Core.lemonchiffon;

var lightblue = Css_Core.lightblue;

var lightcoral = Css_Core.lightcoral;

var lightcyan = Css_Core.lightcyan;

var lightgoldenrodyellow = Css_Core.lightgoldenrodyellow;

var lightgray = Css_Core.lightgray;

var lightgrey = Css_Core.lightgrey;

var lightgreen = Css_Core.lightgreen;

var lightpink = Css_Core.lightpink;

var lightsalmon = Css_Core.lightsalmon;

var lightseagreen = Css_Core.lightseagreen;

var lightskyblue = Css_Core.lightskyblue;

var lightslategray = Css_Core.lightslategray;

var lightslategrey = Css_Core.lightslategrey;

var lightsteelblue = Css_Core.lightsteelblue;

var lightyellow = Css_Core.lightyellow;

var lime = Css_Core.lime;

var limegreen = Css_Core.limegreen;

var linen = Css_Core.linen;

var magenta = Css_Core.magenta;

var maroon = Css_Core.maroon;

var mediumaquamarine = Css_Core.mediumaquamarine;

var mediumblue = Css_Core.mediumblue;

var mediumorchid = Css_Core.mediumorchid;

var mediumpurple = Css_Core.mediumpurple;

var mediumseagreen = Css_Core.mediumseagreen;

var mediumslateblue = Css_Core.mediumslateblue;

var mediumspringgreen = Css_Core.mediumspringgreen;

var mediumturquoise = Css_Core.mediumturquoise;

var mediumvioletred = Css_Core.mediumvioletred;

var midnightblue = Css_Core.midnightblue;

var mintcream = Css_Core.mintcream;

var mistyrose = Css_Core.mistyrose;

var moccasin = Css_Core.moccasin;

var navajowhite = Css_Core.navajowhite;

var navy = Css_Core.navy;

var oldlace = Css_Core.oldlace;

var olive = Css_Core.olive;

var olivedrab = Css_Core.olivedrab;

var orange = Css_Core.orange;

var orangered = Css_Core.orangered;

var orchid = Css_Core.orchid;

var palegoldenrod = Css_Core.palegoldenrod;

var palegreen = Css_Core.palegreen;

var paleturquoise = Css_Core.paleturquoise;

var palevioletred = Css_Core.palevioletred;

var papayawhip = Css_Core.papayawhip;

var peachpuff = Css_Core.peachpuff;

var peru = Css_Core.peru;

var pink = Css_Core.pink;

var plum = Css_Core.plum;

var powderblue = Css_Core.powderblue;

var purple = Css_Core.purple;

var rebeccapurple = Css_Core.rebeccapurple;

var red = Css_Core.red;

var rosybrown = Css_Core.rosybrown;

var royalblue = Css_Core.royalblue;

var saddlebrown = Css_Core.saddlebrown;

var salmon = Css_Core.salmon;

var sandybrown = Css_Core.sandybrown;

var seagreen = Css_Core.seagreen;

var seashell = Css_Core.seashell;

var sienna = Css_Core.sienna;

var silver = Css_Core.silver;

var skyblue = Css_Core.skyblue;

var slateblue = Css_Core.slateblue;

var slategray = Css_Core.slategray;

var slategrey = Css_Core.slategrey;

var snow = Css_Core.snow;

var springgreen = Css_Core.springgreen;

var steelblue = Css_Core.steelblue;

var tan = Css_Core.tan;

var teal = Css_Core.teal;

var thistle = Css_Core.thistle;

var tomato = Css_Core.tomato;

var turquoise = Css_Core.turquoise;

var violet = Css_Core.violet;

var wheat = Css_Core.wheat;

var white = Css_Core.white;

var whitesmoke = Css_Core.whitesmoke;

var yellow = Css_Core.yellow;

var yellowgreen = Css_Core.yellowgreen;

var $$global = include.$$global;

var merge = include.merge;

var style = include.style;

var keyframes = include.keyframes;

exports.Types = Types;
exports.Make = Make;
exports.toJson = toJson;
exports.important = important;
exports.label = label;
exports.Shadow = Shadow;
exports.unsafe = unsafe;
exports.alignContent = alignContent;
exports.alignItems = alignItems;
exports.alignSelf = alignSelf;
exports.animationDelay = animationDelay;
exports.animationDirection = animationDirection;
exports.animationDuration = animationDuration;
exports.animationFillMode = animationFillMode;
exports.animationIterationCount = animationIterationCount;
exports.animationPlayState = animationPlayState;
exports.animationTimingFunction = animationTimingFunction;
exports.backdropFilter = backdropFilter;
exports.backfaceVisibility = backfaceVisibility;
exports.backgroundAttachment = backgroundAttachment;
exports.backgroundColor = backgroundColor;
exports.backgroundClip = backgroundClip;
exports.backgroundImage = backgroundImage;
exports.backgroundOrigin = backgroundOrigin;
exports.backgroundPosition = backgroundPosition;
exports.backgroundPositions = backgroundPositions;
exports.backgroundPosition4 = backgroundPosition4;
exports.backgroundRepeat = backgroundRepeat;
exports.borderBottom = borderBottom;
exports.borderBottomColor = borderBottomColor;
exports.borderBottomLeftRadius = borderBottomLeftRadius;
exports.borderBottomRightRadius = borderBottomRightRadius;
exports.borderBottomStyle = borderBottomStyle;
exports.borderBottomWidth = borderBottomWidth;
exports.borderCollapse = borderCollapse;
exports.borderColor = borderColor;
exports.borderLeft = borderLeft;
exports.borderLeftColor = borderLeftColor;
exports.borderLeftStyle = borderLeftStyle;
exports.borderLeftWidth = borderLeftWidth;
exports.borderRight = borderRight;
exports.borderRightColor = borderRightColor;
exports.borderRightStyle = borderRightStyle;
exports.borderRightWidth = borderRightWidth;
exports.borderRadius = borderRadius;
exports.borderSpacing = borderSpacing;
exports.borderStyle = borderStyle;
exports.borderTopColor = borderTopColor;
exports.borderTopLeftRadius = borderTopLeftRadius;
exports.borderTopRightRadius = borderTopRightRadius;
exports.borderTopStyle = borderTopStyle;
exports.borderTopWidth = borderTopWidth;
exports.borderWidth = borderWidth;
exports.bottom = bottom;
exports.boxSizing = boxSizing;
exports.boxShadow = boxShadow;
exports.boxShadows = boxShadows;
exports.clear = clear;
exports.clipPath = clipPath;
exports.color = color;
exports.columnCount = columnCount;
exports.contentRule = contentRule;
exports.contentRules = contentRules;
exports.counterIncrement = counterIncrement;
exports.countersIncrement = countersIncrement;
exports.counterReset = counterReset;
exports.countersReset = countersReset;
exports.counterSet = counterSet;
exports.countersSet = countersSet;
exports.cursor = cursor;
exports.direction = direction;
exports.display = display;
exports.flex = flex;
exports.flexBasis = flexBasis;
exports.flexDirection = flexDirection;
exports.flexGrow = flexGrow;
exports.flexShrink = flexShrink;
exports.flexWrap = flexWrap;
exports.$$float = $$float;
exports.fontFamily = fontFamily;
exports.fontFamilies = fontFamilies;
exports.fontSize = fontSize;
exports.fontStyle = fontStyle;
exports.fontVariant = fontVariant;
exports.fontWeight = fontWeight;
exports.gridArea = gridArea;
exports.gridArea2 = gridArea2;
exports.gridArea3 = gridArea3;
exports.gridArea4 = gridArea4;
exports.gridAutoFlow = gridAutoFlow;
exports.gridColumn = gridColumn;
exports.gridColumnEnd = gridColumnEnd;
exports.gridColumnGap = gridColumnGap;
exports.gridColumnStart = gridColumnStart;
exports.gridGap = gridGap;
exports.gridRow = gridRow;
exports.gridRowEnd = gridRowEnd;
exports.gridRowGap = gridRowGap;
exports.gridRowStart = gridRowStart;
exports.gridTemplateAreas = gridTemplateAreas;
exports.height = height;
exports.justifyContent = justifyContent;
exports.justifySelf = justifySelf;
exports.left = left;
exports.letterSpacing = letterSpacing;
exports.lineHeight = lineHeight;
exports.listStyle = listStyle;
exports.listStyleImage = listStyleImage;
exports.listStyleType = listStyleType;
exports.listStylePosition = listStylePosition;
exports.margin = margin;
exports.margin2 = margin2;
exports.margin3 = margin3;
exports.margin4 = margin4;
exports.marginLeft = marginLeft;
exports.marginRight = marginRight;
exports.marginTop = marginTop;
exports.marginBottom = marginBottom;
exports.maxHeight = maxHeight;
exports.maxWidth = maxWidth;
exports.minHeight = minHeight;
exports.minWidth = minWidth;
exports.objectFit = objectFit;
exports.objectPosition = objectPosition;
exports.opacity = opacity;
exports.order = order;
exports.outline = outline;
exports.outlineColor = outlineColor;
exports.outlineOffset = outlineOffset;
exports.outlineStyle = outlineStyle;
exports.outlineWidth = outlineWidth;
exports.overflow = overflow;
exports.overflowX = overflowX;
exports.overflowY = overflowY;
exports.overflowWrap = overflowWrap;
exports.padding = padding;
exports.padding2 = padding2;
exports.padding3 = padding3;
exports.padding4 = padding4;
exports.paddingLeft = paddingLeft;
exports.paddingRight = paddingRight;
exports.paddingTop = paddingTop;
exports.paddingBottom = paddingBottom;
exports.perspective = perspective;
exports.perspectiveOrigin = perspectiveOrigin;
exports.pointerEvents = pointerEvents;
exports.position = position;
exports.resize = resize;
exports.right = right;
exports.tableLayout = tableLayout;
exports.textAlign = textAlign;
exports.textDecorationColor = textDecorationColor;
exports.textDecorationLine = textDecorationLine;
exports.textDecorationStyle = textDecorationStyle;
exports.textIndent = textIndent;
exports.textOverflow = textOverflow;
exports.textShadow = textShadow;
exports.textShadows = textShadows;
exports.textTransform = textTransform;
exports.top = top;
exports.transform = transform;
exports.transforms = transforms;
exports.transformOrigin = transformOrigin;
exports.transformOrigin3d = transformOrigin3d;
exports.transitionDelay = transitionDelay;
exports.transitionDuration = transitionDuration;
exports.transitionProperty = transitionProperty;
exports.transformStyle = transformStyle;
exports.transitionTimingFunction = transitionTimingFunction;
exports.userSelect = userSelect;
exports.verticalAlign = verticalAlign;
exports.visibility = visibility;
exports.width = width;
exports.whiteSpace = whiteSpace;
exports.wordBreak = wordBreak;
exports.wordSpacing = wordSpacing;
exports.wordWrap = wordWrap;
exports.zIndex = zIndex;
exports.selector = selector;
exports.media = media;
exports.active = active;
exports.checked = checked;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
exports.defined = defined;
exports.disabled = disabled;
exports.empty = empty;
exports.enabled = enabled;
exports.first = first;
exports.firstChild = firstChild;
exports.firstOfType = firstOfType;
exports.focus = focus;
exports.focusWithin = focusWithin;
exports.host = host;
exports.hover = hover;
exports.indeterminate = indeterminate;
exports.inRange = inRange;
exports.invalid = invalid;
exports.lang = lang;
exports.lastChild = lastChild;
exports.lastOfType = lastOfType;
exports.link = link;
exports.not__ = not__;
exports.Nth = Nth;
exports.nthChild = nthChild;
exports.nthLastChild = nthLastChild;
exports.nthLastOfType = nthLastOfType;
exports.nthOfType = nthOfType;
exports.onlyChild = onlyChild;
exports.onlyOfType = onlyOfType;
exports.optional = optional;
exports.outOfRange = outOfRange;
exports.readOnly = readOnly;
exports.readWrite = readWrite;
exports.required = required;
exports.root = root;
exports.scope = scope;
exports.target = target;
exports.valid = valid;
exports.visited = visited;
exports.after = after;
exports.before = before;
exports.firstLetter = firstLetter;
exports.firstLine = firstLine;
exports.placeholder = placeholder;
exports.selection = selection;
exports.child = child;
exports.children = children;
exports.directSibling = directSibling;
exports.siblings = siblings;
exports.anyLink = anyLink;
exports.initial = initial;
exports.inherit_ = inherit_;
exports.unset = unset;
exports.auto = auto;
exports.none = none;
exports.text = text;
exports.pct = pct;
exports.ch = ch;
exports.cm = cm;
exports.em = em;
exports.ex = ex;
exports.mm = mm;
exports.pt = pt;
exports.px = px;
exports.pxFloat = pxFloat;
exports.rem = rem;
exports.vh = vh;
exports.vmin = vmin;
exports.vmax = vmax;
exports.zero = zero;
exports.deg = deg;
exports.rad = rad;
exports.grad = grad;
exports.turn = turn;
exports.ltr = ltr;
exports.rtl = rtl;
exports.absolute = absolute;
exports.relative = relative;
exports.$$static = $$static;
exports.fixed = fixed;
exports.sticky = sticky;
exports.horizontal = horizontal;
exports.vertical = vertical;
exports.smallCaps = smallCaps;
exports.italic = italic;
exports.oblique = oblique;
exports.hidden = hidden;
exports.visible = visible;
exports.scroll = scroll;
exports.rgb = rgb;
exports.rgba = rgba;
exports.hsl = hsl;
exports.hsla = hsla;
exports.hex = hex;
exports.transparent = transparent;
exports.currentColor = currentColor;
exports.local = local;
exports.paddingBox = paddingBox;
exports.borderBox = borderBox;
exports.contentBox = contentBox;
exports.noRepeat = noRepeat;
exports.space = space;
exports.repeat = repeat;
exports.minmax = minmax;
exports.repeatX = repeatX;
exports.repeatY = repeatY;
exports.contain = contain;
exports.cover = cover;
exports.row = row;
exports.rowReverse = rowReverse;
exports.column = column;
exports.columnReverse = columnReverse;
exports.wrap = wrap;
exports.nowrap = nowrap;
exports.wrapReverse = wrapReverse;
exports.inline = inline;
exports.block = block;
exports.contents = contents;
exports.flexBox = flexBox;
exports.grid = grid;
exports.inlineBlock = inlineBlock;
exports.inlineFlex = inlineFlex;
exports.inlineGrid = inlineGrid;
exports.inlineTable = inlineTable;
exports.listItem = listItem;
exports.runIn = runIn;
exports.table = table;
exports.tableCaption = tableCaption;
exports.tableColumnGroup = tableColumnGroup;
exports.tableHeaderGroup = tableHeaderGroup;
exports.tableFooterGroup = tableFooterGroup;
exports.tableRowGroup = tableRowGroup;
exports.tableCell = tableCell;
exports.tableColumn = tableColumn;
exports.tableRow = tableRow;
exports.flexStart = flexStart;
exports.flexEnd = flexEnd;
exports.center = center;
exports.stretch = stretch;
exports.spaceBetween = spaceBetween;
exports.spaceAround = spaceAround;
exports.spaceEvenly = spaceEvenly;
exports.baseline = baseline;
exports.forwards = forwards;
exports.backwards = backwards;
exports.both = both;
exports.infinite = infinite;
exports.count = count;
exports.paused = paused;
exports.running = running;
exports.inside = inside;
exports.outside = outside;
exports.solid = solid;
exports.dotted = dotted;
exports.dashed = dashed;
exports.underline = underline;
exports.overline = overline;
exports.lineThrough = lineThrough;
exports.clip = clip;
exports.ellipsis = ellipsis;
exports.wavy = wavy;
exports.$$double = $$double;
exports.uppercase = uppercase;
exports.lowercase = lowercase;
exports.capitalize = capitalize;
exports.sub = sub;
exports.$$super = $$super;
exports.textTop = textTop;
exports.textBottom = textBottom;
exports.middle = middle;
exports.normal = normal;
exports.breakAll = breakAll;
exports.keepAll = keepAll;
exports.breakWord = breakWord;
exports.reverse = reverse;
exports.alternate = alternate;
exports.alternateReverse = alternateReverse;
exports.fill = fill;
exports.content = content;
exports.maxContent = maxContent;
exports.minContent = minContent;
exports.fitContent = fitContent;
exports.all = all;
exports.round = round;
exports.miter = miter;
exports.bevel = bevel;
exports.butt = butt;
exports.square = square;
exports.thin = thin;
exports.extraLight = extraLight;
exports.light = light;
exports.medium = medium;
exports.semiBold = semiBold;
exports.bold = bold;
exports.extraBold = extraBold;
exports.lighter = lighter;
exports.bolder = bolder;
exports.fr = fr;
exports.vw = vw;
exports.localUrl = localUrl;
exports.url = url;
exports.linear = linear;
exports.ease = ease;
exports.easeIn = easeIn;
exports.easeOut = easeOut;
exports.easeInOut = easeInOut;
exports.stepStart = stepStart;
exports.stepEnd = stepEnd;
exports.steps = steps;
exports.cubicBezier = cubicBezier;
exports.marginBox = marginBox;
exports.fillBox = fillBox;
exports.strokeBox = strokeBox;
exports.viewBox = viewBox;
exports.translate = translate;
exports.translate3d = translate3d;
exports.translateX = translateX;
exports.translateY = translateY;
exports.translateZ = translateZ;
exports.scale = scale;
exports.scale3d = scale3d;
exports.scaleX = scaleX;
exports.scaleY = scaleY;
exports.scaleZ = scaleZ;
exports.rotate = rotate;
exports.rotate3d = rotate3d;
exports.rotateX = rotateX;
exports.rotateY = rotateY;
exports.rotateZ = rotateZ;
exports.skew = skew;
exports.skewX = skewX;
exports.skewY = skewY;
exports.linearGradient = linearGradient;
exports.repeatingLinearGradient = repeatingLinearGradient;
exports.radialGradient = radialGradient;
exports.repeatingRadialGradient = repeatingRadialGradient;
exports.areas = areas;
exports.ident = ident;
exports.numIdent = numIdent;
exports.contextMenu = contextMenu;
exports.help = help;
exports.pointer = pointer;
exports.progress = progress;
exports.wait = wait;
exports.cell = cell;
exports.crosshair = crosshair;
exports.verticalText = verticalText;
exports.alias = alias;
exports.copy = copy;
exports.move = move;
exports.noDrop = noDrop;
exports.notAllowed = notAllowed;
exports.grab = grab;
exports.grabbing = grabbing;
exports.allScroll = allScroll;
exports.colResize = colResize;
exports.rowResize = rowResize;
exports.nResize = nResize;
exports.eResize = eResize;
exports.sResize = sResize;
exports.wResize = wResize;
exports.neResize = neResize;
exports.nwResize = nwResize;
exports.seResize = seResize;
exports.swResize = swResize;
exports.ewResize = ewResize;
exports.nsResize = nsResize;
exports.neswResize = neswResize;
exports.nwseResize = nwseResize;
exports.zoomIn = zoomIn;
exports.zoomOut = zoomOut;
exports.flex3 = flex3;
exports.border = border;
exports.borderTop = borderTop;
exports.backgroundSize = backgroundSize;
exports.textDecoration = textDecoration;
exports.background = background;
exports.backgrounds = backgrounds;
exports.gridAutoColumns = gridAutoColumns;
exports.gridAutoRows = gridAutoRows;
exports.gridTemplateColumns = gridTemplateColumns;
exports.gridTemplateRows = gridTemplateRows;
exports.Calc = Calc;
exports.size = size;
exports.filter = filter;
exports.Transition = Transition;
exports.transitionValue = transitionValue;
exports.transitionList = transitionList;
exports.transition = transition;
exports.transitions = transitions;
exports.Animation = Animation;
exports.animationValue = animationValue;
exports.animation = animation;
exports.animations = animations;
exports.animationName = animationName;
exports.SVG = SVG;
exports.aliceblue = aliceblue;
exports.antiquewhite = antiquewhite;
exports.aqua = aqua;
exports.aquamarine = aquamarine;
exports.azure = azure;
exports.beige = beige;
exports.bisque = bisque;
exports.black = black;
exports.blanchedalmond = blanchedalmond;
exports.blue = blue;
exports.blueviolet = blueviolet;
exports.brown = brown;
exports.burlywood = burlywood;
exports.cadetblue = cadetblue;
exports.chartreuse = chartreuse;
exports.chocolate = chocolate;
exports.coral = coral;
exports.cornflowerblue = cornflowerblue;
exports.cornsilk = cornsilk;
exports.crimson = crimson;
exports.cyan = cyan;
exports.darkblue = darkblue;
exports.darkcyan = darkcyan;
exports.darkgoldenrod = darkgoldenrod;
exports.darkgray = darkgray;
exports.darkgrey = darkgrey;
exports.darkgreen = darkgreen;
exports.darkkhaki = darkkhaki;
exports.darkmagenta = darkmagenta;
exports.darkolivegreen = darkolivegreen;
exports.darkorange = darkorange;
exports.darkorchid = darkorchid;
exports.darkred = darkred;
exports.darksalmon = darksalmon;
exports.darkseagreen = darkseagreen;
exports.darkslateblue = darkslateblue;
exports.darkslategray = darkslategray;
exports.darkslategrey = darkslategrey;
exports.darkturquoise = darkturquoise;
exports.darkviolet = darkviolet;
exports.deeppink = deeppink;
exports.deepskyblue = deepskyblue;
exports.dimgray = dimgray;
exports.dimgrey = dimgrey;
exports.dodgerblue = dodgerblue;
exports.firebrick = firebrick;
exports.floralwhite = floralwhite;
exports.forestgreen = forestgreen;
exports.fuchsia = fuchsia;
exports.gainsboro = gainsboro;
exports.ghostwhite = ghostwhite;
exports.gold = gold;
exports.goldenrod = goldenrod;
exports.gray = gray;
exports.grey = grey;
exports.green = green;
exports.greenyellow = greenyellow;
exports.honeydew = honeydew;
exports.hotpink = hotpink;
exports.indianred = indianred;
exports.indigo = indigo;
exports.ivory = ivory;
exports.khaki = khaki;
exports.lavender = lavender;
exports.lavenderblush = lavenderblush;
exports.lawngreen = lawngreen;
exports.lemonchiffon = lemonchiffon;
exports.lightblue = lightblue;
exports.lightcoral = lightcoral;
exports.lightcyan = lightcyan;
exports.lightgoldenrodyellow = lightgoldenrodyellow;
exports.lightgray = lightgray;
exports.lightgrey = lightgrey;
exports.lightgreen = lightgreen;
exports.lightpink = lightpink;
exports.lightsalmon = lightsalmon;
exports.lightseagreen = lightseagreen;
exports.lightskyblue = lightskyblue;
exports.lightslategray = lightslategray;
exports.lightslategrey = lightslategrey;
exports.lightsteelblue = lightsteelblue;
exports.lightyellow = lightyellow;
exports.lime = lime;
exports.limegreen = limegreen;
exports.linen = linen;
exports.magenta = magenta;
exports.maroon = maroon;
exports.mediumaquamarine = mediumaquamarine;
exports.mediumblue = mediumblue;
exports.mediumorchid = mediumorchid;
exports.mediumpurple = mediumpurple;
exports.mediumseagreen = mediumseagreen;
exports.mediumslateblue = mediumslateblue;
exports.mediumspringgreen = mediumspringgreen;
exports.mediumturquoise = mediumturquoise;
exports.mediumvioletred = mediumvioletred;
exports.midnightblue = midnightblue;
exports.mintcream = mintcream;
exports.mistyrose = mistyrose;
exports.moccasin = moccasin;
exports.navajowhite = navajowhite;
exports.navy = navy;
exports.oldlace = oldlace;
exports.olive = olive;
exports.olivedrab = olivedrab;
exports.orange = orange;
exports.orangered = orangered;
exports.orchid = orchid;
exports.palegoldenrod = palegoldenrod;
exports.palegreen = palegreen;
exports.paleturquoise = paleturquoise;
exports.palevioletred = palevioletred;
exports.papayawhip = papayawhip;
exports.peachpuff = peachpuff;
exports.peru = peru;
exports.pink = pink;
exports.plum = plum;
exports.powderblue = powderblue;
exports.purple = purple;
exports.rebeccapurple = rebeccapurple;
exports.red = red;
exports.rosybrown = rosybrown;
exports.royalblue = royalblue;
exports.saddlebrown = saddlebrown;
exports.salmon = salmon;
exports.sandybrown = sandybrown;
exports.seagreen = seagreen;
exports.seashell = seashell;
exports.sienna = sienna;
exports.silver = silver;
exports.skyblue = skyblue;
exports.slateblue = slateblue;
exports.slategray = slategray;
exports.slategrey = slategrey;
exports.snow = snow;
exports.springgreen = springgreen;
exports.steelblue = steelblue;
exports.tan = tan;
exports.teal = teal;
exports.thistle = thistle;
exports.tomato = tomato;
exports.turquoise = turquoise;
exports.violet = violet;
exports.wheat = wheat;
exports.white = white;
exports.whitesmoke = whitesmoke;
exports.yellow = yellow;
exports.yellowgreen = yellowgreen;
exports.$$global = $$global;
exports.insertRule = insertRule;
exports.merge = merge;
exports.style = style;
exports.keyframes = keyframes;
exports.fontFace = fontFace;
/* include Not a pure module */
