'use strict';

var Css = require("bs-css-emotion/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function rem_of_px(pixel) {
  return Css.rem(pixel / 16);
}

var StyleU = {
  rem_of_px: rem_of_px
};

var ErrorU = {
  unknownErrorMessage: "An unknown error has occurred."
};

function createMarkup(markup) {
  return React.createElement("span", {
              dangerouslySetInnerHTML: {
                __html: markup
              }
            });
}

var DomU = {
  createMarkup: createMarkup
};

function mergeDeep(items) {
  return Belt_Array.reduce(items, [], Belt_Array.concat);
}

function arrayKeepSome(array) {
  return Belt_Array.reduce(array, [], (function (acc, item) {
                if (item !== undefined) {
                  return Belt_Array.concat(acc, [Caml_option.valFromOption(item)]);
                } else {
                  return acc;
                }
              }));
}

var ArrayU = {
  mergeDeep: mergeDeep,
  arrayKeepSome: arrayKeepSome
};

function handleEnterPressOnKeyDown($$event, callback) {
  $$event.persist();
  if ($$event.which === 13 || $$event.keyCode === 13) {
    return Curry._1(callback, /* () */0);
  } else {
    return 0;
  }
}

function handleArrowUpPressOnKeyDown($$event, callback) {
  $$event.persist();
  if ($$event.which === 38 || $$event.keyCode === 38) {
    return Curry._1(callback, /* () */0);
  } else {
    return 0;
  }
}

function handleArrowDownPressOnKeyDown($$event, callback) {
  $$event.persist();
  if ($$event.which === 40 || $$event.keyCode === 40) {
    return Curry._1(callback, /* () */0);
  } else {
    return 0;
  }
}

function focus(node) {
  return node.focus();
}

function focusOn(id) {
  var match = document.getElementById(id);
  if (match == null) {
    var match$1 = document.getElementById("default-container");
    if (match$1 == null) {
      return /* () */0;
    } else {
      return match$1.focus();
    }
  } else {
    return match.focus();
  }
}

var downloadFile = (// Resource: https://stackoverflow.com/questions/3975648/how-to-set-content-disposition-attachment-via-javascript

	async function(fileData, fileName, dataType, blobType) {
		const blob = new Blob([fileData],{type: blobType, charset: "UTF-8"});

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob);
      return;
    }

    const data = await window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.dataType = dataType;
    link.href = data;
    link.download = fileName;
    link.dispatchEvent(new MouseEvent('click'));

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data)
    }, 60);
	});

var InputU = {
  handleEnterPressOnKeyDown: handleEnterPressOnKeyDown,
  handleArrowUpPressOnKeyDown: handleArrowUpPressOnKeyDown,
  handleArrowDownPressOnKeyDown: handleArrowDownPressOnKeyDown,
  focus: focus,
  focusOn: focusOn,
  downloadFile: downloadFile
};

exports.StyleU = StyleU;
exports.ErrorU = ErrorU;
exports.DomU = DomU;
exports.ArrayU = ArrayU;
exports.InputU = InputU;
/* downloadFile Not a pure module */
