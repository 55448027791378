// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var ReactDOMRe = require("reason-react/src/ReactDOMRe.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Quiz$WordpressSeamonsterStarter = require("./reason-apps/Quiz.bs.js");
var MapTabsRoot$WordpressSeamonsterStarter = require("./reason-apps/MapTabs/MapTabsRoot.bs.js");

var mounts = [
  {
    id: "react-mount-quiz",
    node: Belt_Option.getWithDefault(Belt_Option.map(Caml_option.nullable_to_opt(window.wp_data), (function (wp_data) {
                return React.createElement(Quiz$WordpressSeamonsterStarter.make, {
                            questions: wp_data.quiz_questions,
                            shareUrl: wp_data.quiz_fb_share_url,
                            failUrl: wp_data.quiz_fail_url,
                            successMessage: wp_data.quiz_success_message,
                            failureMessage: wp_data.quiz_failure_message
                          });
              })), null)
  },
  {
    id: "react-mount--map-tabs",
    node: React.createElement(MapTabsRoot$WordpressSeamonsterStarter.make, { })
  }
];

Belt_Array.map(mounts, (function (mount) {
        var match = document.getElementById(mount.id);
        if (match == null) {
          return /* () */0;
        } else {
          return ReactDOMRe.renderToElementWithId(mount.node, mount.id);
        }
      }));

exports.mounts = mounts;
/* mounts Not a pure module */
