// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReactCopyToClipboard = require("react-copy-to-clipboard");

function Quiz__CopyToClipboard(Props) {
  var text = Props.text;
  var match = React.useState((function () {
          return false;
        }));
  var setCopied = match[1];
  var copied = match[0];
  React.useEffect((function () {
          var timeoutId = setTimeout((function (param) {
                  if (copied) {
                    return Curry._1(setCopied, (function (param) {
                                  return false;
                                }));
                  } else {
                    return 0;
                  }
                }), 3000);
          return (function (param) {
                    clearTimeout(timeoutId);
                    return /* () */0;
                  });
        }), /* tuple */[
        copied,
        setCopied
      ]);
  return React.createElement(ReactCopyToClipboard, {
              text: text,
              onCopy: (function (param) {
                  return Curry._1(setCopied, (function (param) {
                                return true;
                              }));
                }),
              children: React.createElement("div", {
                    style: {
                      width: "182px"
                    }
                  }, React.createElement("i", {
                        className: "fal fa-clone"
                      }), React.createElement("span", undefined, copied ? "Link Copied!" : "Copy Link"))
            });
}

var make = Quiz__CopyToClipboard;

exports.make = make;
/* react Not a pure module */
