// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Theme$WordpressSeamonsterStarter = require("../Lib/Theme.bs.js");
var Utils$WordpressSeamonsterStarter = require("../Lib/Utils.bs.js");
var MapTabs$WordpressSeamonsterStarter = require("./components/MapTabs.bs.js");
var MapTabsTypes$WordpressSeamonsterStarter = require("./MapTabsTypes.bs.js");

var counties$prime = Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(window.wp_counties), /* Error */Block.__(1, ["wp_counties not found"]), (function (json) {
        var match = MapTabsTypes$WordpressSeamonsterStarter.t_decode(json);
        if (match.tag) {
          Utils$WordpressSeamonsterStarter.captureDeccoError(match[0], "wp_counties");
          return /* Error */Block.__(1, ["wp_counties failed to decode"]);
        } else {
          return /* Ok */Block.__(0, [match[0]]);
        }
      }));

var map_tabs$prime = Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(window.map_tabs), /* Error */Block.__(1, ["map_tabs not found"]), (function (json) {
        var match = MapTabsTypes$WordpressSeamonsterStarter.pageFields_decode(json);
        if (match.tag) {
          Utils$WordpressSeamonsterStarter.captureDeccoError(match[0], "map_tabs");
          return /* Error */Block.__(1, ["map_tabs failed to decode"]);
        } else {
          return /* Ok */Block.__(0, [match[0]]);
        }
      }));

var root = Curry._1(Css.style, /* :: */[
      Css.backgroundColor(Css.hex(Theme$WordpressSeamonsterStarter.lightBackground)),
      /* :: */[
        Css.padding2(Css.px(30), Css.px(0)),
        /* :: */[
          Css.selector("> div", /* :: */[
                Css.maxWidth(Css.px(870)),
                /* :: */[
                  Css.marginLeft(/* auto */-1065951377),
                  /* :: */[
                    Css.marginRight(/* auto */-1065951377),
                    /* [] */0
                  ]
                ]
              ]),
          /* :: */[
            Theme$WordpressSeamonsterStarter.MediaQuery.sm(/* :: */[
                  Css.padding2(Css.px(45), Css.px(0)),
                  /* [] */0
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var Style = {
  root: root
};

function MapTabsRoot(Props) {
  var tmp;
  var exit = 0;
  var msg;
  if (counties$prime.tag) {
    msg = counties$prime[0];
    exit = 1;
  } else if (map_tabs$prime.tag) {
    msg = map_tabs$prime[0];
    exit = 1;
  } else {
    tmp = React.createElement(MapTabs$WordpressSeamonsterStarter.make, {
          data: counties$prime[0],
          title: map_tabs$prime[0].title
        });
  }
  if (exit === 1) {
    console.log(msg);
    tmp = React.createElement("div", {
          className: "notification is-danger"
        }, "An unknown problem occurred. Please try visiting this page later. If this continues, please contact us.");
  }
  return React.createElement("div", {
              className: root
            }, tmp);
}

var make = MapTabsRoot;

exports.counties$prime = counties$prime;
exports.map_tabs$prime = map_tabs$prime;
exports.Style = Style;
exports.make = make;
/* counties' Not a pure module */
