// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Css_Core = require("bs-css/src/Css_Core.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Reach$WordpressSeamonsterStarter = require("./Bindings/Reach/Reach.bs.js");
var Theme$WordpressSeamonsterStarter = require("./Lib/Theme.bs.js");
var Quiz__Share$WordpressSeamonsterStarter = require("./Components/Quiz__Share.bs.js");
var Quiz__Choices$WordpressSeamonsterStarter = require("./Components/Quiz__Choices.bs.js");
var Quiz__ProgressBar$WordpressSeamonsterStarter = require("./Components/Quiz__ProgressBar.bs.js");
var Quiz__ProgressText$WordpressSeamonsterStarter = require("./Components/Quiz__ProgressText.bs.js");

Curry._2(Css.$$global, "[data-reach-dialog-overlay]", /* :: */[
      Css.zIndex(99),
      /* [] */0
    ]);

Curry._2(Css.$$global, "[data-reach-dialog-content]", /* :: */[
      Css.maxWidth(Css.px(940)),
      /* :: */[
        Css.width(/* `calc */[
              -1044768619,
              /* tuple */[
                /* sub */5745024,
                Css.pct(100.0),
                Css.px(40)
              ]
            ]),
        /* :: */[
          Css.margin4(Css.px(26), Css.px(20), Css.px(16), Css.px(20)),
          /* :: */[
            Css.boxShadow(Css_Core.Shadow.box(Css.px(10), Css.px(10), Css.px(2), undefined, undefined, Css.rgba(100, 100, 100, 0.4))),
            /* :: */[
              Css.padding(/* zero */-789508312),
              /* :: */[
                Css.position(/* relative */903134412),
                /* :: */[
                  Theme$WordpressSeamonsterStarter.MediaQuery.sm(/* :: */[
                        Css.marginLeft(/* auto */-1065951377),
                        /* :: */[
                          Css.marginRight(/* auto */-1065951377),
                          /* :: */[
                            Css.top(Css.pct(50)),
                            /* :: */[
                              Css.transform(Css.translateY(Css.pct(-50))),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var quiz = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.justifyContent(/* center */98248149),
        /* :: */[
          Css.alignItems(/* center */98248149),
          /* :: */[
            Css.padding2(Css.px(32), /* zero */-789508312),
            /* [] */0
          ]
        ]
      ]
    ]);

var takeQuizButton = Curry._1(Css.style, /* [] */0) + " button is-primary";

var paginationButton = Curry._1(Css.style, /* :: */[
      Css.padding(/* zero */-789508312),
      /* :: */[
        Css.textTransform(/* uppercase */-415330030),
        /* :: */[
          Css.fontWeight(/* semiBold */-812709613),
          /* :: */[
            Css.lineHeight(Css.px(18)),
            /* :: */[
              Css.color(/* `hex */[
                    5194459,
                    Theme$WordpressSeamonsterStarter.brandBlue
                  ]),
              /* :: */[
                Css.borderColor(/* transparent */582626130),
                /* :: */[
                  Css.selector("&:hover", /* :: */[
                        Css.borderColor(/* transparent */582626130),
                        /* :: */[
                          Css.textDecoration(/* underline */131142924),
                          /* :: */[
                            Css.color(/* `hex */[
                                  5194459,
                                  Theme$WordpressSeamonsterStarter.brandBlue
                                ]),
                            /* [] */0
                          ]
                        ]
                      ]),
                  /* :: */[
                    Css.selector("&:focus", /* :: */[
                          Css.borderColor(/* transparent */582626130),
                          /* :: */[
                            Css.outlineStyle(/* none */-922086728),
                            /* :: */[
                              Css.important(Css.boxShadow(/* none */-922086728)),
                              /* [] */0
                            ]
                          ]
                        ]),
                    /* :: */[
                      Css.selector("&:active:not[disabled]", /* :: */[
                            Css.transform(Css.translateY(Css.px(1))),
                            /* [] */0
                          ]),
                      /* :: */[
                        Css.selector("&[disabled]", /* :: */[
                              Css.borderColor(/* transparent */582626130),
                              /* :: */[
                                Css.outlineStyle(/* none */-922086728),
                                /* :: */[
                                  Css.important(Css.boxShadow(/* none */-922086728)),
                                  /* :: */[
                                    Css.textDecoration(/* none */-922086728),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

function prevButton(hide) {
  return Curry._1(Css.merge, /* :: */[
              paginationButton,
              /* :: */[
                Curry._1(Css.style, /* :: */[
                      Css.visibility(hide ? /* hidden */-862584982 : /* visible */589592690),
                      /* [] */0
                    ]),
                /* [] */0
              ]
            ]) + " button is-normal";
}

var nextButton = paginationButton + " button is-normal";

var pagination = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.justifyContent(/* spaceBetween */516682146),
        /* [] */0
      ]
    ]);

var closeModalButton = Curry._1(Css.style, /* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(Css.px(5)),
        /* :: */[
          Css.right(Css.px(20)),
          /* :: */[
            Css.fontSize(Css.px(28)),
            /* :: */[
              Css.cursor(/* pointer */-786317123),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var dialogInner = Curry._1(Css.style, /* :: */[
      Css.label("dialog-inner"),
      /* :: */[
        Css.padding2(/* zero */-789508312, Css.px(20)),
        /* :: */[
          Css.display(/* flex */-1010954439),
          /* :: */[
            Css.flexDirection(/* column */-963948842),
            /* :: */[
              Theme$WordpressSeamonsterStarter.MediaQuery.sm(/* :: */[
                    Css.width(Css.pct(76.0)),
                    /* [] */0
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var questionHelper = Curry._1(Css.style, /* :: */[
      Css.fontSize(Css.px(18)),
      /* [] */0
    ]);

var progressText = Curry._1(Css.style, /* :: */[
      Css.fontWeight(/* semiBold */-812709613),
      /* :: */[
        Css.lineHeight(Css.px(18)),
        /* :: */[
          Css.color(/* `hex */[
                5194459,
                Theme$WordpressSeamonsterStarter.brandBlue
              ]),
          /* :: */[
            Css.marginBottom(Css.px(16)),
            /* :: */[
              Css.textTransform(/* uppercase */-415330030),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var conclusionButton = Curry._1(Css.style, /* :: */[
      Css.fontSize(Css.px(18)),
      /* :: */[
        Css.lineHeight(Css.px(28)),
        /* :: */[
          Css.fontWeight(/* medium */-20425611),
          /* :: */[
            Css.alignSelf(/* center */98248149),
            /* :: */[
              Css.color(/* `hex */[
                    5194459,
                    Theme$WordpressSeamonsterStarter.brandBlue
                  ]),
              /* :: */[
                Css.cursor(/* pointer */-786317123),
                /* :: */[
                  Css.selector("&:hover", /* :: */[
                        Css.textDecoration(/* underline */131142924),
                        /* [] */0
                      ]),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var tipsLink = Curry._1(Css.style, /* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.maxWidth(Css.px(330)),
        /* :: */[
          Css.height(Css.px(50)),
          /* :: */[
            Css.display(/* flex */-1010954439),
            /* :: */[
              Css.flexDirection(/* column */-963948842),
              /* :: */[
                Css.justifyContent(/* center */98248149),
                /* :: */[
                  Css.textAlign(/* center */98248149),
                  /* :: */[
                    Css.backgroundColor(/* `hex */[
                          5194459,
                          Theme$WordpressSeamonsterStarter.brandBlue
                        ]),
                    /* :: */[
                      Css.color(Css.white),
                      /* :: */[
                        Css.alignSelf(/* center */98248149),
                        /* :: */[
                          Css.margin2(Css.px(34), /* zero */-789508312),
                          /* :: */[
                            Css.textTransform(Css.uppercase),
                            /* :: */[
                              Css.fontSize(Css.px(14)),
                              /* :: */[
                                Css.fontWeight(/* bold */-1055161979),
                                /* :: */[
                                  Css.selector("&:hover", /* :: */[
                                        Css.color(Css.white),
                                        /* [] */0
                                      ]),
                                  /* :: */[
                                    Theme$WordpressSeamonsterStarter.MediaQuery.sm(/* :: */[
                                          Css.margin2(Css.px(12), /* zero */-789508312),
                                          /* [] */0
                                        ]),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  quiz: quiz,
  takeQuizButton: takeQuizButton,
  paginationButton: paginationButton,
  prevButton: prevButton,
  nextButton: nextButton,
  pagination: pagination,
  closeModalButton: closeModalButton,
  dialogInner: dialogInner,
  questionHelper: questionHelper,
  progressText: progressText,
  conclusionButton: conclusionButton,
  tipsLink: tipsLink
};

function Quiz(Props) {
  var questions = Props.questions;
  var shareUrl = Props.shareUrl;
  var failUrl = Props.failUrl;
  var successMessage = Props.successMessage;
  var failureMessage = Props.failureMessage;
  var numQuestions = React.useMemo((function () {
          return questions.length;
        }));
  var match = React.useState((function () {
          return false;
        }));
  var setOpenQuiz = match[1];
  var match$1 = React.useState((function () {
          return /* Question */[0];
        }));
  var setScreen = match$1[1];
  var screen = match$1[0];
  var match$2 = React.useState((function () {
          return Belt_List.makeBy(numQuestions, (function (i) {
                        return /* tuple */[
                                i,
                                undefined
                              ];
                      }));
        }));
  var setChoices = match$2[1];
  var choices = match$2[0];
  var maxIndex = React.useMemo((function () {
          return numQuestions - 1 | 0;
        }), [questions]);
  var nextQuestion = React.useCallback((function (param) {
          return Curry._1(setScreen, (function (screen) {
                        if (screen) {
                          var i = screen[0];
                          if (i < maxIndex) {
                            return /* Question */[i + 1 | 0];
                          } else {
                            return screen;
                          }
                        } else {
                          return screen;
                        }
                      }));
        }), [screen]);
  var prevQuestion = React.useCallback((function (_event) {
          return Curry._1(setScreen, (function (screen) {
                        if (screen) {
                          var i = screen[0];
                          if (i > 0) {
                            return /* Question */[i - 1 | 0];
                          } else {
                            return /* Question */[0];
                          }
                        } else {
                          return /* Question */[maxIndex];
                        }
                      }));
        }), [screen]);
  var conclude = React.useCallback((function (param) {
          return Curry._1(setScreen, (function (param) {
                        return /* Conclusion */0;
                      }));
        }));
  var restartQuiz = function (param) {
    Curry._1(setChoices, (function (param) {
            return Belt_List.makeBy(numQuestions, (function (i) {
                          return /* tuple */[
                                  i,
                                  undefined
                                ];
                        }));
          }));
    return Curry._1(setScreen, (function (param) {
                  return /* Question */[0];
                }));
  };
  var handleTakeQuizClick = function (_event) {
    if (screen === /* Conclusion */0) {
      restartQuiz(/* () */0);
    }
    return Curry._1(setOpenQuiz, (function (param) {
                  return true;
                }));
  };
  var advanceQuiz = function (i) {
    if (i === maxIndex) {
      return Curry._1(conclude, /* () */0);
    } else {
      return Curry._1(nextQuestion, /* () */0);
    }
  };
  var handleSelectChoice = function (choice) {
    if (screen) {
      var i = screen[0];
      Curry._1(setChoices, (function (choices) {
              return Belt_List.setAssoc(choices, i, choice, (function (prim, prim$1) {
                            return prim === prim$1;
                          }));
            }));
      return advanceQuiz(i);
    } else {
      return /* () */0;
    }
  };
  var tmp;
  if (screen) {
    var i = screen[0];
    var match$3 = Belt_Array.get(questions, i);
    var match$4 = Belt_List.getAssoc(choices, i, (function (prim, prim$1) {
            return prim === prim$1;
          }));
    if (match$3 !== undefined && match$4 !== undefined) {
      var chosen = Caml_option.valFromOption(match$4);
      var match$5 = match$3;
      var tmp$1 = {
        onSelect: handleSelectChoice
      };
      if (chosen !== undefined) {
        tmp$1.chosen = Caml_option.valFromOption(chosen);
      }
      var hidePrev = Caml_obj.caml_equal(screen, /* Question */[0]);
      tmp = React.createElement(React.Fragment, undefined, React.createElement(Quiz__ProgressBar$WordpressSeamonsterStarter.make, {
                progress: i + 1 | 0,
                max: numQuestions
              }), React.createElement("div", {
                className: dialogInner
              }, React.createElement(Quiz__ProgressText$WordpressSeamonsterStarter.make, {
                    progress: i + 1 | 0,
                    max: numQuestions
                  }), React.createElement("h3", undefined, match$5.question), React.createElement("div", {
                    className: questionHelper
                  }, match$5.question_helper), React.createElement(Quiz__Choices$WordpressSeamonsterStarter.make, tmp$1), React.createElement("div", {
                    className: pagination
                  }, React.createElement("button", {
                        className: prevButton(hidePrev),
                        onClick: prevQuestion
                      }, "< Back"), React.createElement("div", undefined, React.createElement("button", {
                            className: nextButton,
                            disabled: chosen === undefined,
                            onClick: (function (param) {
                                return advanceQuiz(i);
                              })
                          }, i === maxIndex ? "Done" : "Next >")))));
    } else {
      tmp = React.createElement("div", undefined, "Sorry, we couldn't find the question. Please try again later.");
    }
  } else {
    var getSummary = function (choices) {
      return Belt_List.reduce(choices, /* tuple */[
                  0,
                  0
                ], (function (prev, param) {
                    var maybeChoice = param[1];
                    var yess = prev[1];
                    var nos = prev[0];
                    if (maybeChoice !== undefined) {
                      switch (maybeChoice) {
                        case /* Yes */0 :
                            return /* tuple */[
                                    nos,
                                    yess + 1 | 0
                                  ];
                        case /* No */1 :
                            return /* tuple */[
                                    nos + 1 | 0,
                                    yess
                                  ];
                        case /* Sometimes */2 :
                            return prev;
                        
                      }
                    } else {
                      return prev;
                    }
                  }));
    };
    var match$6 = getSummary(choices);
    var yess = match$6[1];
    var nos = match$6[0];
    var noTimesText = nos === 1 ? "time" : "times";
    var yesTimesText = yess === 1 ? "time" : "times";
    tmp = React.createElement("div", {
          className: dialogInner
        }, React.createElement("div", {
              className: progressText
            }, "Conclusion"), nos === 0 ? React.createElement(React.Fragment, undefined, React.createElement("h3", undefined, successMessage), React.createElement(Quiz__Share$WordpressSeamonsterStarter.make, {
                    className: Curry._1(Css.style, /* :: */[
                          Css.marginTop(Css.px(30)),
                          /* :: */[
                            Css.marginBottom(Css.px(64)),
                            /* [] */0
                          ]
                        ]),
                    url: shareUrl
                  }), React.createElement("div", {
                    className: conclusionButton,
                    onClick: (function (param) {
                        return Curry._1(setOpenQuiz, (function (param) {
                                      return false;
                                    }));
                      })
                  }, "Exit Quiz")) : React.createElement(React.Fragment, undefined, React.createElement("h3", {
                    className: Curry._1(Css.style, /* :: */[
                          Css.marginBottom(Css.em(0.5)),
                          /* [] */0
                        ])
                  }, "You responded with “no” " + (String(nos) + (" " + (String(noTimesText) + (", and you responded with “yes”  " + (String(yess) + (" " + (String(yesTimesText) + ".")))))))), React.createElement("p", undefined, failureMessage), React.createElement("a", {
                    className: tipsLink,
                    href: failUrl
                  }, "Brush up on your driving skills"), React.createElement("div", {
                    className: conclusionButton,
                    onClick: (function (param) {
                        return restartQuiz(/* () */0);
                      })
                  }, "Start Over")));
  }
  return React.createElement(React.Fragment, undefined, React.createElement("a", {
                  className: takeQuizButton,
                  onClick: handleTakeQuizClick
                }, "Take the Quiz"), React.createElement(Reach$WordpressSeamonsterStarter.Dialog.make, {
                  isOpen: match[0],
                  ariaLabel: "Quiz Dialog",
                  onDismiss: (function (param) {
                      return Curry._1(setOpenQuiz, (function (param) {
                                    return false;
                                  }));
                    }),
                  children: React.createElement("div", {
                        className: quiz
                      }, React.createElement("div", {
                            className: closeModalButton,
                            onClick: (function (param) {
                                return Curry._1(setOpenQuiz, (function (param) {
                                              return false;
                                            }));
                              })
                          }, React.createElement("i", {
                                className: "fal fa-times"
                              })), tmp)
                }));
}

var ProgressBar = /* alias */0;

var ProgressText = /* alias */0;

var Choices = /* alias */0;

var Share = /* alias */0;

var make = Quiz;

exports.ProgressBar = ProgressBar;
exports.ProgressText = ProgressText;
exports.Choices = Choices;
exports.Share = Share;
exports.Styles = Styles;
exports.make = make;
/*  Not a pure module */
