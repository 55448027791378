// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Theme$WordpressSeamonsterStarter = require("../Lib/Theme.bs.js");

var choices = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* :: */[
          Css.justifyContent(/* spaceBetween */516682146),
          /* :: */[
            Css.margin4(Css.px(12), /* zero */-789508312, Css.px(8), /* zero */-789508312),
            /* :: */[
              Css.selector("& > div + div", /* :: */[
                    Css.marginTop(Css.px(13)),
                    /* [] */0
                  ]),
              /* :: */[
                Theme$WordpressSeamonsterStarter.MediaQuery.sm(/* :: */[
                      Css.flexDirection(/* row */5693978),
                      /* :: */[
                        Css.justifyContent(/* spaceBetween */516682146),
                        /* :: */[
                          Css.selector("& > div + div", /* :: */[
                                Css.marginTop(/* zero */-789508312),
                                /* [] */0
                              ]),
                          /* [] */0
                        ]
                      ]
                    ]),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var buttonShared = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* :: */[
          Css.justifyContent(/* center */98248149),
          /* :: */[
            Css.textAlign(/* center */98248149),
            /* :: */[
              Css.height(Css.px(50)),
              /* :: */[
                Css.color(Css.white),
                /* :: */[
                  Css.fontWeight(/* bold */-1055161979),
                  /* :: */[
                    Css.textTransform(/* uppercase */-415330030),
                    /* :: */[
                      Css.cursor(/* pointer */-786317123),
                      /* :: */[
                        Theme$WordpressSeamonsterStarter.MediaQuery.sm(/* :: */[
                              Css.width(Css.pct(30.0)),
                              /* [] */0
                            ]),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var buttonUnlit = Curry._1(Css.merge, /* :: */[
      buttonShared,
      /* :: */[
        Curry._1(Css.style, /* :: */[
              Css.backgroundColor(/* `hex */[
                    5194459,
                    Theme$WordpressSeamonsterStarter.brandBlueLighter
                  ]),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var buttonLit = Curry._1(Css.merge, /* :: */[
      buttonShared,
      /* :: */[
        Curry._1(Css.style, /* :: */[
              Css.backgroundColor(/* `hex */[
                    5194459,
                    Theme$WordpressSeamonsterStarter.brandBlue
                  ]),
              /* :: */[
                Css.color(Css.white),
                /* [] */0
              ]
            ]),
        /* [] */0
      ]
    ]);

function buttonStyle(maybeChosen, choice) {
  if (maybeChosen !== undefined && !Caml_obj.caml_equal(Caml_option.valFromOption(maybeChosen), choice)) {
    return buttonUnlit;
  } else {
    return buttonLit;
  }
}

var Styles = {
  choices: choices,
  buttonShared: buttonShared,
  buttonUnlit: buttonUnlit,
  buttonLit: buttonLit,
  buttonStyle: buttonStyle
};

function Quiz__Choices(Props) {
  var chosen = Props.chosen;
  var onSelect = Props.onSelect;
  return React.createElement("div", {
              className: choices
            }, React.createElement("div", {
                  className: buttonStyle(chosen, /* Yes */0),
                  onClick: (function (param) {
                      return Curry._1(onSelect, /* Yes */0);
                    })
                }, "Yes"), React.createElement("div", {
                  className: buttonStyle(chosen, /* Sometimes */2),
                  onClick: (function (param) {
                      return Curry._1(onSelect, /* Sometimes */2);
                    })
                }, "Sometimes"), React.createElement("div", {
                  className: buttonStyle(chosen, /* No */1),
                  onClick: (function (param) {
                      return Curry._1(onSelect, /* No */1);
                    })
                }, "No"));
}

var make = Quiz__Choices;

exports.Styles = Styles;
exports.make = make;
/* choices Not a pure module */
