// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.js");

var gray = "E0E0E0";

var sectionTop = Css.borderTop(Css.px(2), /* solid */12956715, Css.hex(gray));

var Borders = {
  sectionTop: sectionTop
};

var Breakpoint = {
  xs: 425,
  sm: 768,
  sml: 1024,
  md: 1260,
  lg: 1920
};

function xs(styles) {
  return Css.media("(min-width: " + (String(426) + "px)"), styles);
}

function sm(styles) {
  return Css.media("(min-width: " + (String(769) + "px)"), styles);
}

function sml(styles) {
  return Css.media("(min-width: " + (String(1025) + "px)"), styles);
}

function md(styles) {
  return Css.media("(min-width: " + (String(1261) + "px)"), styles);
}

function lg(styles) {
  return Css.media("(min-width: " + (String(1921) + "px)"), styles);
}

var MediaQuery = {
  xs: xs,
  sm: sm,
  sml: sml,
  md: md,
  lg: lg
};

var brandBlue = "1e3c7c";

var brandBlueLight = "586589";

var brandBlueLighter = "879AC2";

var lightBackground = "F6F0EC";

exports.brandBlue = brandBlue;
exports.brandBlueLight = brandBlueLight;
exports.brandBlueLighter = brandBlueLighter;
exports.lightBackground = lightBackground;
exports.gray = gray;
exports.Borders = Borders;
exports.Breakpoint = Breakpoint;
exports.MediaQuery = MediaQuery;
/* sectionTop Not a pure module */
