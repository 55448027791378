'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Decco = require("decco/src/Decco.js");
var Belt_Result = require("bs-platform/lib/js/belt_Result.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function encode(x, tToJs) {
  if (typeof x === "string") {
    return Decco.stringToJson(x);
  } else {
    return Decco.stringToJson(Curry._1(tToJs, x));
  }
}

function decode(x, tFromJs, name) {
  return Belt_Result.flatMap(Belt_Result.map(Decco.stringFromJson(x), tFromJs), (function (y) {
                if (y !== undefined) {
                  return /* Ok */Block.__(0, [Caml_option.valFromOption(y)]);
                } else {
                  return Decco.error(undefined, "Unknown " + name, x);
                }
              }));
}

function decode_array(x, tFromJs, name) {
  return Decco.arrayFromJson((function (item) {
                return Belt_Result.flatMap(Belt_Result.map(Decco.stringFromJson(item), tFromJs), (function (y) {
                              if (y !== undefined) {
                                return /* Ok */Block.__(0, [Caml_option.valFromOption(y)]);
                              } else {
                                return Decco.error(undefined, "Unknown " + name, item);
                              }
                            }));
              }), x);
}

function MakePV(PV) {
  var tToJs = PV.tToJs;
  var tFromJs = PV.tFromJs;
  var name = PV.name;
  var t_encode = function (param) {
    return encode(param, tToJs);
  };
  var t_decode = function (param) {
    return decode(param, tFromJs, name);
  };
  var t_decode_array = function (param) {
    return decode_array(param, tFromJs, name);
  };
  return {
          tToJs: tToJs,
          tFromJs: tFromJs,
          name: name,
          t_encode: t_encode,
          t_decode: t_decode,
          t_decode_array: t_decode_array
        };
}

exports.encode = encode;
exports.decode = decode;
exports.decode_array = decode_array;
exports.MakePV = MakePV;
/* No side effect */
