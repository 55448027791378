// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$String = require("bs-platform/lib/js/string.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");

function toString(param) {
  if (param !== -601204732) {
    if (param >= 653193961) {
      return "unset";
    } else {
      return "initial";
    }
  } else {
    return "inherit";
  }
}

var Cascading = {
  initial: /* initial */-878767996,
  inherit_: /* inherit_ */-601204732,
  unset: /* unset */653193961,
  toString: toString
};

function s(x) {
  return /* `s */[
          115,
          x
        ];
}

function ms(x) {
  return /* `ms */[
          24422,
          x
        ];
}

function toString$1(param) {
  if (param[0] >= 24422) {
    return param[1].toString() + "ms";
  } else {
    return param[1].toString() + "s";
  }
}

var Time = {
  s: s,
  ms: ms,
  toString: toString$1
};

function pct(x) {
  return /* `percent */[
          -119887163,
          x
        ];
}

function toString$2(param) {
  return param[1].toString() + "%";
}

var Percentage = {
  pct: pct,
  toString: toString$2
};

function toString$3(param) {
  return "url(" + (param[1] + ")");
}

var Url = {
  toString: toString$3
};

function ch(x) {
  return /* `ch */[
          22181,
          x
        ];
}

function em(x) {
  return /* `em */[
          22632,
          x
        ];
}

function ex(x) {
  return /* `ex */[
          22643,
          x
        ];
}

function rem(x) {
  return /* `rem */[
          5691738,
          x
        ];
}

function vh(x) {
  return /* `vh */[
          26418,
          x
        ];
}

function vw(x) {
  return /* `vw */[
          26433,
          x
        ];
}

function vmin(x) {
  return /* `vmin */[
          -833470756,
          x
        ];
}

function vmax(x) {
  return /* `vmax */[
          -833472530,
          x
        ];
}

function px(x) {
  return /* `px */[
          25096,
          x
        ];
}

function pxFloat(x) {
  return /* `pxFloat */[
          -1040484748,
          x
        ];
}

function cm(x) {
  return /* `cm */[
          22186,
          x
        ];
}

function mm(x) {
  return /* `mm */[
          24416,
          x
        ];
}

function inch(x) {
  return /* `inch */[
          -977586742,
          x
        ];
}

function pc(x) {
  return /* `pc */[
          25075,
          x
        ];
}

function pt(x) {
  return /* `pt */[
          25092,
          x
        ];
}

function toString$4(param) {
  if (typeof param === "number") {
    return "0";
  } else {
    var variant = param[0];
    if (variant >= 22632) {
      if (variant >= 25092) {
        if (variant !== 25096) {
          if (variant >= 26433) {
            if (variant >= 5691738) {
              return param[1].toString() + "rem";
            } else {
              return param[1].toString() + "vw";
            }
          } else if (variant >= 26418) {
            return param[1].toString() + "vh";
          } else {
            return param[1].toString() + "pt";
          }
        } else {
          return param[1].toString() + "px";
        }
      } else if (variant >= 24416) {
        if (variant >= 25075) {
          return param[1].toString() + "pc";
        } else {
          return param[1].toString() + "mm";
        }
      } else if (variant >= 22643) {
        return param[1].toString() + "ex";
      } else {
        return param[1].toString() + "em";
      }
    } else if (variant >= -833470756) {
      if (variant >= 22181) {
        if (variant >= 22186) {
          return param[1].toString() + "cm";
        } else {
          return param[1].toString() + "ch";
        }
      } else if (variant >= -119887163) {
        return param[1].toString() + "%";
      } else {
        return param[1].toString() + "vmin";
      }
    } else if (variant >= -977586742) {
      if (variant >= -833472530) {
        return param[1].toString() + "vmax";
      } else {
        return param[1].toString() + "in";
      }
    } else if (variant >= -1040484748) {
      return param[1].toString() + "px";
    } else {
      var match = param[1];
      if (match[0] >= 5745024) {
        return "calc(" + (toString$4(match[1]) + (" - " + (toString$4(match[2]) + ")")));
      } else {
        return "calc(" + (toString$4(match[1]) + (" + " + (toString$4(match[2]) + ")")));
      }
    }
  }
}

var Length = {
  ch: ch,
  em: em,
  ex: ex,
  rem: rem,
  vh: vh,
  vw: vw,
  vmin: vmin,
  vmax: vmax,
  px: px,
  pxFloat: pxFloat,
  cm: cm,
  mm: mm,
  inch: inch,
  pc: pc,
  pt: pt,
  zero: /* zero */-789508312,
  toString: toString$4
};

function deg(x) {
  return /* `deg */[
          4995526,
          x
        ];
}

function rad(x) {
  return /* `rad */[
          5690837,
          x
        ];
}

function grad(x) {
  return /* `grad */[
          -999567410,
          x
        ];
}

function turn(x) {
  return /* `turn */[
          -855250051,
          x
        ];
}

function toString$5(param) {
  var variant = param[0];
  if (variant >= 4995526) {
    if (variant >= 5690837) {
      return param[1].toString() + "rad";
    } else {
      return param[1].toString() + "deg";
    }
  } else if (variant >= -855250051) {
    return param[1].toString() + "turn";
  } else {
    return param[1].toString() + "grad";
  }
}

var Angle = {
  deg: deg,
  rad: rad,
  grad: grad,
  turn: turn,
  toString: toString$5
};

function toString$6(param) {
  if (param >= 5695082) {
    return "rtl";
  } else {
    return "ltr";
  }
}

var Direction = {
  ltr: /* ltr */5396714,
  rtl: /* rtl */5695082,
  toString: toString$6
};

function toString$7(param) {
  if (param !== 10615156) {
    if (param >= 188263721) {
      if (param >= 903134412) {
        return "relative";
      } else {
        return "sticky";
      }
    } else if (param >= 100392110) {
      return "static";
    } else {
      return "absolute";
    }
  } else {
    return "fixed";
  }
}

var Position = {
  absolute: /* absolute */-1013592457,
  relative: /* relative */903134412,
  $$static: /* static */100392110,
  fixed: /* fixed */10615156,
  sticky: /* sticky */188263721,
  toString: toString$7
};

function toString$8(param) {
  if (param >= -367674250) {
    if (param >= 423610969) {
      if (param >= 888960333) {
        return "block";
      } else {
        return "inline";
      }
    } else if (param >= 365165796) {
      return "horizontal";
    } else {
      return "vertical";
    }
  } else if (param >= -922086728) {
    return "none";
  } else {
    return "both";
  }
}

var Resize = {
  none: /* none */-922086728,
  both: /* both */-1055160191,
  horizontal: /* horizontal */365165796,
  vertical: /* vertical */-367674250,
  block: /* block */888960333,
  inline: /* inline */423610969,
  toString: toString$8
};

function toString$9(param) {
  if (param >= 812216871) {
    return "normal";
  } else {
    return "smallCaps";
  }
}

var FontVariant = {
  normal: /* normal */812216871,
  smallCaps: /* smallCaps */-386684792,
  toString: toString$9
};

function toString$10(param) {
  if (param !== 734792881) {
    if (param >= 812216871) {
      return "normal";
    } else {
      return "italic";
    }
  } else {
    return "oblique";
  }
}

var FontStyle = {
  normal: /* normal */812216871,
  italic: /* italic */107228912,
  oblique: /* oblique */734792881,
  toString: toString$10
};

function toString$11(param) {
  if (param >= -550577721) {
    if (param >= 60557045) {
      if (param >= 427265337) {
        return "content";
      } else {
        return "max-content";
      }
    } else if (param >= -195805336) {
      return "fit-content";
    } else {
      return "min-content";
    }
  } else if (param >= -1011102077) {
    return "fill";
  } else {
    return "auto";
  }
}

var FlexBasis = {
  fill: /* fill */-1011102077,
  content: /* content */427265337,
  maxContent: /* maxContent */60557045,
  minContent: /* minContent */-550577721,
  fitContent: /* fitContent */-195805336,
  toString: toString$11
};

function toString$12(param) {
  if (param >= -862584982) {
    if (param >= 589592690) {
      return "visible";
    } else {
      return "hidden";
    }
  } else if (param >= -949692403) {
    return "scroll";
  } else {
    return "auto";
  }
}

var Overflow = {
  hidden: /* hidden */-862584982,
  visible: /* visible */589592690,
  scroll: /* scroll */-949692403,
  auto: /* auto */-1065951377,
  toString: toString$12
};

function toString$13(param) {
  return "auto";
}

var Margin = {
  auto: /* auto */-1065951377,
  toString: toString$13
};

function toString$14(param) {
  if (param >= -15525083) {
    if (param >= 5693978) {
      return "row";
    } else {
      return "row dense";
    }
  } else if (param >= -227605271) {
    return "column dense";
  } else {
    return "column";
  }
}

var GridAutoFlow = {
  toString: toString$14
};

function toString$15(param) {
  return "normal";
}

var GridColumnGap = {
  toString: toString$15
};

function toString$16(param) {
  if (param >= 79692155) {
    if (param >= 476669816) {
      if (param >= 516406248) {
        return "text-top";
      } else {
        return "text-bottom";
      }
    } else if (param >= 287825029) {
      return "baseline";
    } else {
      return "super";
    }
  } else if (param >= 5745024) {
    if (param >= 5793429) {
      return "top";
    } else {
      return "sub";
    }
  } else if (param >= -445061397) {
    return "bottom";
  } else {
    return "middle";
  }
}

var VerticalAlign = {
  toString: toString$16
};

function steps(i, dir) {
  return /* `steps */[
          68058023,
          /* tuple */[
            i,
            dir
          ]
        ];
}

function cubicBezier(a, b, c, d) {
  return /* `cubicBezier */[
          -554785527,
          /* tuple */[
            a,
            b,
            c,
            d
          ]
        ];
}

function toString$17(param) {
  if (typeof param === "number") {
    if (param >= -193525386) {
      if (param >= 138027891) {
        if (param >= 715748672) {
          return "ease-out";
        } else {
          return "ease-in";
        }
      } else if (param >= 20933615) {
        return "step-end";
      } else {
        return "step-start";
      }
    } else if (param !== -960651557) {
      if (param >= -325037595) {
        return "linear";
      } else {
        return "ease";
      }
    } else {
      return "ease-in-out";
    }
  } else if (param[0] >= 68058023) {
    var match = param[1];
    var i = match[0];
    if (match[1] >= 67859554) {
      return "steps(" + (i.toString() + ", start)");
    } else {
      return "steps(" + (i.toString() + ", end)");
    }
  } else {
    var match$1 = param[1];
    return "cubic-bezier(" + (match$1[0].toString() + (", " + (match$1[1].toString() + (", " + (match$1[2].toString() + (", " + (match$1[3].toString() + ")")))))));
  }
}

function toString$18(param) {
  if (typeof param === "number") {
    if (param >= 652328338) {
      return "auto-fill";
    } else {
      return "auto-fit";
    }
  } else {
    return param[1].toString();
  }
}

var RepeatValue = {
  toString: toString$18
};

function toString$19(param) {
  if (param >= -484197732) {
    if (param >= 700345660) {
      if (param >= 787279419) {
        if (param >= 826920258) {
          return "upper-latin";
        } else {
          return "upper-roman";
        }
      } else if (param >= 739986499) {
        return "lower-latin";
      } else {
        return "lower-roman";
      }
    } else if (param >= -422333295) {
      return "decimal";
    } else {
      return "upper-alpha";
    }
  } else if (param >= -703761904) {
    if (param >= -655228771) {
      if (param >= -571131491) {
        return "lower-alpha";
      } else {
        return "square";
      }
    } else if (param >= -699686657) {
      return "lower-greek";
    } else {
      return "circle";
    }
  } else if (param >= -922086728) {
    return "none";
  } else {
    return "disc";
  }
}

var ListStyleType = {
  toString: toString$19
};

function toString$20(param) {
  if (param >= 501235708) {
    return "inside";
  } else {
    return "outside";
  }
}

var ListStylePosition = {
  toString: toString$20
};

function toString$21(param) {
  if (param >= 472095738) {
    if (param !== 568403505) {
      if (param >= 852175633) {
        if (param >= 1042283741) {
          return "inset";
        } else {
          return "double";
        }
      } else if (param >= 841979626) {
        return "dotted";
      } else {
        return "grove";
      }
    } else {
      return "dashed";
    }
  } else if (param !== -862584982) {
    if (param >= 12956715) {
      if (param >= 209930196) {
        return "outset";
      } else {
        return "solid";
      }
    } else if (param >= -379468757) {
      return "ridge";
    } else {
      return "none";
    }
  } else {
    return "hidden";
  }
}

var OutlineStyle = {
  toString: toString$21
};

function toString$22(x) {
  if (typeof x === "number") {
    if (x >= -81801163) {
      if (x >= -20425611) {
        if (x >= 812216871) {
          if (x >= 888264127) {
            return "900";
          } else {
            return "400";
          }
        } else if (x >= 434326211) {
          return "lighter";
        } else {
          return "500";
        }
      } else if (x >= -21160922) {
        return "200";
      } else {
        return "800";
      }
    } else if (x !== -855898535) {
      if (x >= -534575822) {
        if (x >= -184774442) {
          return "300";
        } else {
          return "bolder";
        }
      } else if (x >= -812709613) {
        return "600";
      } else {
        return "700";
      }
    } else {
      return "100";
    }
  } else {
    return x[1].toString();
  }
}

var FontWeight = {
  thin: /* thin */-855898535,
  extraLight: /* extraLight */-21160922,
  light: /* light */-184774442,
  medium: /* medium */-20425611,
  semiBold: /* semiBold */-812709613,
  bold: /* bold */-1055161979,
  extraBold: /* extraBold */-81801163,
  lighter: /* lighter */434326211,
  bolder: /* bolder */-534575822,
  toString: toString$22
};

function translate(x, y) {
  return /* `translate */[
          -914368690,
          /* tuple */[
            x,
            y
          ]
        ];
}

function translate3d(x, y, z) {
  return /* `translate3d */[
          178189215,
          /* tuple */[
            x,
            y,
            z
          ]
        ];
}

function translateX(x) {
  return /* `translateX */[
          106728778,
          x
        ];
}

function translateY(y) {
  return /* `translateY */[
          106728779,
          y
        ];
}

function translateZ(z) {
  return /* `translateZ */[
          106728780,
          z
        ];
}

function scale(x, y) {
  return /* `scale */[
          -120664438,
          /* tuple */[
            x,
            y
          ]
        ];
}

function scale3d(x, y, z) {
  return /* `scale3d */[
          -452513317,
          /* tuple */[
            x,
            y,
            z
          ]
        ];
}

function scaleX(x) {
  return /* `scaleX */[
          1009117838,
          x
        ];
}

function scaleY(x) {
  return /* `scaleY */[
          1009117839,
          x
        ];
}

function scaleZ(x) {
  return /* `scaleZ */[
          1009117840,
          x
        ];
}

function rotate(a) {
  return /* `rotate */[
          -887077285,
          a
        ];
}

function rotate3d(x, y, z, a) {
  return /* `rotate3d */[
          142802924,
          /* tuple */[
            x,
            y,
            z,
            a
          ]
        ];
}

function rotateX(a) {
  return /* `rotateX */[
          -249738851,
          a
        ];
}

function rotateY(a) {
  return /* `rotateY */[
          -249738850,
          a
        ];
}

function rotateZ(a) {
  return /* `rotateZ */[
          -249738849,
          a
        ];
}

function skew(a, a$prime) {
  return /* `skew */[
          -866839798,
          /* tuple */[
            a,
            a$prime
          ]
        ];
}

function skewX(a) {
  return /* `skewX */[
          -31746546,
          a
        ];
}

function skewY(a) {
  return /* `skewY */[
          -31746545,
          a
        ];
}

function toString$23(param) {
  var variant = param[0];
  if (variant >= -120664438) {
    if (variant >= 106728778) {
      if (variant >= 178189215) {
        if (variant >= 1009117838) {
          switch (variant - 1009117838 | 0) {
            case 0 :
                return "scaleX(" + (param[1].toString() + ")");
            case 1 :
                return "scaleY(" + (param[1].toString() + ")");
            case 2 :
                return "scaleZ(" + (param[1].toString() + ")");
            
          }
        } else {
          var match = param[1];
          var x = match[0];
          var y = match[1];
          var z = match[2];
          return "translate3d(" + (toString$4(x) + (", " + (toString$4(y) + (", " + (toString$4(z) + ")")))));
        }
      } else if (variant >= 142802924) {
        var match$1 = param[1];
        return "rotate3d(" + (match$1[0].toString() + (", " + (match$1[1].toString() + (", " + (match$1[2].toString() + (", " + (toString$5(match$1[3]) + ")")))))));
      } else {
        switch (variant - 106728778 | 0) {
          case 0 :
              return "translateX(" + (toString$4(param[1]) + ")");
          case 1 :
              return "translateY(" + (toString$4(param[1]) + ")");
          case 2 :
              return "translateZ(" + (toString$4(param[1]) + ")");
          
        }
      }
    } else if (variant !== -31746546) {
      if (variant >= -31746545) {
        return "skewY(" + (toString$5(param[1]) + ")");
      } else {
        var match$2 = param[1];
        var x$1 = match$2[0];
        var y$1 = match$2[1];
        return "scale(" + (x$1.toString() + (", " + (y$1.toString() + ")")));
      }
    } else {
      return "skewX(" + (toString$5(param[1]) + ")");
    }
  } else if (variant >= -887077285) {
    if (variant >= -452513317) {
      if (variant >= -249738851) {
        switch (variant + 249738851 | 0) {
          case 0 :
              return "rotateX(" + (toString$5(param[1]) + ")");
          case 1 :
              return "rotateY(" + (toString$5(param[1]) + ")");
          case 2 :
              return "rotateZ(" + (toString$5(param[1]) + ")");
          
        }
      } else {
        var match$3 = param[1];
        return "scale3d(" + (match$3[0].toString() + (", " + (match$3[1].toString() + (", " + (match$3[2].toString() + ")")))));
      }
    } else if (variant >= -866839798) {
      var match$4 = param[1];
      return "skew(" + (toString$5(match$4[0]) + (", " + (toString$5(match$4[1]) + ")")));
    } else {
      return "rotate(" + (toString$5(param[1]) + ")");
    }
  } else if (variant >= -914368690) {
    var match$5 = param[1];
    return "translate(" + (toString$4(match$5[0]) + (", " + (toString$4(match$5[1]) + ")")));
  } else {
    return "perspective(" + (param[1].toString() + ")");
  }
}

function toString$24(param) {
  if (param >= 812216871) {
    if (param >= 892586298) {
      return "alternate";
    } else {
      return "normal";
    }
  } else if (param >= 545428232) {
    return "alternate-reverse";
  } else {
    return "reverse";
  }
}

var AnimationDirection = {
  toString: toString$24
};

function toString$25(param) {
  if (param >= 245861168) {
    if (param >= 733653774) {
      return "forwards";
    } else {
      return "backwards";
    }
  } else if (param >= -922086728) {
    return "none";
  } else {
    return "both";
  }
}

var AnimationFillMode = {
  toString: toString$25
};

function toString$26(param) {
  if (typeof param === "number") {
    return "infinite";
  } else {
    return param[1].toString();
  }
}

var AnimationIterationCount = {
  toString: toString$26
};

function toString$27(param) {
  if (param >= -276545362) {
    return "paused";
  } else {
    return "running";
  }
}

var AnimationPlayState = {
  toString: toString$27
};

function toString$28(x) {
  if (x >= 82328943) {
    if (x >= 477181167) {
      if (x >= 624364317) {
        if (x !== 626862894) {
          if (x >= 939907157) {
            if (x >= 951366027) {
              return "ne-resize";
            } else {
              return "not-allowed";
            }
          } else if (x >= 676920916) {
            return "col-resize";
          } else {
            return "nw-resize";
          }
        } else {
          return "context-menu";
        }
      } else if (x >= 577832966) {
        if (x >= 621258809) {
          return "e-resize";
        } else {
          return "ew-resize";
        }
      } else if (x >= 563171728) {
        return "alias";
      } else {
        return "nesw-resize";
      }
    } else if (x >= 302348560) {
      if (x !== 365450254) {
        if (x >= 457757954) {
          if (x >= 465819841) {
            return "default";
          } else {
            return "n-resize";
          }
        } else if (x >= 381626435) {
          return "vertical-text";
        } else {
          return "no-drop";
        }
      } else {
        return "all-scroll";
      }
    } else if (x >= 180897442) {
      if (x >= 294257099) {
        return "w-resize";
      } else {
        return "crosshair";
      }
    } else if (x >= 103479213) {
      return "progress";
    } else {
      return "nwse-resize";
    }
  } else if (x >= -840286290) {
    if (x >= -459627717) {
      if (x !== -348903737) {
        if (x >= -19620980) {
          if (x >= -18796519) {
            return "ns-resize";
          } else {
            return "grabbing";
          }
        } else if (x >= -88732200) {
          return "zoom-in";
        } else {
          return "zoom-out";
        }
      } else {
        return "s-resize";
      }
    } else if (x >= -786317123) {
      if (x >= -693048282) {
        return "se-resize";
      } else {
        return "pointer";
      }
    } else if (x >= -822977931) {
      return "wait";
    } else {
      return "row-resize";
    }
  } else if (x >= -999567412) {
    if (x !== -989121855) {
      if (x >= -922086728) {
        if (x >= -856044371) {
          return "text";
        } else {
          return "none";
        }
      } else if (x >= -933174511) {
        return "move";
      } else {
        return "grab";
      }
    } else {
      return "help";
    }
  } else if (x >= -1044071499) {
    if (x >= -1020049992) {
      return "sw-resize";
    } else {
      return "copy";
    }
  } else if (x >= -1044569694) {
    return "cell";
  } else {
    return "auto";
  }
}

var Cursor = {
  auto: /* auto */-1065951377,
  $$default: /* default */465819841,
  none: /* none */-922086728,
  contextMenu: /* contextMenu */626862894,
  help: /* help */-989121855,
  pointer: /* pointer */-786317123,
  progress: /* progress */103479213,
  wait: /* wait */-822977931,
  cell: /* cell */-1044569694,
  crosshair: /* crosshair */180897442,
  text: /* text */-856044371,
  verticalText: /* verticalText */381626435,
  alias: /* alias */563171728,
  copy: /* copy */-1044071499,
  move: /* move */-933174511,
  noDrop: /* noDrop */302348560,
  notAllowed: /* notAllowed */939907157,
  grab: /* grab */-999567412,
  grabbing: /* grabbing */-19620980,
  allScroll: /* allScroll */365450254,
  colResize: /* colResize */676920916,
  rowResize: /* rowResize */-840286290,
  nResize: /* nResize */457757954,
  eResize: /* eResize */621258809,
  sResize: /* sResize */-348903737,
  wResize: /* wResize */294257099,
  neResize: /* neResize */951366027,
  nwResize: /* nwResize */624364317,
  seResize: /* seResize */-693048282,
  swResize: /* swResize */-1020049992,
  ewResize: /* ewResize */577832966,
  nsResize: /* nsResize */-18796519,
  neswResize: /* neswResize */477181167,
  nwseResize: /* nwseResize */82328943,
  zoomIn: /* zoomIn */-88732200,
  zoomOut: /* zoomOut */-459627717,
  toString: toString$28
};

function rgb(r, g, b) {
  return /* `rgb */[
          5692173,
          /* tuple */[
            r,
            g,
            b
          ]
        ];
}

function rgba(r, g, b, a) {
  return /* `rgba */[
          -878128972,
          /* tuple */[
            r,
            g,
            b,
            a
          ]
        ];
}

function hsl(h, s, l) {
  return /* `hsl */[
          5197569,
          /* tuple */[
            h,
            /* `percent */[
              -119887163,
              s
            ],
            /* `percent */[
              -119887163,
              l
            ]
          ]
        ];
}

function hsla(h, s, l, a) {
  return /* `hsla */[
          -988425664,
          /* tuple */[
            h,
            /* `percent */[
              -119887163,
              s
            ],
            /* `percent */[
              -119887163,
              l
            ],
            a
          ]
        ];
}

function hex(x) {
  return /* `hex */[
          5194459,
          x
        ];
}

function string_of_alpha(param) {
  if (param[0] >= 5496390) {
    return param[1].toString();
  } else {
    return param[1].toString() + "%";
  }
}

function string_of_percent(param) {
  return param[1].toString() + "%";
}

function toString$29(param) {
  if (typeof param === "number") {
    if (param >= 582626130) {
      return "transparent";
    } else {
      return "currentColor";
    }
  } else {
    var variant = param[0];
    if (variant !== -878128972) {
      if (variant >= 5197569) {
        if (variant >= 5692173) {
          var match = param[1];
          return "rgb(" + (match[0].toString() + (", " + (match[1].toString() + (", " + (match[2].toString() + ")")))));
        } else {
          var match$1 = param[1];
          return "hsl(" + (toString$5(match$1[0]) + (", " + (string_of_percent(match$1[1]) + (", " + (string_of_percent(match$1[2]) + ")")))));
        }
      } else if (variant >= 5194459) {
        return "#" + param[1];
      } else {
        var match$2 = param[1];
        return "hsla(" + (toString$5(match$2[0]) + (", " + (string_of_percent(match$2[1]) + (", " + (string_of_percent(match$2[2]) + (", " + (string_of_alpha(match$2[3]) + ")")))))));
      }
    } else {
      var match$3 = param[1];
      return "rgba(" + (match$3[0].toString() + (", " + (match$3[1].toString() + (", " + (match$3[2].toString() + (", " + (match$3[3].toString() + ")")))))));
    }
  }
}

function toString$30(param) {
  if (param >= 472095738) {
    if (param !== 568403505) {
      if (param >= 852175633) {
        if (param >= 1042283741) {
          return "inset";
        } else {
          return "double";
        }
      } else if (param >= 841979626) {
        return "dotted";
      } else {
        return "groove";
      }
    } else {
      return "dashed";
    }
  } else if (param !== -862584982) {
    if (param >= 12956715) {
      if (param >= 209930196) {
        return "outset";
      } else {
        return "solid";
      }
    } else if (param >= -379468757) {
      return "ridge";
    } else {
      return "none";
    }
  } else {
    return "hidden";
  }
}

var BorderStyle = {
  toString: toString$30
};

function toString$31(param) {
  if (param >= -922086728) {
    return "none";
  } else {
    return "auto";
  }
}

var PointerEvents = {
  toString: toString$31
};

function toString$32(param) {
  return "none";
}

var Perspective = {
  toString: toString$32
};

function toString$33(param) {
  return "normal";
}

var LetterSpacing = {
  normal: /* normal */812216871,
  toString: toString$33
};

function toString$34(param) {
  if (typeof param === "number") {
    return "normal";
  } else {
    return param[1].toString();
  }
}

var LineHeight = {
  toString: toString$34
};

function toString$35(param) {
  return "normal";
}

var WordSpacing = {
  toString: toString$35
};

function toString$36(param) {
  if (param !== 423610969) {
    if (param >= 888960333) {
      return "block";
    } else {
      return "run-in";
    }
  } else {
    return "inline";
  }
}

var DisplayOutside = {
  toString: toString$36
};

function toString$37(param) {
  if (param !== -999565626) {
    if (param >= 182695950) {
      return "table";
    } else {
      return "flex";
    }
  } else {
    return "grid";
  }
}

var DisplayInside = {
  toString: toString$37
};

function toString$38(param) {
  return "list-item";
}

var DisplayListItem = {
  toString: toString$38
};

function toString$39(param) {
  if (param >= 606419204) {
    if (param >= 850209563) {
      if (param >= 1054826616) {
        return "table-caption";
      } else {
        return "table-column-group";
      }
    } else if (param >= 793912528) {
      return "table-cell";
    } else {
      return "table-header-group";
    }
  } else if (param >= -836725517) {
    if (param >= 97122692) {
      return "table-column";
    } else {
      return "table-row-group";
    }
  } else if (param >= -843129172) {
    return "table-row";
  } else {
    return "table-footer-group";
  }
}

var DisplayInternal = {
  toString: toString$39
};

function toString$40(param) {
  if (param >= 790889754) {
    return "contents";
  } else {
    return "none";
  }
}

var DisplayBox = {
  toString: toString$40
};

function toString$41(param) {
  if (param >= 53323314) {
    if (param >= 64712127) {
      return "inline-grid";
    } else {
      return "inline-flex";
    }
  } else if (param >= -147785676) {
    return "inline-block";
  } else {
    return "inline-table";
  }
}

var DisplayLegacy = {
  toString: toString$41
};

function toString$42(param) {
  if (param !== -162316795) {
    if (param >= 812216871) {
      return "normal";
    } else {
      return "auto";
    }
  } else {
    return "stretch";
  }
}

var JustifySelf = {
  toString: toString$42
};

function toString$43(param) {
  if (param >= 67859554) {
    if (param !== 98248149) {
      if (param >= 924268066) {
        if (param >= 1040645430) {
          return "self-start";
        } else {
          return "flex-end";
        }
      } else if (param >= 662439529) {
        return "flex-start";
      } else {
        return "start";
      }
    } else {
      return "center";
    }
  } else if (param >= -379319332) {
    if (param >= 51834799) {
      return "self-end";
    } else {
      return "right";
    }
  } else if (param >= -944764921) {
    return "left";
  } else {
    return "end";
  }
}

var PositionalAlignment = {
  toString: toString$43
};

function toString$44(param) {
  return "baseline";
}

var BaselineAlignment = {
  toString: toString$44
};

function toString$45(param) {
  return "normal";
}

var NormalAlignment = {
  toString: toString$45
};

function toString$46(param) {
  if (param >= -162316795) {
    if (param >= 516682146) {
      return "space-between";
    } else {
      return "stretch";
    }
  } else if (param >= -485895757) {
    return "space-around";
  } else {
    return "space-evenly";
  }
}

var DistributedAlignment = {
  toString: toString$46
};

function toString$47(param) {
  if (param >= 62250832) {
    if (param >= 98248149) {
      return "center";
    } else {
      return "justify";
    }
  } else if (param >= -379319332) {
    return "right";
  } else {
    return "left";
  }
}

var TextAlign = {
  toString: toString$47
};

function toString$48(param) {
  if (param !== 812216871) {
    if (param >= 892748188) {
      return "keep-all";
    } else {
      return "break-all";
    }
  } else {
    return "normal";
  }
}

var WordBreak = {
  toString: toString$48
};

function toString$49(param) {
  if (param >= 660870029) {
    if (param >= 812216871) {
      if (param >= 867913355) {
        return "nowrap";
      } else {
        return "normal";
      }
    } else if (param >= 705417996) {
      return "break-spaces";
    } else {
      return "pre-wrap";
    }
  } else if (param >= 538440119) {
    return "pre-line";
  } else {
    return "pre";
  }
}

var WhiteSpace = {
  toString: toString$49
};

function toString$50(param) {
  if (param >= 812216871) {
    return "normal";
  } else {
    return "stretch";
  }
}

var AlignItems = {
  toString: toString$50
};

function toString$51(param) {
  if (param >= 812216871) {
    return "normal";
  } else {
    return "auto";
  }
}

var AlignSelf = {
  toString: toString$51
};

function toString$52(param) {
  if (param !== 67859554) {
    if (param >= 662439529) {
      if (param >= 924268066) {
        return "flex-end";
      } else {
        return "flex-start";
      }
    } else if (param >= 98248149) {
      return "center";
    } else {
      return "end";
    }
  } else {
    return "start";
  }
}

var AlignContent = {
  toString: toString$52
};

function toString$53(param) {
  if (param !== -922086728) {
    if (param >= 427065300) {
      if (param >= 635502604) {
        return "scale-down";
      } else {
        return "contain";
      }
    } else if (param >= -899416265) {
      return "cover";
    } else {
      return "fill";
    }
  } else {
    return "none";
  }
}

var ObjectFit = {
  toString: toString$53
};

function toString$54(param) {
  if (param >= -944764921) {
    if (param >= -379319332) {
      if (param >= 943927042) {
        return "inline-end";
      } else {
        return "right";
      }
    } else if (param >= -922086728) {
      return "none";
    } else {
      return "left";
    }
  } else if (param >= -968886455) {
    return "inline-start";
  } else {
    return "both";
  }
}

var Clear = {
  toString: toString$54
};

function toString$55(param) {
  if (param !== -944764921) {
    if (param >= -379319332) {
      if (param >= 943927042) {
        return "inline-end";
      } else {
        return "right";
      }
    } else if (param >= -922086728) {
      return "none";
    } else {
      return "inline-start";
    }
  } else {
    return "left";
  }
}

var Float = {
  toString: toString$55
};

function toString$56(param) {
  if (param !== -862584982) {
    if (param >= 589592690) {
      return "visible";
    } else {
      return "collapse";
    }
  } else {
    return "hidden";
  }
}

var Visibility = {
  toString: toString$56
};

function toString$57(param) {
  if (param >= 10615156) {
    return "fixed";
  } else {
    return "auto";
  }
}

var TableLayout = {
  toString: toString$57
};

function toString$58(param) {
  if (param >= 119283555) {
    return "separate";
  } else {
    return "collapse";
  }
}

var BorderCollapse = {
  toString: toString$58
};

function toString$59(param) {
  if (param !== -463121288) {
    if (param >= 867913355) {
      return "nowrap";
    } else {
      return "wrap";
    }
  } else {
    return "wrap-reverse";
  }
}

var FlexWrap = {
  toString: toString$59
};

function toString$60(param) {
  if (param >= 135477484) {
    if (param >= 378951208) {
      return "row-reverse";
    } else {
      return "column-reverse";
    }
  } else if (param >= 5693978) {
    return "row";
  } else {
    return "column";
  }
}

var FlexDirection = {
  toString: toString$60
};

function toString$61(param) {
  if (param >= 9307263) {
    return "border-box";
  } else {
    return "content-box";
  }
}

var BoxSizing = {
  toString: toString$61
};

function toString$62(param) {
  if (typeof param === "number") {
    return "auto";
  } else {
    return param[1].toString();
  }
}

var ColumnCount = {
  toString: toString$62
};

function toString$63(param) {
  if (param !== -922086728) {
    if (param >= 4847905) {
      if (param >= 427065300) {
        return "contain";
      } else {
        return "all";
      }
    } else if (param >= -856044371) {
      return "text";
    } else {
      return "auto";
    }
  } else {
    return "none";
  }
}

var UserSelect = {
  toString: toString$63
};

function toString$64(param) {
  if (param >= -425349839) {
    if (param >= -415330030) {
      return "uppercase";
    } else {
      return "lowercase";
    }
  } else if (param >= -509867604) {
    return "capitalize";
  } else {
    return "none";
  }
}

var TextTransform = {
  toString: toString$64
};

function areas(x) {
  return /* `areas */[
          629510214,
          x
        ];
}

function toString$65(param) {
  if (typeof param === "number") {
    return "none";
  } else {
    return $$String.trim(List.fold_left((function (carry, elem) {
                      return carry + ("'" + (elem + "' "));
                    }), "", param[1]));
  }
}

var GridTemplateAreas = {
  areas: areas,
  toString: toString$65
};

function ident(x) {
  return /* `ident */[
          930693872,
          x
        ];
}

function num(x) {
  return /* `num */[
          5496390,
          x
        ];
}

function numIdent(x, y) {
  return /* `numIdent */[
          -192325942,
          /* tuple */[
            x,
            y
          ]
        ];
}

function span(x) {
  return /* `span */[
          -866592054,
          x
        ];
}

function toString$66(t) {
  if (typeof t === "number") {
    return "auto";
  } else {
    var variant = t[0];
    if (variant >= 5496390) {
      if (variant >= 930693872) {
        return t[1];
      } else {
        return String(t[1]);
      }
    } else if (variant >= -192325942) {
      var match = t[1];
      return String(match[0]) + (" " + match[1]);
    } else {
      var e = t[1];
      return "span " + (
              e[0] >= 930693872 ? e[1] : String(e[1])
            );
    }
  }
}

var GridArea = {
  auto: /* auto */-1065951377,
  ident: ident,
  num: num,
  numIdent: numIdent,
  span: span,
  toString: toString$66
};

function toString$67(param) {
  if (typeof param === "number") {
    return "none";
  } else {
    var variant = param[0];
    if (variant >= -97740042) {
      if (variant !== -29929489) {
        if (variant >= 934407426) {
          if (variant >= 1036870963) {
            var h = param[1];
            if (typeof h === "number") {
              return "hue-rotate(0deg)";
            } else {
              return "hue-rotate(" + (toString$5(h) + ")");
            }
          } else {
            var match = param[1];
            if (match[0] >= 5496390) {
              return "contrast(" + (String(match[1]) + ")");
            } else {
              return "contrast(" + (match[1].toString() + "%)");
            }
          }
        } else if (variant >= 534308630) {
          var match$1 = param[1];
          if (match$1[0] >= 5496390) {
            return "invert(" + (String(match$1[1]) + ")");
          } else {
            return "invert(" + (match$1[1].toString() + "%)");
          }
        } else {
          var match$2 = param[1];
          if (match$2[0] >= 5496390) {
            return "sepia(" + (String(match$2[1]) + ")");
          } else {
            return "sepia(" + (match$2[1].toString() + "%)");
          }
        }
      } else {
        var match$3 = param[1];
        if (match$3[0] >= 5496390) {
          return "saturate(" + (String(match$3[1]) + ")");
        } else {
          return "saturate(" + (match$3[1].toString() + "%)");
        }
      }
    } else if (variant !== -594525841) {
      if (variant >= -345678389) {
        if (variant >= -214464569) {
          var match$4 = param[1];
          if (match$4[0] >= 5496390) {
            return "grayscale(" + (String(match$4[1]) + ")");
          } else {
            return "grayscale(" + (match$4[1].toString() + "%)");
          }
        } else {
          var match$5 = param[1];
          if (match$5[0] >= 5496390) {
            return "opacity(" + (String(match$5[1]) + ")");
          } else {
            return "opacity(" + (match$5[1].toString() + "%)");
          }
        }
      } else if (variant >= -445542959) {
        var match$6 = param[1];
        if (match$6[0] >= 5496390) {
          return "brightness(" + (String(match$6[1]) + ")");
        } else {
          return "brightness(" + (match$6[1].toString() + "%)");
        }
      } else {
        return "blur(" + (toString$4(param[1]) + ")");
      }
    } else {
      var match$7 = param[1];
      if (match$7[0] >= 5496390) {
        return "drop-shadow(" + (String(match$7[1]) + ")");
      } else {
        return "drop-shadow(" + (match$7[1].toString() + "%)");
      }
    }
  }
}

function toString$68(param) {
  if (param !== -118437525) {
    if (param >= 10615156) {
      return "fixed";
    } else {
      return "scroll";
    }
  } else {
    return "local";
  }
}

var BackgroundAttachment = {
  toString: toString$68
};

function toString$69(param) {
  if (param !== 9307263) {
    if (param >= 972575930) {
      return "padding-box";
    } else {
      return "content-box";
    }
  } else {
    return "border-box";
  }
}

var BackgroundClip = {
  toString: toString$69
};

function toString$70(param) {
  if (param !== 9307263) {
    if (param >= 972575930) {
      return "padding-box";
    } else {
      return "content-box";
    }
  } else {
    return "border-box";
  }
}

var BackgroundOrigin = {
  toString: toString$70
};

function toString$71(param) {
  if (param !== -379319332) {
    if (param >= 98248149) {
      return "center";
    } else {
      return "left";
    }
  } else {
    return "right";
  }
}

var X = {
  toString: toString$71
};

function toString$72(param) {
  if (param !== 5793429) {
    if (param >= 98248149) {
      return "center";
    } else {
      return "bottom";
    }
  } else {
    return "top";
  }
}

var Y = {
  toString: toString$72
};

function toString$73(param) {
  if (param !== -445061397) {
    if (param >= 5793429) {
      if (param >= 98248149) {
        return "center";
      } else {
        return "top";
      }
    } else if (param >= -379319332) {
      return "right";
    } else {
      return "left";
    }
  } else {
    return "bottom";
  }
}

var BackgroundPosition = {
  X: X,
  Y: Y,
  toString: toString$73
};

function toString$74(param) {
  if (param >= 23497926) {
    if (param >= 646437021) {
      if (param >= 646437022) {
        return "repeat-y";
      } else {
        return "repeat-x";
      }
    } else if (param >= 108828507) {
      return "repeat";
    } else {
      return "space";
    }
  } else if (param >= -312084402) {
    return "round";
  } else {
    return "no-repeat";
  }
}

var BackgroundRepeat = {
  toString: toString$74
};

function toString$75(param) {
  if (typeof param === "number") {
    if (param >= 166888785) {
      return "ellipsis";
    } else {
      return "clip";
    }
  } else {
    return param[1];
  }
}

var TextOverflow = {
  toString: toString$75
};

function toString$76(param) {
  if (param !== -922086728) {
    if (param >= 412155569) {
      if (param >= 888664412) {
        return "blink";
      } else {
        return "line-through";
      }
    } else if (param >= 131142924) {
      return "underline";
    } else {
      return "overline";
    }
  } else {
    return "none";
  }
}

var TextDecorationLine = {
  toString: toString$76
};

function toString$77(param) {
  if (param !== 12956715) {
    if (param >= 841979626) {
      if (param >= 852175633) {
        return "double";
      } else {
        return "dotted";
      }
    } else if (param >= 568403505) {
      return "dashed";
    } else {
      return "wavy";
    }
  } else {
    return "solid";
  }
}

var TextDecorationStyle = {
  toString: toString$77
};

function toString$78(param) {
  if (param >= -195805336) {
    return "fit-content";
  } else {
    return "auto";
  }
}

var Width = {
  toString: toString$78
};

function toString$79(param) {
  return "none";
}

var MaxWidth = {
  toString: toString$79
};

function toString$80(param) {
  return "auto";
}

var Height = {
  toString: toString$80
};

function toString$81(param) {
  return "none";
}

var MaxHeight = {
  toString: toString$81
};

function toString$82(param) {
  if (param !== 893332859) {
    if (param >= 1059921449) {
      return "break-word";
    } else {
      return "normal";
    }
  } else {
    return "anywhere";
  }
}

var OverflowWrap = {
  toString: toString$82
};

function linearGradient(angle, stops) {
  return /* `linearGradient */[
          616379637,
          /* tuple */[
            angle,
            stops
          ]
        ];
}

function repeatingLinearGradient(angle, stops) {
  return /* `repeatingLinearGradient */[
          -160280644,
          /* tuple */[
            angle,
            stops
          ]
        ];
}

function radialGradient(stops) {
  return /* `radialGradient */[
          119548911,
          stops
        ];
}

function repeatingRadialGradient(stops) {
  return /* `repeatingRadialGradient */[
          -657111370,
          stops
        ];
}

function string_of_stops(stops) {
  var strings = Belt_List.map(stops, (function (param) {
          return toString$29(param[1]) + (" " + toString$4(param[0]));
        }));
  var separator = ", ";
  var _strings = strings;
  var _acc = "";
  while(true) {
    var acc = _acc;
    var strings$1 = _strings;
    if (strings$1) {
      var xs = strings$1[1];
      var x = strings$1[0];
      if (xs) {
        _acc = acc + (x + separator);
        _strings = xs;
        continue ;
      } else {
        return acc + x;
      }
    } else {
      return acc;
    }
  };
}

function toString$83(param) {
  var variant = param[0];
  if (variant >= 119548911) {
    if (variant >= 616379637) {
      var match = param[1];
      return "linear-gradient(" + (toString$5(match[0]) + (", " + (string_of_stops(match[1]) + ")")));
    } else {
      return "radial-gradient(" + (string_of_stops(param[1]) + ")");
    }
  } else if (variant >= -160280644) {
    var match$1 = param[1];
    return "repeating-linear-gradient(" + (toString$5(match$1[0]) + (", " + (string_of_stops(match$1[1]) + ")")));
  } else {
    return "repeating-radial-gradient(" + (string_of_stops(param[1]) + ")");
  }
}

function toString$84(param) {
  return "none";
}

var BackgroundImage = {
  toString: toString$84
};

function toString$85(param) {
  if (param >= 9307263) {
    if (param >= 481366182) {
      if (param >= 972575930) {
        return "padding-box";
      } else {
        return "view-box";
      }
    } else if (param >= 65166152) {
      return "fill-box";
    } else {
      return "border-box";
    }
  } else if (param !== -298658179) {
    if (param >= -229710541) {
      return "stroke-box";
    } else {
      return "content-box";
    }
  } else {
    return "margin-box";
  }
}

var GeometyBox = {
  marginBox: /* marginBox */-298658179,
  borderBox: /* borderBox */9307263,
  paddingBox: /* paddingBox */972575930,
  contentBox: /* contentBox */-1008848302,
  fillBox: /* fillBox */65166152,
  strokeBox: /* strokeBox */-229710541,
  viewBox: /* viewBox */481366182,
  toString: toString$85
};

function toString$86(param) {
  return "none";
}

var ClipPath = {
  toString: toString$86
};

function toString$87(param) {
  if (param >= 589592690) {
    return "visible";
  } else {
    return "hidden";
  }
}

var BackfaceVisibility = {
  toString: toString$87
};

function toString$88(param) {
  if (param !== -922086728) {
    if (param >= -878767996) {
      return "initial";
    } else {
      return "auto";
    }
  } else {
    return "none";
  }
}

var Flex = {
  toString: toString$88
};

function toString$89(param) {
  if (param >= 589702045) {
    return "preserve-3d";
  } else {
    return "flat";
  }
}

var TransformStyle = {
  toString: toString$89
};

function toString$90(param) {
  return "none";
}

var ListStyleImage = {
  toString: toString$90
};

function toString$91(param) {
  if (typeof param === "number") {
    if (param >= -504775901) {
      if (param !== -270962810) {
        if (param >= -46800986) {
          if (param >= 563809289) {
            return "fangsong";
          } else {
            return "fantasy";
          }
        } else if (param >= -97640579) {
          return "serif";
        } else {
          return "system-ui";
        }
      } else {
        return "emoji";
      }
    } else if (param >= -718373334) {
      if (param >= -555363901) {
        return "monospace";
      } else {
        return "sans-serif";
      }
    } else if (param >= -862985531) {
      return "cursive";
    } else {
      return "math";
    }
  } else {
    return param[1];
  }
}

function toString$92(param) {
  if (param !== -866243949) {
    if (param >= 714993698) {
      if (param >= 888960333) {
        return "block";
      } else {
        return "fallback";
      }
    } else if (param >= 510570400) {
      return "optional";
    } else {
      return "auto";
    }
  } else {
    return "swap";
  }
}

var toString$93 = toString$19;

var CounterStyleType = {
  toString: toString$93
};

function counter(styleOpt, name) {
  var style = styleOpt !== undefined ? styleOpt : /* unset */653193961;
  return /* `counter */[
          492416764,
          /* tuple */[
            name,
            style
          ]
        ];
}

function toString$94(param) {
  var match = param[1];
  var style = match[1];
  var counter = match[0];
  if (style !== 653193961) {
    return "counter(" + (counter + ("," + (toString$19(style) + ")")));
  } else {
    return "counter(" + (counter + ")");
  }
}

var Counter = {
  counter: counter,
  toString: toString$94
};

function counters(styleOpt, separatorOpt, name) {
  var style = styleOpt !== undefined ? styleOpt : /* unset */653193961;
  var separator = separatorOpt !== undefined ? separatorOpt : "";
  return /* `counters */[
          287272439,
          /* tuple */[
            name,
            separator,
            style
          ]
        ];
}

function toString$95(param) {
  var match = param[1];
  var style = match[2];
  var separator = match[1];
  var name = match[0];
  if (style !== 653193961) {
    return "counters(" + (name + (",\"" + (separator + ("\"," + (toString$19(style) + ")")))));
  } else {
    return "counters(" + (name + (",\"" + (separator + "\")")));
  }
}

var Counters = {
  counters: counters,
  toString: toString$95
};

function increment(valueOpt, name) {
  var value = valueOpt !== undefined ? valueOpt : 1;
  return /* `increment */[
          838796847,
          /* tuple */[
            name,
            value
          ]
        ];
}

function toString$96(param) {
  if (typeof param === "number") {
    return "none";
  } else {
    var match = param[1];
    return match[0] + (" " + String(match[1]));
  }
}

var CounterIncrement = {
  increment: increment,
  toString: toString$96
};

function reset(valueOpt, name) {
  var value = valueOpt !== undefined ? valueOpt : 0;
  return /* `reset */[
          -423081521,
          /* tuple */[
            name,
            value
          ]
        ];
}

function toString$97(param) {
  if (typeof param === "number") {
    return "none";
  } else {
    var match = param[1];
    return match[0] + (" " + String(match[1]));
  }
}

var CounterReset = {
  reset: reset,
  toString: toString$97
};

function set(valueOpt, name) {
  var value = valueOpt !== undefined ? valueOpt : 0;
  return /* `set */[
          5741474,
          /* tuple */[
            name,
            value
          ]
        ];
}

function toString$98(param) {
  if (typeof param === "number") {
    return "none";
  } else {
    var match = param[1];
    return match[0] + (" " + String(match[1]));
  }
}

var CounterSet = {
  set: set,
  toString: toString$98
};

function toString$99(param) {
  if (typeof param === "number") {
    if (param >= -786984174) {
      if (param >= 812216871) {
        if (param >= 851870353) {
          return "no-open-quote";
        } else {
          return "normal";
        }
      } else if (param >= 684949604) {
        return "close-quote";
      } else {
        return "open-quote";
      }
    } else if (param >= -922086728) {
      return "none";
    } else {
      return "no-close-quote";
    }
  } else if (param[0] >= -856044371) {
    return "\"" + (String(param[1]) + "\"");
  } else {
    return "attr(" + (param[1] + ")");
  }
}

var Content = {
  toString: toString$99
};

var TimingFunction = {
  linear: /* linear */-325037595,
  ease: /* ease */-1022587922,
  easeIn: /* easeIn */138027891,
  easeOut: /* easeOut */715748672,
  easeInOut: /* easeInOut */-960651557,
  stepStart: /* stepStart */-193525386,
  stepEnd: /* stepEnd */20933615,
  steps: steps,
  cubicBezier: cubicBezier,
  toString: toString$17
};

var Transform = {
  translate: translate,
  translate3d: translate3d,
  translateX: translateX,
  translateY: translateY,
  translateZ: translateZ,
  scale: scale,
  scale3d: scale3d,
  scaleX: scaleX,
  scaleY: scaleY,
  scaleZ: scaleZ,
  rotate: rotate,
  rotate3d: rotate3d,
  rotateX: rotateX,
  rotateY: rotateY,
  rotateZ: rotateZ,
  skew: skew,
  skewX: skewX,
  skewY: skewY,
  toString: toString$23
};

var Color = {
  rgb: rgb,
  rgba: rgba,
  hsl: hsl,
  hsla: hsla,
  hex: hex,
  transparent: /* transparent */582626130,
  currentColor: /* currentColor */292050538,
  toString: toString$29
};

var BackdropFilter = {
  toString: toString$67
};

var Gradient = {
  linearGradient: linearGradient,
  radialGradient: radialGradient,
  repeatingLinearGradient: repeatingLinearGradient,
  repeatingRadialGradient: repeatingRadialGradient,
  toString: toString$83
};

var FontFamilyName = {
  toString: toString$91
};

var FontDisplay = {
  toString: toString$92
};

exports.Cascading = Cascading;
exports.Time = Time;
exports.Percentage = Percentage;
exports.Url = Url;
exports.Length = Length;
exports.Angle = Angle;
exports.Direction = Direction;
exports.Position = Position;
exports.Resize = Resize;
exports.FontVariant = FontVariant;
exports.FontStyle = FontStyle;
exports.FlexBasis = FlexBasis;
exports.Overflow = Overflow;
exports.Margin = Margin;
exports.GridAutoFlow = GridAutoFlow;
exports.GridColumnGap = GridColumnGap;
exports.VerticalAlign = VerticalAlign;
exports.TimingFunction = TimingFunction;
exports.RepeatValue = RepeatValue;
exports.ListStyleType = ListStyleType;
exports.ListStylePosition = ListStylePosition;
exports.OutlineStyle = OutlineStyle;
exports.FontWeight = FontWeight;
exports.Transform = Transform;
exports.AnimationDirection = AnimationDirection;
exports.AnimationFillMode = AnimationFillMode;
exports.AnimationIterationCount = AnimationIterationCount;
exports.AnimationPlayState = AnimationPlayState;
exports.Cursor = Cursor;
exports.Color = Color;
exports.BorderStyle = BorderStyle;
exports.PointerEvents = PointerEvents;
exports.Perspective = Perspective;
exports.LetterSpacing = LetterSpacing;
exports.LineHeight = LineHeight;
exports.WordSpacing = WordSpacing;
exports.DisplayOutside = DisplayOutside;
exports.DisplayInside = DisplayInside;
exports.DisplayListItem = DisplayListItem;
exports.DisplayInternal = DisplayInternal;
exports.DisplayBox = DisplayBox;
exports.DisplayLegacy = DisplayLegacy;
exports.JustifySelf = JustifySelf;
exports.PositionalAlignment = PositionalAlignment;
exports.BaselineAlignment = BaselineAlignment;
exports.NormalAlignment = NormalAlignment;
exports.DistributedAlignment = DistributedAlignment;
exports.TextAlign = TextAlign;
exports.WordBreak = WordBreak;
exports.WhiteSpace = WhiteSpace;
exports.AlignItems = AlignItems;
exports.AlignSelf = AlignSelf;
exports.AlignContent = AlignContent;
exports.ObjectFit = ObjectFit;
exports.Clear = Clear;
exports.Float = Float;
exports.Visibility = Visibility;
exports.TableLayout = TableLayout;
exports.BorderCollapse = BorderCollapse;
exports.FlexWrap = FlexWrap;
exports.FlexDirection = FlexDirection;
exports.BoxSizing = BoxSizing;
exports.ColumnCount = ColumnCount;
exports.UserSelect = UserSelect;
exports.TextTransform = TextTransform;
exports.GridTemplateAreas = GridTemplateAreas;
exports.GridArea = GridArea;
exports.BackdropFilter = BackdropFilter;
exports.BackgroundAttachment = BackgroundAttachment;
exports.BackgroundClip = BackgroundClip;
exports.BackgroundOrigin = BackgroundOrigin;
exports.BackgroundPosition = BackgroundPosition;
exports.BackgroundRepeat = BackgroundRepeat;
exports.TextOverflow = TextOverflow;
exports.TextDecorationLine = TextDecorationLine;
exports.TextDecorationStyle = TextDecorationStyle;
exports.Width = Width;
exports.MaxWidth = MaxWidth;
exports.Height = Height;
exports.MaxHeight = MaxHeight;
exports.OverflowWrap = OverflowWrap;
exports.Gradient = Gradient;
exports.BackgroundImage = BackgroundImage;
exports.GeometyBox = GeometyBox;
exports.ClipPath = ClipPath;
exports.BackfaceVisibility = BackfaceVisibility;
exports.Flex = Flex;
exports.TransformStyle = TransformStyle;
exports.ListStyleImage = ListStyleImage;
exports.FontFamilyName = FontFamilyName;
exports.FontDisplay = FontDisplay;
exports.CounterStyleType = CounterStyleType;
exports.Counter = Counter;
exports.Counters = Counters;
exports.CounterIncrement = CounterIncrement;
exports.CounterReset = CounterReset;
exports.CounterSet = CounterSet;
exports.Content = Content;
/* No side effect */
