// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Theme$WordpressSeamonsterStarter = require("../../Lib/Theme.bs.js");
var MapSvg$WordpressSeamonsterStarter = require("./MapSvg.bs.js");
var MapTabsSearch$WordpressSeamonsterStarter = require("./MapTabsSearch.bs.js");
var ProvidersList$WordpressSeamonsterStarter = require("./ProvidersList.bs.js");
var MapCountyAreas$WordpressSeamonsterStarter = require("./MapCountyAreas.bs.js");

var root = Curry._1(Css.style, /* :: */[
      Css.selector("h2", /* :: */[
            Css.marginBottom(Css.px(20)),
            /* [] */0
          ]),
      /* [] */0
    ]);

var svgContainer = Curry._1(Css.style, /* :: */[
      Css.padding2(Css.px(0), Css.px(10)),
      /* [] */0
    ]);

var providersContainer = Curry._1(Css.style, /* :: */[
      Css.marginTop(Css.px(40)),
      /* :: */[
        Css.selector("h2", /* :: */[
              Css.marginBottom(Css.px(25)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var transportGroupBar = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.justifyContent(/* center */98248149),
        /* :: */[
          Css.alignItems(/* center */98248149),
          /* :: */[
            Css.marginBottom(Css.px(26)),
            /* :: */[
              Css.paddingLeft(Css.px(20)),
              /* :: */[
                Css.paddingRight(Css.px(20)),
                /* :: */[
                  Css.selector("> button:first-of-type", /* :: */[
                        Css.marginRight(Css.px(45)),
                        /* [] */0
                      ]),
                  /* :: */[
                    Css.selector("button", /* :: */[
                          Css.borderStyle(/* none */-922086728),
                          /* :: */[
                            Css.background(/* none */-922086728),
                            /* :: */[
                              Css.cursor(/* pointer */-786317123),
                              /* :: */[
                                Css.color(Css.hex(Theme$WordpressSeamonsterStarter.brandBlue)),
                                /* :: */[
                                  Css.fontSize(Css.px(21)),
                                  /* :: */[
                                    Css.fontFamily(/* `custom */[
                                          1066567601,
                                          "Alvar Essential, sans-serif"
                                        ]),
                                    /* :: */[
                                      Css.selector("&.is-active", /* :: */[
                                            Css.fontWeight(/* semiBold */-812709613),
                                            /* [] */0
                                          ]),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var Style = {
  root: root,
  svgContainer: svgContainer,
  providersContainer: providersContainer,
  transportGroupBar: transportGroupBar
};

function MapTabs(Props) {
  var data = Props.data;
  var title = Props.title;
  var match = React.useState((function () {
          return ;
        }));
  var setActiveCounty = match[1];
  var activeCounty = match[0];
  var match$1 = React.useState((function () {
          return /* Local */0;
        }));
  var setActiveProviderGroup = match$1[1];
  var activeProviderGroup = match$1[0];
  var setCountyByName = function (countyName) {
    return Curry._1(setActiveCounty, (function (param) {
                  return Belt_Array.getBy(data, (function (county) {
                                return Caml_obj.caml_equal(county.post_name, countyName);
                              }));
                }));
  };
  var tmp;
  if (activeCounty !== undefined) {
    var county = activeCounty;
    var testProviders = Belt_Array.keep(county.providers, (function (provider) {
            return provider.type_ === "test";
          }));
    var transportProviders = Belt_Array.keep(county.providers, (function (provider) {
            return provider.type_ === "transport";
          }));
    var localGroup = Belt_Array.keep(transportProviders, (function (provider) {
            return provider.availability === "local";
          }));
    var stateGroup = Belt_Array.keep(transportProviders, (function (provider) {
            return provider.availability === "national";
          }));
    tmp = React.createElement(React.Fragment, undefined, county.county_areas !== "" ? React.createElement(React.Fragment, undefined, React.createElement("h2", {
                    className: "has-text-centered"
                  }, "Areas in " + county.post_title), React.createElement(MapCountyAreas$WordpressSeamonsterStarter.make, {
                    areas: county.county_areas
                  })) : null, testProviders.length !== 0 ? React.createElement("div", {
                className: providersContainer
              }, React.createElement("h2", {
                    className: "has-text-centered"
                  }, "Test Providers"), React.createElement(ProvidersList$WordpressSeamonsterStarter.make, {
                    providers: testProviders
                  })) : null, transportProviders.length !== 0 ? React.createElement("div", {
                className: providersContainer
              }, React.createElement("h2", {
                    className: "has-text-centered"
                  }, "Transportation Providers"), React.createElement("div", {
                    className: transportGroupBar
                  }, React.createElement("button", {
                        className: activeProviderGroup === /* Local */0 ? "is-active" : "",
                        onClick: (function (param) {
                            return Curry._1(setActiveProviderGroup, (function (param) {
                                          return /* Local */0;
                                        }));
                          })
                      }, "Local"), React.createElement("button", {
                        className: activeProviderGroup === /* State */1 ? "is-active" : "",
                        onClick: (function (param) {
                            return Curry._1(setActiveProviderGroup, (function (param) {
                                          return /* State */1;
                                        }));
                          })
                      }, "Statewide / Nationwide")), React.createElement("div", undefined, activeProviderGroup ? React.createElement(ProvidersList$WordpressSeamonsterStarter.make, {
                          providers: stateGroup
                        }) : React.createElement(ProvidersList$WordpressSeamonsterStarter.make, {
                          providers: localGroup
                        }))) : null);
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: root
            }, React.createElement("h2", {
                  className: "has-text-centered"
                }, title), React.createElement("div", {
                  className: svgContainer
                }, React.createElement(MapSvg$WordpressSeamonsterStarter.make, {
                      setCountyByName: setCountyByName
                    })), React.createElement("div", undefined, React.createElement(MapTabsSearch$WordpressSeamonsterStarter.make, { }), React.createElement("div", undefined, tmp)));
}

var make = MapTabs;

exports.Style = Style;
exports.make = make;
/* root Not a pure module */
