// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Decco = require("decco/src/Decco.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Js_mapperRt = require("bs-platform/lib/js/js_mapperRt.js");
var DeccoHelper$Sms = require("@seamonster-studios/reason/src/utils/DeccoHelper.bs.js");

var jsMapperConstantArray = [
  /* tuple */[
    -1051365097,
    "bristol"
  ],
  /* tuple */[
    -970559436,
    "richmond"
  ],
  /* tuple */[
    -770103918,
    "fredrickburg"
  ],
  /* tuple */[
    -690626360,
    "northern-virginia"
  ],
  /* tuple */[
    -390217077,
    "hampton-roads"
  ],
  /* tuple */[
    -142298106,
    "salem"
  ],
  /* tuple */[
    -118435526,
    "staunton"
  ],
  /* tuple */[
    240466510,
    "culpeper"
  ],
  /* tuple */[
    487220590,
    "lynchburg"
  ]
];

function tToJs(param) {
  return Js_mapperRt.binarySearch(9, param, jsMapperConstantArray);
}

function tFromJs(param) {
  return Js_mapperRt.revSearch(9, jsMapperConstantArray, param);
}

var County = DeccoHelper$Sms.MakePV({
      tToJs: tToJs,
      tFromJs: tFromJs,
      name: "County"
    });

function contact_method_encode(v) {
  return Js_dict.fromArray([
              /* tuple */[
                "title",
                Decco.stringToJson(v.title)
              ],
              /* tuple */[
                "label",
                Decco.stringToJson(v.label)
              ],
              /* tuple */[
                "link",
                Decco.stringToJson(v.link)
              ]
            ]);
}

function contact_method_decode(v) {
  var match = Js_json.classify(v);
  if (typeof match === "number" || match.tag !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  } else {
    var dict = match[0];
    var match$1 = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict, "title"), null));
    if (match$1.tag) {
      var e = match$1[0];
      return /* Error */Block.__(1, [{
                  path: ".title" + e.path,
                  message: e.message,
                  value: e.value
                }]);
    } else {
      var match$2 = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict, "label"), null));
      if (match$2.tag) {
        var e$1 = match$2[0];
        return /* Error */Block.__(1, [{
                    path: ".label" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }]);
      } else {
        var match$3 = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict, "link"), null));
        if (match$3.tag) {
          var e$2 = match$3[0];
          return /* Error */Block.__(1, [{
                      path: ".link" + e$2.path,
                      message: e$2.message,
                      value: e$2.value
                    }]);
        } else {
          return /* Ok */Block.__(0, [{
                      title: match$1[0],
                      label: match$2[0],
                      link: match$3[0]
                    }]);
        }
      }
    }
  }
}

function provider_encode(v) {
  return Js_dict.fromArray([
              /* tuple */[
                "type",
                Decco.stringToJson(v.type_)
              ],
              /* tuple */[
                "availability",
                Decco.stringToJson(v.availability)
              ],
              /* tuple */[
                "info",
                Decco.stringToJson(v.info)
              ],
              /* tuple */[
                "address_line_1",
                Decco.optionToJson(Decco.stringToJson, v.address_line_1)
              ],
              /* tuple */[
                "address_line_2",
                Decco.optionToJson(Decco.stringToJson, v.address_line_2)
              ],
              /* tuple */[
                "google_maps_address",
                Decco.optionToJson(Decco.stringToJson, v.google_maps_address)
              ],
              /* tuple */[
                "contact_methods",
                Decco.arrayToJson(contact_method_encode, v.contact_methods)
              ]
            ]);
}

function provider_decode(v) {
  var match = Js_json.classify(v);
  if (typeof match === "number" || match.tag !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  } else {
    var dict = match[0];
    var match$1 = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict, "type"), null));
    if (match$1.tag) {
      var e = match$1[0];
      return /* Error */Block.__(1, [{
                  path: ".type" + e.path,
                  message: e.message,
                  value: e.value
                }]);
    } else {
      var match$2 = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict, "availability"), null));
      if (match$2.tag) {
        var e$1 = match$2[0];
        return /* Error */Block.__(1, [{
                    path: ".availability" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }]);
      } else {
        var match$3 = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict, "info"), null));
        if (match$3.tag) {
          var e$2 = match$3[0];
          return /* Error */Block.__(1, [{
                      path: ".info" + e$2.path,
                      message: e$2.message,
                      value: e$2.value
                    }]);
        } else {
          var match$4 = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict, "address_line_1"), (function (param) {
                      return Decco.optionFromJson(Decco.stringFromJson, param);
                    })), /* Ok */Block.__(0, [undefined]));
          if (match$4.tag) {
            var e$3 = match$4[0];
            return /* Error */Block.__(1, [{
                        path: ".address_line_1" + e$3.path,
                        message: e$3.message,
                        value: e$3.value
                      }]);
          } else {
            var match$5 = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict, "address_line_2"), (function (param) {
                        return Decco.optionFromJson(Decco.stringFromJson, param);
                      })), /* Ok */Block.__(0, [undefined]));
            if (match$5.tag) {
              var e$4 = match$5[0];
              return /* Error */Block.__(1, [{
                          path: ".address_line_2" + e$4.path,
                          message: e$4.message,
                          value: e$4.value
                        }]);
            } else {
              var match$6 = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict, "google_maps_address"), (function (param) {
                          return Decco.optionFromJson(Decco.stringFromJson, param);
                        })), /* Ok */Block.__(0, [undefined]));
              if (match$6.tag) {
                var e$5 = match$6[0];
                return /* Error */Block.__(1, [{
                            path: ".google_maps_address" + e$5.path,
                            message: e$5.message,
                            value: e$5.value
                          }]);
              } else {
                var match$7 = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict, "contact_methods"), (function (param) {
                            return Decco.arrayFromJson(contact_method_decode, param);
                          })), /* Ok */Block.__(0, [[]]));
                if (match$7.tag) {
                  var e$6 = match$7[0];
                  return /* Error */Block.__(1, [{
                              path: ".contact_methods" + e$6.path,
                              message: e$6.message,
                              value: e$6.value
                            }]);
                } else {
                  return /* Ok */Block.__(0, [{
                              type_: match$1[0],
                              availability: match$2[0],
                              info: match$3[0],
                              address_line_1: match$4[0],
                              address_line_2: match$5[0],
                              google_maps_address: match$6[0],
                              contact_methods: match$7[0]
                            }]);
                }
              }
            }
          }
        }
      }
    }
  }
}

function transportation_providers_encode(v) {
  return Js_dict.fromArray([
              /* tuple */[
                "local",
                Decco.optionToJson((function (param) {
                        return Decco.arrayToJson(provider_encode, param);
                      }), v.local)
              ],
              /* tuple */[
                "state",
                Decco.optionToJson((function (param) {
                        return Decco.arrayToJson(provider_encode, param);
                      }), v.state)
              ]
            ]);
}

function transportation_providers_decode(v) {
  var match = Js_json.classify(v);
  if (typeof match === "number" || match.tag !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  } else {
    var dict = match[0];
    var match$1 = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict, "local"), (function (param) {
                return Decco.optionFromJson((function (param) {
                              return Decco.arrayFromJson(provider_decode, param);
                            }), param);
              })), /* Ok */Block.__(0, [undefined]));
    if (match$1.tag) {
      var e = match$1[0];
      return /* Error */Block.__(1, [{
                  path: ".local" + e.path,
                  message: e.message,
                  value: e.value
                }]);
    } else {
      var match$2 = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict, "state"), (function (param) {
                  return Decco.optionFromJson((function (param) {
                                return Decco.arrayFromJson(provider_decode, param);
                              }), param);
                })), /* Ok */Block.__(0, [undefined]));
      if (match$2.tag) {
        var e$1 = match$2[0];
        return /* Error */Block.__(1, [{
                    path: ".state" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }]);
      } else {
        return /* Ok */Block.__(0, [{
                    local: match$1[0],
                    state: match$2[0]
                  }]);
      }
    }
  }
}

function county_name_encode(v) {
  return Js_dict.fromArray([
              /* tuple */[
                "value",
                Curry._1(County.t_encode, v.value)
              ],
              /* tuple */[
                "label",
                Decco.stringToJson(v.label)
              ]
            ]);
}

function county_name_decode(v) {
  var match = Js_json.classify(v);
  if (typeof match === "number" || match.tag !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  } else {
    var dict = match[0];
    var match$1 = Curry._1(County.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict, "value"), null));
    if (match$1.tag) {
      var e = match$1[0];
      return /* Error */Block.__(1, [{
                  path: ".value" + e.path,
                  message: e.message,
                  value: e.value
                }]);
    } else {
      var match$2 = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict, "label"), null));
      if (match$2.tag) {
        var e$1 = match$2[0];
        return /* Error */Block.__(1, [{
                    path: ".label" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }]);
      } else {
        return /* Ok */Block.__(0, [{
                    value: match$1[0],
                    label: match$2[0]
                  }]);
      }
    }
  }
}

function county_info_encode(v) {
  return Js_dict.fromArray([
              /* tuple */[
                "county_name",
                county_name_encode(v.county_name)
              ],
              /* tuple */[
                "areas",
                Decco.optionToJson(Decco.stringToJson, v.areas)
              ],
              /* tuple */[
                "test_providers",
                Decco.optionToJson((function (param) {
                        return Decco.arrayToJson(provider_encode, param);
                      }), v.test_providers)
              ],
              /* tuple */[
                "transportation_providers",
                Decco.optionToJson(transportation_providers_encode, v.transportation_providers)
              ]
            ]);
}

function county_info_decode(v) {
  var match = Js_json.classify(v);
  if (typeof match === "number" || match.tag !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  } else {
    var dict = match[0];
    var match$1 = county_name_decode(Belt_Option.getWithDefault(Js_dict.get(dict, "county_name"), null));
    if (match$1.tag) {
      var e = match$1[0];
      return /* Error */Block.__(1, [{
                  path: ".county_name" + e.path,
                  message: e.message,
                  value: e.value
                }]);
    } else {
      var match$2 = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict, "areas"), (function (param) {
                  return Decco.optionFromJson(Decco.stringFromJson, param);
                })), /* Ok */Block.__(0, [undefined]));
      if (match$2.tag) {
        var e$1 = match$2[0];
        return /* Error */Block.__(1, [{
                    path: ".areas" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }]);
      } else {
        var match$3 = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict, "test_providers"), (function (param) {
                    return Decco.optionFromJson((function (param) {
                                  return Decco.arrayFromJson(provider_decode, param);
                                }), param);
                  })), /* Ok */Block.__(0, [undefined]));
        if (match$3.tag) {
          var e$2 = match$3[0];
          return /* Error */Block.__(1, [{
                      path: ".test_providers" + e$2.path,
                      message: e$2.message,
                      value: e$2.value
                    }]);
        } else {
          var match$4 = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict, "transportation_providers"), (function (param) {
                      return Decco.optionFromJson(transportation_providers_decode, param);
                    })), /* Ok */Block.__(0, [undefined]));
          if (match$4.tag) {
            var e$3 = match$4[0];
            return /* Error */Block.__(1, [{
                        path: ".transportation_providers" + e$3.path,
                        message: e$3.message,
                        value: e$3.value
                      }]);
          } else {
            return /* Ok */Block.__(0, [{
                        county_name: match$1[0],
                        areas: match$2[0],
                        test_providers: match$3[0],
                        transportation_providers: match$4[0]
                      }]);
          }
        }
      }
    }
  }
}

function county_post_encode(v) {
  return Js_dict.fromArray([
              /* tuple */[
                "post_title",
                Decco.stringToJson(v.post_title)
              ],
              /* tuple */[
                "post_name",
                Curry._1(County.t_encode, v.post_name)
              ],
              /* tuple */[
                "county_areas",
                Decco.stringToJson(v.county_areas)
              ],
              /* tuple */[
                "providers",
                Decco.arrayToJson(provider_encode, v.providers)
              ]
            ]);
}

function county_post_decode(v) {
  var match = Js_json.classify(v);
  if (typeof match === "number" || match.tag !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  } else {
    var dict = match[0];
    var match$1 = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict, "post_title"), null));
    if (match$1.tag) {
      var e = match$1[0];
      return /* Error */Block.__(1, [{
                  path: ".post_title" + e.path,
                  message: e.message,
                  value: e.value
                }]);
    } else {
      var match$2 = Curry._1(County.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict, "post_name"), null));
      if (match$2.tag) {
        var e$1 = match$2[0];
        return /* Error */Block.__(1, [{
                    path: ".post_name" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }]);
      } else {
        var match$3 = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict, "county_areas"), Decco.stringFromJson), /* Ok */Block.__(0, [""]));
        if (match$3.tag) {
          var e$2 = match$3[0];
          return /* Error */Block.__(1, [{
                      path: ".county_areas" + e$2.path,
                      message: e$2.message,
                      value: e$2.value
                    }]);
        } else {
          var match$4 = Decco.arrayFromJson(provider_decode, Belt_Option.getWithDefault(Js_dict.get(dict, "providers"), null));
          if (match$4.tag) {
            var e$3 = match$4[0];
            return /* Error */Block.__(1, [{
                        path: ".providers" + e$3.path,
                        message: e$3.message,
                        value: e$3.value
                      }]);
          } else {
            return /* Ok */Block.__(0, [{
                        post_title: match$1[0],
                        post_name: match$2[0],
                        county_areas: match$3[0],
                        providers: match$4[0]
                      }]);
          }
        }
      }
    }
  }
}

function pageFields_encode(v) {
  return Js_dict.fromArray([/* tuple */[
                "title",
                Decco.stringToJson(v.title)
              ]]);
}

function pageFields_decode(v) {
  var match = Js_json.classify(v);
  if (typeof match === "number" || match.tag !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  } else {
    var match$1 = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(match[0], "title"), null));
    if (match$1.tag) {
      var e = match$1[0];
      return /* Error */Block.__(1, [{
                  path: ".title" + e.path,
                  message: e.message,
                  value: e.value
                }]);
    } else {
      return /* Ok */Block.__(0, [{
                  title: match$1[0]
                }]);
    }
  }
}

function t_encode(v) {
  return Decco.arrayToJson(county_post_encode, v);
}

function t_decode(v) {
  return Decco.arrayFromJson(county_post_decode, v);
}

exports.County = County;
exports.contact_method_encode = contact_method_encode;
exports.contact_method_decode = contact_method_decode;
exports.provider_encode = provider_encode;
exports.provider_decode = provider_decode;
exports.transportation_providers_encode = transportation_providers_encode;
exports.transportation_providers_decode = transportation_providers_decode;
exports.county_name_encode = county_name_encode;
exports.county_name_decode = county_name_decode;
exports.county_info_encode = county_info_encode;
exports.county_info_decode = county_info_decode;
exports.county_post_encode = county_post_encode;
exports.county_post_decode = county_post_decode;
exports.pageFields_encode = pageFields_encode;
exports.pageFields_decode = pageFields_decode;
exports.t_encode = t_encode;
exports.t_decode = t_decode;
/* County Not a pure module */
