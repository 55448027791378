// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var CompatDialog = require("./compat-dialog");

var make = CompatDialog.Dialog;

var make$1 = CompatDialog.DialogContent;

var Content = {
  make: make$1
};

var Overlay = { };

var Dialog = {
  make: make,
  Content: Content,
  Overlay: Overlay
};

exports.Dialog = Dialog;
/* make Not a pure module */
